import {
  ICreatePlan,
  IListPlanSummary,
  IListPlans,
  IRouteAddOrder,
  IVariantVehicleStatusUpdate,
  IVehicleOrderStatusUpdate,
  IVehicleOrderSubmitPartialPayment,
  IVehicleRouteStatusUpdate,
} from '../../types';
export const routePlanningJSON = {
  listPlanSummary: ({ date, orders, route }: IListPlanSummary) => {
    return {
      date: date,
      orders: orders,
      route: route,
    };
  },
  createPlan: ({ date, orders, vehicles }: ICreatePlan) => {
    return {
      date: date,
      orders: orders,
      vehicles: vehicles,
    };
  },
  listPlan: ({
    limit,
    pageNo,
    sortKey,
    sortOrder,
    fromDate,
    needCount,
    toDate,
    status,
  }: IListPlans) => {
    return {
      limit: limit,
      pageNo: pageNo,
      sortKey: sortKey,
      sortOrder: sortOrder,
      fromDate: fromDate,
      needCount: needCount,
      toDate: toDate,
      status: status,
    };
  },
  variantVehicleStatusUpdate: ({
    order,
    variant,
    status,
    quantityType,
  }: IVariantVehicleStatusUpdate) => {
    return {
      order: order,
      variant: variant,
      status: status,
      quantityType: quantityType,
    };
  },
  vehicleRouteStatusUpdate: ({ status }: IVehicleRouteStatusUpdate) => {
    return { status: status.toString() };
  },
  vehicleOrderStatusUpdate: ({
    order,
    paymentMode,
    status,
    collectedCharge,
    scheduleForNextDay
  }: IVehicleOrderStatusUpdate) => {
    let temp: any = {
      order: order,
      status: status.toString(),
      paymentMode: paymentMode,
      scheduleForNextDay:scheduleForNextDay
    };
    if (paymentMode !== 0) {
    } else {
      temp = {
        ...temp,
        collectedPartially: true,
        collectedCharge: collectedCharge === 0 ? -1 : collectedCharge,
      };
    }
    return temp;
  },
  vehicleOrderSubmitPartialPayment: ({
    order,
    paymentMode,
    collectedCharge,
  }: IVehicleOrderSubmitPartialPayment) => {
    let temp: any = {
      order: order,
      paymentMode: paymentMode,
      collectedPartially: true,
      collectedCharge: collectedCharge,
    };
    return temp;
  },
  addOrder: ({ order }: IRouteAddOrder) => {
    let temp: any = {
      orders: order,
    };
    return temp;
  },
  removeOrder: ({ order }: IRouteAddOrder) => {
    let form = new FormData();
    form.append('order', order);
    return form;
  },
};
