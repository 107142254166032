import {
  Button,
  Card,
  Col,
  FormLabel,
  OverlayTrigger,
  Popover,
  Row,
  Tooltip,
} from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import RejectProduct from '../../modals/reject-product';
import cartoon from '../../../_admin/assets/media/product/cartoon.png';
import RoundThreeDot from '../../../_admin/assets/media/svg_uTrade/three-dot-round.svg';
import VerifiedPro from '../../../_admin/assets/media/svg_uTrade/verified.svg';
import TabPriceDimensions from '../products/product-details-tabs/price-dimensions';
import TabProductDetails from '../products/product-details-tabs/product-details';
import TabInventoryHistory from '../products/product-details-tabs/product-inventory-history';
import TabStockDetails from '../products/product-details-tabs/product-stock-details';
import APICallService from '../../../api/apiCallService';
import {
  inventory,
  manageProduct,
  multipleWarehouse,
  ordersDelivery,
  product,
  reports,
} from '../../../api/apiEndPoints';
import Loader from '../../../Global/loader';
import {
  Accepted,
  All,
  AllSeller,
  ApprovedProduct,
  CartonWithDozens,
  CartonWithPieces,
  Dozen,
  Edit,
  FinanceManager,
  FulfillmentManager,
  LoadingBayManager,
  NewRequests,
  PendingProduct,
  Piece,
  Rejected,
  RejectedProduct,
  UnderReview,
  View,
  All_Products,
  Admin,
} from '../../../utils/constants';
import { CustomSelectTable } from '../../custom/Select/CustomSelectTable';
import { error } from '../../../Global/toast';
import ReturnGoodsSellerModal from '../../modals/return-goods-seller-modal';
import { useAuth } from '../auth';
import ProductInventorySVG from '../../../_admin/assets/media/dashboard/product-inventory.svg';
import { reportJson } from '../../../api/apiJSON/reports';
import { getKey, removeKey, setKey } from '../../../Global/history';
import { listProductDetails } from '../../../utils/storeString';
import Method from '../../../utils/methods';
import { calculateDiscountedPrice } from '../../../utils/ProductPriceDiscount';
import PermissionModal from '../../modals/permission';
import { warehouse as warehouseStoreString } from '../../../utils/storeString';
import { CustomSelect } from '../../custom/Select/CustomSelect';
const ProductDetails = () => {
  const { currentUser } = useAuth();
  let tempWarehouse: any = getKey(warehouseStoreString.warehouseRef);
  tempWarehouse = JSON.parse(tempWarehouse);
  if (tempWarehouse?._id == '') {
    tempWarehouse._id = currentUser?.warehouse?._id;
    tempWarehouse.value = currentUser?.warehouse?._id || '';
    tempWarehouse.title = currentUser?.warehouse?.name || '';
    tempWarehouse.label = currentUser?.warehouse?.name || '';
  }
  const { state }: any = useLocation();
  const navigate = useNavigate();
  const [fetchLoader, setFetchLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [productDetails, setProductDetails] = useState<any>();
  const [businessDetails, setBusinessDetails] = useState<any>();
  const [disabledFields, setDisabledFields] = useState('');
  const [message, setMessage] = useState('');
  const [validation, setValidation] = useState(false);
  const [expired, setExpired] = useState<any>();
  const [expiring, setExpiring] = useState<any>();
  const [productList, setProductList] = useState<any>([]);
  const [modalLoader, setModalLoader] = useState(false);
  const [returnGoodsSeller, setReturnGoodsSeller] = useState(false);
  const [show, setShow] = useState(false);
  const [ids, setIds] = useState([]);
  const [count, setCount] = useState(0);
  const handleSelectTab = (tab: any) => {
    setCurrentTab(tab);
    setKey(listProductDetails.currentTab, tab, false);
  };
  const [showPermissionModal, setShowPermissionModal] = useState(false);
  useEffect(() => {
    (async () => {
      setFetchLoader(true);
      await fetchWarehouse(tempWarehouse?._id || null);
      setFetchLoader(false);
    })();
  }, []);
  const fetchLastMonthOrder = async (
    businessID?: String,
    productId?: String,
    warehouseId?: any
  ) => {
    let params: any = { variant: productId };
    if (warehouseId) {
      params = { ...params, warehouse: warehouseId };
    }
    let apiService = new APICallService(
      ordersDelivery.variant,
      params,
      { _id: businessID },
      '',
      '',
      '',
      All_Products,
      warehouseId || null
    );
    let response = await apiService.callAPI();
    setCount(response.count);
  };
  const fetchProductDetails = async (
    productId: String,
    warehouseId?: any,
    warehouse?: any
  ) => {
    let apiService = new APICallService(
      product.productInfo,
      productId,
      '',
      '',
      '',
      '',
      All_Products,
      warehouseId || null
    );
    let response = await apiService.callAPI();
    console.log(warehouse);
    response.record = calculateDiscountedPrice(
      [response.record],
      warehouse ? warehouse : selectedWarehouse
    )[0];
    setProductDetails(response.record);
    setBusinessDetails(response.business);
    let tempFields = response.record.technicalInfo
      .filter((a: any) => a.enabled == false)
      .map((a: any) => a.name)
      .join(', ');
    setDisabledFields(tempFields);
    setTab([
      {
        name: 'Product details',
        content: <TabProductDetails productDetails={response.record} />,
      },
      {
        name: 'Price & dimensions',
        content: <TabPriceDimensions productDetails={response.record} />,
      },
      {
        name: 'History of inventory',
        content: <TabInventoryHistory productDetails={response.record} />,
      },
      {
        name: 'Stock Details',
        content: <TabStockDetails productDetails={response.record} />,
      },
    ]);
    // setCurrentTab({
    //   name: 'Product details',
    //   content: <TabProductDetails productDetails={response.record} />,
    // });
    fetchLastMonthOrder(
      response.business.businessProfile._id,
      productId,
      warehouseId
    );
  };
  const [tab, setTab] = useState([
    {
      name: 'Product details',
      content: <TabProductDetails productDetails={productDetails} />,
    },
    {
      name: 'Price & dimensions',
      content: <TabPriceDimensions productDetails={productDetails} />,
    },
  ]);
  // const [currentTab, setCurrentTab] = useState({
  //   name: 'Product details',
  //   content: <TabProductDetails productDetails={productDetails} />,
  // });
  const [currentTab, setCurrentTab] = useState<any>(() => {
    const storedTabData = getKey(listProductDetails.currentTab);
    return storedTabData || tab[0];
  });
  const createTabs = () => {
    const localTab = tab;
    let current = currentTab;
    const allTabs = localTab.map((tab) => {
      return (
        <li className="nav-item">
          <Link
            to={'/'}
            className={
              current.name === tab.name
                ? 'nav-link active text-active-primary'
                : 'nav-link text-hover-primary'
            }
            data-bs-toggle="tab"
            onClick={() => handleSelectTab(tab)}
          >
            {tab.name}
          </Link>
        </li>
      );
    });
    return allTabs;
  };
  const handleProductApprove = async (status: number) => {
    setLoading(true);
    let currentId = ids[0];
    let productDetailsTemp = { ...productDetails };
    let apiService = new APICallService(
      manageProduct.updateVariantStatus,
      {
        status: status,
      },
      { id: ids[0] },
      '',
      '',
      '',
      All_Products,
      tempWarehouse?._id
    );
    let response = await apiService.callAPI();
    if (response) {
      let temp = [...ids];
      // Check if currentId exists in ids array
      const currentIdIndex = temp.indexOf(currentId);
      if (currentIdIndex !== -1) {
        // Remove currentId from its current position
        temp.splice(currentIdIndex, 1);
      }
      setIds(temp);
      if (temp.length) {
        setBusinessDetails(null);
        setProductDetails(null);
        setFetchLoader(true);
        await fetchProductDetails(temp[0], selectedWarehouse?._id || null);
        setFetchLoader(false);
      } else {
        productDetailsTemp.status = status;
        setProductDetails(productDetailsTemp);
      }
    }
    setLoading(false);
  };
  const handleProductReject = async (status: number) => {
    setRejectLoading(true);
    if (message.length > 0) {
      let currentId = ids[0];
      let productDetailsTemp = { ...productDetails };
      let apiService = new APICallService(
        manageProduct.updateVariantStatus,
        {
          status: status,
          message: message,
        },
        { id: ids[0] },
        '',
        '',
        '',
        All_Products,
        tempWarehouse?._id
      );
      let response = await apiService.callAPI();
      if (response) {
        // productDetailsTemp.status = status;
        // productDetailsTemp.statusMessage = message;
        // setProductDetails(productDetailsTemp);
        let temp = [...ids];
        // Check if currentId exists in ids array
        const currentIdIndex = temp.indexOf(currentId);
        if (currentIdIndex !== -1) {
          // Remove currentId from its current position
          temp.splice(currentIdIndex, 1);
        }
        setIds(temp);
        if (temp.length) {
          setFetchLoader(true);
          await fetchProductDetails(temp[0], selectedWarehouse?._id || null);
          setFetchLoader(false);
        } else {
          productDetailsTemp.status = status;
          productDetailsTemp.statusMessage = message;
          setProductDetails(productDetailsTemp);
        }
        setShowRejectProduct(false);
      }
      setValidation(false);
    } else {
      setValidation(true);
    }
    setRejectLoading(false);
  };
  const handleChange = (value: string) => {
    if (value.length > 0) {
      setValidation(false);
    } else {
      setValidation(true);
    }
    setMessage(value);
  };
  const [showRejectProduct, setShowRejectProduct] = useState(false);
  const [warehouse, setWarehouse] = useState<any>([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState<any>(null);
  const renderTooltip = (props: any) => (
    <Tooltip
      className="tooltip w-lg-275px"
      {...props}
    >
      <p className="text-white mb-0 fs-14 fw-500">
        Once seller account is approved then you'll be able to access these
        features.
      </p>
    </Tooltip>
  );
  const renderTabContent = () => {
    const storedTabData: any = getKey(listProductDetails.currentTab);
    if (storedTabData) {
      const currentTabData = tab.find((t) => t.name === storedTabData?.name);
      return currentTabData ? currentTabData.content : null;
    } else {
      return tab[0].content;
    }
  };
  const openMenuOnClick = async () => {
    setShow(true);
  };
  const onMenuClose = async () => {
    setShow(false);
  };
  const onMenuOpen = async () => {
    setShow(true);
  };
  const handleOption = async (event: any) => {
    if (event.value === 1) {
      navigate('/products/edit-product-variant', {
        state: {
          _id: productDetails?._id,
          isMaster: false,
          moduleName: All_Products,
        },
      });
    } else if (event.value === 2) {
      if (event.value === 1) {
      } else if (event.value === 2) {
        if (productDetails.inventoryInfo[0].quantityTypes.length) {
          navigate('/inventory/goods-in-warehouse/edit-stock-count', {
            state: productDetails?._id,
          });
        } else {
          error('Stock details not available!');
        }
      }
    }
  };
  const handleSellerProfile = (id: string) => {
    console.log('hii', id);
    navigate('/all-products/seller-profile', {
      state: { id: id, moduleName: All_Products },
    });
  };
  const fetchExpiringProducts = async () => {
    setModalLoader(true);
    let apiService = new APICallService(
      inventory.variantExpiredStockList,
      {
        variant: productDetails._id,
      },
      '',
      '',
      '',
      '',
      All_Products,
      tempWarehouse?._id
    );
    let response = await apiService.callAPI();
    if (
      (Object.keys(response).length && response.expired.quantityTypes.length) ||
      response.expiring.quantityTypes.length
    ) {
      setProductList(response.businesses);
      setExpired(response.expired);
      setExpiring(response.expiring);
      setReturnGoodsSeller(true);
    } else {
      error('No Data found');
    }
    setModalLoader(false);
  };
  const handleSubmit = async () => {
    let variants: any = [];
    [state.id].map((val: any) => {
      variants.push(val);
    });
    let params: any = {
      variants: variants,
      utcOffset: new Date().getTimezoneOffset(),
    };
    if (tempWarehouse?._id) {
      params.warehouse = tempWarehouse?._id;
    }
    setDownloadLoading(true);
    let apiService = new APICallService(
      reports.productVariantReport,
      reportJson.getProductVariantReport(params),
      undefined,
      'blob',
      '',
      '',
      All_Products,
      tempWarehouse?._id
    );
    let response = await apiService.callAPI();
    if (response) {
      const pdfBlob = new Blob([response], { type: 'application/pdf' });
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(pdfBlob);
      downloadLink.download = 'product_variant_report' + '.pdf';
      downloadLink.click();
    } else {
      error('No Data Found');
    }
    setDownloadLoading(false);
  };
  const reserveQuantity = (
    <Popover
      id="popover-basic"
      className="bg-black"
    >
      <Popover.Body className="p-2  border-r10px text-white">
        <span>Reserved stock</span>
      </Popover.Body>
    </Popover>
  );
  const remainingQuantity = (
    <Popover
      id="popover-basic"
      className="bg-black"
    >
      <Popover.Body className="p-2  border-r10px text-white">
        <span>Remaining stock</span>
      </Popover.Body>
    </Popover>
  );
  const totalQuantity = (
    <Popover
      id="popover-basic"
      className="
    bg-black"
    >
      <Popover.Body className="p-2  border-r10px text-white">
        <span>Total stock</span>
      </Popover.Body>
    </Popover>
  );
  const fetchWarehouse = async (warehouseId?: any) => {
    let params: any = {
      skip: 0,
      sortKey: 'name',
      sortOrder: 1,
      needCount: true,
    };
    if (warehouseId) {
      params.warehouse = warehouseId;
    }
    setLoading(true);
    const apiService = new APICallService(
      multipleWarehouse.listWarehouse,
      params,
      '',
      '',
      '',
      '',
      All_Products,
      warehouseId
    );
    const response = await apiService.callAPI();
    let data: any = [...warehouse];
    if (response.records.length) {
      response.records.map((val: any) => {
        data.push({
          value: val._id,
          label: val.name,
          title: val.name,
          _id: val._id,
          discount: val.discount || 0,
          discountType: val.discountType || 0,
          roundValue: val.roundValue || 0,
        });
      });
    }
    let tempWarehouse: any = getKey(warehouseStoreString.warehouseRef);
    tempWarehouse = JSON.parse(tempWarehouse);
    if (tempWarehouse) {
      if (tempWarehouse?._id == '') {
        tempWarehouse._id = currentUser?.warehouse?._id;
        tempWarehouse.value = currentUser?.warehouse?._id || '';
        tempWarehouse.title = currentUser?.warehouse?.name || '';
        tempWarehouse.label = currentUser?.warehouse?.name || '';
      }
      const temp = data.find((item: any) => item._id === tempWarehouse._id);
      setSelectedWarehouse(temp);
      let id;
      if (state.status && state.status === PendingProduct) {
        id = state.ids[0];
        setIds(state.ids);
      } else {
        id = state.id;
      }
      setFetchLoader(true);
      await fetchProductDetails(id, tempWarehouse?._id || null, temp);
      // setWarehouseData(temp);
    } else {
      if (response.records.length) {
        setSelectedWarehouse(data[0]);
        let id;
        if (state.status && state.status === PendingProduct) {
          id = state.ids[0];
          setIds(state.ids);
        } else {
          id = state.id;
        }
        setFetchLoader(true);
        await fetchProductDetails(id, tempWarehouse?._id || null, data[0]);
      }
    }
    setWarehouse(data);
    setLoading(false);
  };
  const handleWarehouseChange = async (event: any) => {
    setFetchLoader(true);
    // setKey(listDrivers.filterByType, event);
    let id;
    if (ids[0]) {
      id = ids[0];
    } else {
      id = state?.id;
    }
    if (event) {
      setSelectedWarehouse(event);
      setKey(warehouseStoreString.warehouseRef, event);
      // await fetchLowStockList(
      //   pageLimit,
      //   requiredStockFor,
      //   ordered,
      //   startDate,
      //   searchTerm,
      //   page,
      //   event.value
      // );
      await fetchProductDetails(id, event.value, event);
    } else {
      setSelectedWarehouse(null);
      // await fetchLowStockList(
      //   pageLimit,
      //   requiredStockFor,
      //   ordered,
      //   startDate,
      //   searchTerm,
      //   page,
      //   ""
      // );
      await fetchProductDetails(id);
      removeKey(warehouseStoreString.warehouseRef);
    }
    setFetchLoader(false);
  };
  return (
    <>
      {showPermissionModal && (
        <PermissionModal
          show={showPermissionModal}
          onHide={() => setShowPermissionModal(false)}
          moduleName={'seller'}
        />
      )}
      {productList && productList.length && returnGoodsSeller ? (
        <ReturnGoodsSellerModal
          show={returnGoodsSeller}
          onHide={() => setReturnGoodsSeller(false)}
          productList={productList}
          expired={expired}
          expiring={expiring}
        />
      ) : (
        <></>
      )}{' '}
      <div className="bg-white">
        <div className="p-9">
          <Row className="align-items-center mb-8">
            <Col xs>
              <h1 className="fs-22 fw-bolder mb-md-0 mb-5">Product details</h1>
            </Col>
            <Col xs="auto">
              {currentUser?.userType === Admin ? (
                <div className="d-flex align-items-center gap-4">
                  <FormLabel className="fs-16 fw-500 text-dark">
                    Filter by warehouse
                  </FormLabel>
                  <CustomSelect
                    backgroundColor="white"
                    value={selectedWarehouse}
                    onChange={(event: any) => {
                      handleWarehouseChange(event);
                    }}
                    hideSelectedOptions={true}
                    options={warehouse}
                    // isClearable={true}
                  />
                </div>
              ) : (
                <></>
              )}
            </Col>
            {currentUser?.role.length === 0 ||
            currentUser?.role.some(
              (obj: any) => obj.type === FinanceManager
            ) ? (
              <>
                {!fetchLoader ? (
                  <>
                    {' '}
                    <Col xs="auto">
                      <Button
                        size="lg"
                        className="min-h-50px"
                        onClick={() => handleSubmit()}
                        disabled={downloadLoading}
                      >
                        {!downloadLoading && (
                          <span className="indicator-label">
                            Download report
                          </span>
                        )}
                        {downloadLoading && (
                          <span
                            className="indicator-progress"
                            style={{ display: 'block' }}
                          >
                            Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        )}
                      </Button>
                    </Col>
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
            <Col
              xs="auto"
              className="order-sm-last mb-md-0 mb-5"
            >
              {/* <Button
                variant=""
                className="btn btn-icon btn-flush"
              >
                <img
                  width={47}
                  height={47}
                  src={RoundThreeDot}
                  alt=""
                />
              </Button> */}{' '}
              <>
                <Col xs="auto">
                  {!fetchLoader ? (
                    <Button
                      variant="link"
                      className="fs-16 fw-bolder text-primary btn-flush align-self-center text-nowrap"
                      onClick={() => fetchExpiringProducts()}
                    >
                      {!modalLoader && (
                        <span className="indicator-label fs-16 fw-bold">
                          Check expiring stock
                        </span>
                      )}
                      {modalLoader && (
                        <span
                          className="indicator-progress fs-16 fw-bold"
                          style={{ display: 'block' }}
                        >
                          Please wait...
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      )}
                    </Button>
                  ) : (
                    <></>
                  )}
                  {currentUser?.role.some(
                    (obj: any) =>
                      obj.type === FinanceManager ||
                      obj.type === LoadingBayManager ||
                      obj.type === FulfillmentManager
                  ) ? (
                    <></>
                  ) : (
                    <>
                      {fetchLoader ? (
                        <></>
                      ) : (
                        <>
                          {productDetails && productDetails.deleted ? (
                            <></>
                          ) : (
                            <>
                              {Method.hasPermission(
                                All_Products,
                                Edit,
                                currentUser
                              ) ? (
                                <Button
                                  type="button"
                                  variant="white"
                                  className="btn-icon btn-sm"
                                >
                                  <CustomSelectTable
                                    marginLeft={'-56px'}
                                    width={'130px'}
                                    placeholder={
                                      <img
                                        className="img-fluid"
                                        width={47}
                                        height={47}
                                        src={RoundThreeDot}
                                        alt=""
                                      />
                                    }
                                    options={[
                                      {
                                        label: (
                                          <Button
                                            variant=""
                                            className="btn btn-link btn-flush fs-14 fw-500 text-black ms-3 p-4  "
                                          >
                                            Edit details
                                          </Button>
                                        ),
                                        value: 1,
                                      },
                                      {
                                        label: (
                                          <Button
                                            variant=""
                                            className="btn btn-link btn-flush fs-14 fw-500 text-black ms-3 p-4  "
                                          >
                                            Edit Stock Count
                                          </Button>
                                        ),
                                        value: 2,
                                      },
                                      // {
                                      //   label: (
                                      //     <Button
                                      //       variant=""
                                      //       className="btn btn-link btn-flush fs-14 fw-500 text-black ms-3 p-4  "
                                      //     >
                                      //       Return goods
                                      //     </Button>
                                      //   ),
                                      //   value: 3,
                                      // },
                                      // {
                                      //   label: (
                                      //     <Button
                                      //       variant=""
                                      //       className="btn btn-link btn-flush fs-14 fw-500 text-danger ms-3 p-4  "
                                      //     >
                                      //       Delete this product
                                      //     </Button>
                                      //   ),
                                      //   value: 4,
                                      // },
                                    ]}
                                    backgroundColor="white"
                                    show={show}
                                    onMenuClose={() => {
                                      onMenuClose();
                                    }}
                                    openMenuOnClick={() => {
                                      openMenuOnClick();
                                    }}
                                    onMenuOpen={() => {
                                      onMenuOpen();
                                    }}
                                    onChange={(event: any) => {
                                      handleOption(event);
                                    }}
                                    // isOptionDisabled={(option: any) => option.value === 2}
                                  />
                                </Button>
                              ) : (
                                <></>
                              )}
                            </>
                          )}{' '}
                        </>
                      )}
                    </>
                  )}
                </Col>
              </>
            </Col>
            {currentUser?.role.some(
              (obj: any) =>
                obj.type === FinanceManager ||
                obj.type === LoadingBayManager ||
                obj.type === FulfillmentManager
            ) ? (
              <></>
            ) : (
              <>
                {' '}
                {!fetchLoader && businessDetails ? (
                  productDetails.status === ApprovedProduct ||
                  productDetails.status === RejectedProduct ? (
                    <></>
                  ) : (
                    <>
                      {Method.hasPermission(All_Products, Edit, currentUser) ? (
                        <Col
                          sm="auto"
                          className="mb-md-0 mb-5"
                        >
                          {businessDetails.businessProfile.KYC.status ===
                            NewRequests - 1 ||
                          businessDetails.businessProfile.KYC.status ===
                            All - 1 ||
                          businessDetails.businessProfile.KYC.status ===
                            UnderReview - 1 ||
                          businessDetails.businessProfile.KYC.status ===
                            Rejected - 1 ? (
                            <OverlayTrigger
                              placement="bottom"
                              // delay={{ show: 250, hide: 400 }}
                              overlay={renderTooltip}
                              trigger="hover"
                            >
                              <Button
                                variant="primary"
                                className="min-h-50px me-3"
                              >
                                {loading ? (
                                  <span
                                    className="indicator-progress fs-16 fw-bold"
                                    style={{ display: 'block' }}
                                  >
                                    Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                  </span>
                                ) : (
                                  <span className="indicator-label fs-16 fw-bold">
                                    Approve product
                                  </span>
                                )}
                              </Button>
                            </OverlayTrigger>
                          ) : (
                            <Button
                              variant="primary"
                              className="min-h-50px me-3"
                              onClick={() => {
                                handleProductApprove(ApprovedProduct);
                              }}
                            >
                              {loading ? (
                                <span
                                  className="indicator-progress fs-16 fw-bold"
                                  style={{ display: 'block' }}
                                >
                                  Please wait...
                                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                              ) : (
                                <span className="indicator-label fs-16 fw-bold">
                                  Approve product
                                </span>
                              )}
                            </Button>
                          )}
                          <Button
                            variant="light-danger"
                            onClick={() => setShowRejectProduct(true)}
                            className="min-h-50px me-3"
                            disabled={
                              businessDetails.businessProfile.KYC.status ===
                                NewRequests - 1 ||
                              businessDetails.businessProfile.KYC.status ===
                                All - 1 ||
                              businessDetails.businessProfile.KYC.status ===
                                UnderReview - 1 ||
                              businessDetails.businessProfile.KYC.status ===
                                Rejected - 1
                            }
                          >
                            <span className="indicator-label fs-16 fw-bold">
                              Reject product
                            </span>
                          </Button>
                        </Col>
                      ) : (
                        <></>
                      )}
                    </>
                  )
                ) : (
                  <></>
                )}
              </>
            )}
          </Row>
          {currentUser?.role.some(
            (obj: any) =>
              obj.type === FinanceManager ||
              obj.type === LoadingBayManager ||
              obj.type === FulfillmentManager
          ) ? (
            <></>
          ) : (
            <>
              {!fetchLoader && businessDetails ? (
                <Row>
                  <Col
                    xs={12}
                    className="mb-7"
                  >
                    <div className="bg-light border border-r10px p-7">
                      <Row className="align-items-center">
                        <Col
                          xs="auto"
                          className="mb-sm-0 mb-5"
                        >
                          <div className="symbol symbol-50px border">
                            <img
                              src={businessDetails.businessProfile.image}
                              className="img-fluid object-fit-contain"
                              alt=""
                            />
                          </div>
                        </Col>
                        <Col
                          xs
                          className="ps-0 mb-sm-0 mb-5"
                        >
                          <div className="fs-20 fw-bold d-flex align-items-center">
                            {businessDetails.businessProfile.name}
                            {businessDetails.businessProfile.KYC.status ===
                            Accepted - 1 ? (
                              <img
                                className="ms-2 mb-1"
                                width={24}
                                height={24}
                                src={VerifiedPro}
                                alt=""
                              />
                            ) : (
                              <></>
                            )}
                            {businessDetails.businessProfile.KYC.status ===
                              All - 1 ||
                            businessDetails.businessProfile.KYC.status ===
                              NewRequests - 1 ||
                            businessDetails.businessProfile.KYC.status ===
                              UnderReview - 1 ? (
                              <>
                                <span className="bage badge-light-warning text-dark border-r5px fs-14 fw-600 ms-2 p-2">
                                  Verification pending
                                </span>
                              </>
                            ) : (
                              <></>
                            )}
                            {businessDetails.businessProfile.KYC.status ===
                            Rejected - 1 ? (
                              <>
                                <span className="bage badge-light-danger text-dark border-r5px fs-14 fw-600 ms-2 p-2">
                                  Rejected
                                </span>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                          {businessDetails?.pendingCertificates.length ? (
                            <div className="fs-16 fw-600 text-danger">
                              {`Business certificate pending:  
                    ${businessDetails?.pendingCertificates
                      .map((u: any) => u.title)
                      .join(', ')}`}
                            </div>
                          ) : (
                            <></>
                          )}
                        </Col>
                        <Col
                          xs="auto"
                          className="text-end"
                        >
                          {Method.hasPermission(
                            All_Products,
                            View,
                            currentUser
                          ) &&
                          Method.hasPermission(AllSeller, View, currentUser) ? (
                            <Button
                              className="fs-16 fw-bold text-primary"
                              variant=""
                              onClick={() => {
                                handleSellerProfile(
                                  businessDetails.businessProfile._id
                                );
                              }}
                            >
                              View seller profile{' '}
                            </Button>
                          ) : (
                            <></>
                          )}
                          {Method.hasPermission(
                            All_Products,
                            View,
                            currentUser
                          ) &&
                          !Method.hasPermission(
                            AllSeller,
                            View,
                            currentUser
                          ) ? (
                            <Button
                              className="fs-16 fw-bold text-primary"
                              variant=""
                              onClick={() => {
                                setShowPermissionModal(true);
                              }}
                            >
                              View seller profile{' '}
                            </Button>
                          ) : (
                            <></>
                          )}
                        </Col>{' '}
                      </Row>
                    </div>
                  </Col>
                </Row>
              ) : (
                <></>
              )}
            </>
          )}
          {fetchLoader ? (
            <>
              <div className="d-flex justify-content-center">
                <Loader loading={fetchLoader} />
              </div>
            </>
          ) : (
            <>
              {productDetails && Object.keys(productDetails).length ? (
                <>
                  <RejectProduct
                    show={showRejectProduct}
                    onHide={() => {
                      setShowRejectProduct(false);
                    }}
                    handleProductReject={() => {
                      handleProductReject(RejectedProduct);
                    }}
                    loading={rejectLoading}
                    productDetails={productDetails}
                    handleChange={(value: string) => {
                      handleChange(value);
                    }}
                    message={message}
                    validation={validation}
                  />
                  <Card className="border border-custom-color mb-5 mb-xl-10">
                    {productDetails?.inventoryInfo.length &&
                    productDetails?.inventoryInfo[0] ? (
                      productDetails?.inventoryInfo[0].quantityTypes.some(
                        (quantVal: any) => quantVal.forecastedDays !== null
                      ) && (
                        <div className="d-flex justify-content-end">
                          <div
                            className="d-flex justify-content-center align-items-center bg-e7f1fd py-3 px-6"
                            style={{ borderRadius: '0 8px 0 10px' }}
                          >
                            <div
                              className="fw-bold fs-15 text-primary"
                              style={{ color: '#1b74e4', fontStyle: 'italic' }}
                            >
                              <img
                                src={ProductInventorySVG}
                                alt="inventory"
                                height="18px"
                                width="20px"
                              />
                              &nbsp; Stocks left:{' '}
                              {productDetails?.inventoryInfo &&
                              productDetails?.inventoryInfo.length &&
                              productDetails?.inventoryInfo[0] ? (
                                productDetails?.inventoryInfo[0].quantityTypes.map(
                                  (quantVal: any, index: number) => {
                                    if (quantVal.forecastedDays !== null) {
                                      let typeText = '';
                                      switch (quantVal.type) {
                                        case 1:
                                          typeText = 'CWP';
                                          break;
                                        case 2:
                                          typeText = 'DZN';
                                          break;
                                        case 3:
                                          typeText = 'PCS';
                                          break;
                                        case 4:
                                          typeText = 'CWP';
                                          break;
                                        default:
                                          typeText = '';
                                          break;
                                      }
                                      return (
                                        <React.Fragment key={index}>
                                          {index !== 0 && ', '}
                                          <span className="fw-bold">
                                            {typeText} (
                                            {quantVal.forecastedDays} days)
                                          </span>
                                        </React.Fragment>
                                      );
                                    }
                                    return null;
                                  }
                                )
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </div>
                      )
                    ) : (
                      <></>
                    )}
                    <Card.Body className="pt-9 pb-0">
                      <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
                        <div className="me-9 mb-4">
                          <div className="symbol symbol-150px symbol-lg-200px symbol-fixed border position-relative">
                            <div className="image-input d-flex flex-center rounded w-lg-200px h-lg-200px">
                              <div
                                className="image-input-wrapper shadow-none bgi-contain bgi-position-center w-100 h-100"
                                style={{
                                  backgroundImage: `url(${productDetails.media[0].url})`,
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                        <div className="row align-items-center flex-grow-1">
                          <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                            <div className="d-flex flex-column">
                              <div className="d-flex flex-column mb-2">
                                <h3 className="text-black fs-22 fw-700">
                                  {productDetails.title.replace(
                                    /\s*\)\s*/g,
                                    ')'
                                  )}
                                  {productDetails.active ? (
                                    <span className="badge badge-light-success border-r4px p-3 fs-14 fw-600 text-dark ms-2">
                                      Active Product
                                    </span>
                                  ) : (
                                    <span className="badge badge-light border-r4px p-3 fs-14 fw-600 text-dark ms-2">
                                      Deactivated Product
                                    </span>
                                  )}
                                </h3>
                                <span className="fs-16 fw-500">
                                  Product ID: {productDetails.refKey}
                                </span>
                              </div>
                              {productDetails.status === PendingProduct ? (
                                <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                                  <div className="d-flex justify-content-center align-items-center bg-light-warning p-3 px-4 pills-radius">
                                    <span className="bullet bullet-dot bg-warning me-2 h-10px w-10px"></span>
                                    <span className="fw-600 text-black fs-18">
                                      Verification pending
                                    </span>
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                              {productDetails.status === RejectedProduct ? (
                                <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                                  <div className="d-flex justify-content-center align-items-center bg-light-danger p-3 px-4 pills-radius">
                                    <span className="bullet bullet-dot bg-danger me-2 h-10px w-10px"></span>
                                    <span className="fw-600 text-black fs-18">
                                      Product Rejected:{' '}
                                      {productDetails.statusMessage
                                        ? productDetails.statusMessage
                                        : 'Your product details are invalid'}
                                    </span>
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                          <Col lg={12}>
                            <div className="d-flex flex-wrap flex-stack">
                              <div className="d-flex flex-column flex-grow-1 pe-8">
                                <div className="d-flex flex-wrap">
                                  <div className="bg-light border rounded min-w-125px py-3 px-4 me-6 mb-3">
                                    <div className="d-flex align-items-center">
                                      <div className="fs-22 fw-bold">
                                        {count}
                                      </div>
                                    </div>
                                    <div className="fw-500 fs-16">
                                      Orders in last month
                                    </div>
                                  </div>
                                  {productDetails?.inventoryInfo &&
                                  productDetails.inventoryInfo.length &&
                                  productDetails?.inventoryInfo[0] ? (
                                    productDetails.inventoryInfo[0]
                                      .quantityTypes &&
                                    productDetails.inventoryInfo[0]
                                      .quantityTypes.length ? (
                                      <>
                                        {productDetails.inventoryInfo[0].quantityTypes.map(
                                          (quantVal: any) => {
                                            return (
                                              <div className="bg-light border rounded min-w-125px py-3 px-4 me-6 mb-3">
                                                <div className="d-flex align-items-center">
                                                  <div className="fs-2 fw-bolder">
                                                    <OverlayTrigger
                                                      trigger="hover"
                                                      placement="bottom"
                                                      overlay={
                                                        remainingQuantity
                                                      }
                                                    >
                                                      <span>
                                                        {quantVal.remainingQty}
                                                      </span>
                                                    </OverlayTrigger>{' '}
                                                    +{' '}
                                                    <OverlayTrigger
                                                      trigger="hover"
                                                      placement="bottom"
                                                      overlay={reserveQuantity}
                                                    >
                                                      <span>
                                                        {quantVal.reservedQty}
                                                      </span>
                                                    </OverlayTrigger>{' '}
                                                    ={' '}
                                                    <OverlayTrigger
                                                      trigger="hover"
                                                      placement="bottom"
                                                      overlay={totalQuantity}
                                                    >
                                                      <span>
                                                        {quantVal.stockCount}
                                                      </span>
                                                    </OverlayTrigger>{' '}
                                                    <img
                                                      src={cartoon}
                                                      width={20}
                                                      height={19}
                                                      alt=""
                                                    />
                                                  </div>
                                                </div>
                                                <div className="fw-500 fs-16">
                                                  {quantVal.type ===
                                                    CartonWithDozens ||
                                                  quantVal.type ===
                                                    CartonWithPieces
                                                    ? 'Carton '
                                                    : ''}
                                                  {quantVal.type === Dozen
                                                    ? 'Dozen '
                                                    : ''}
                                                  {quantVal.type === Piece
                                                    ? 'Piece '
                                                    : ''}{' '}
                                                  in stock
                                                </div>
                                              </div>
                                            );
                                          }
                                        )}
                                      </>
                                    ) : (
                                      <></>
                                    )
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                            </div>
                          </Col>
                        </div>
                      </div>
                    </Card.Body>
                    <Row className="align-items-center px-lg-9 px-6">
                      <Col sm>
                        <div className="d-flex h-70px">
                          <ul className="nav nav-stretch nav-line-tabs nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-transparent fs-18 fw-600">
                            {createTabs()}
                          </ul>
                        </div>
                      </Col>
                    </Row>
                  </Card>{' '}
                  <>
                    <Col lg={12}>
                      <div
                        className="tab-content"
                        id="myTabContent"
                      >
                        <div className="tab-pane fade show active">
                          <>{renderTabContent()}</>
                        </div>
                      </div>
                    </Col>
                  </>
                </>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default ProductDetails;
