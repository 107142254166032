import { useEffect, useState } from 'react';
import { Col, Row, Card, Form } from 'react-bootstrap';
import { KTSVG } from '../../../_admin/helpers';
import { CustomSelect } from '../../custom/Select/CustomSelect';
import CustomDatePicker from '../../custom/DateRange/DatePicker';
import Pagination from '../../../Global/pagination';
import APICallService from '../../../api/apiCallService';
import { customerService, dashboard, master } from '../../../api/apiEndPoints';
import Method from '../../../utils/methods';
import {
  CartonWithDozens,
  CartonWithPieces,
  Dashboard,
  Dozen,
  PAGE_LIMIT,
  Piece,
} from '../../../utils/constants';
import Loader from '../../../Global/loader';
import { CustomComponentSelect } from '../../custom/Select/CustomComponentSelect';
import { warehouse as warehouseStoreString } from '../../../utils/storeString';
import { getKey } from '../../../Global/history';

const TopSellers = () => {
  let tempWarehouse: any = getKey(warehouseStoreString.warehouseRef);
  tempWarehouse = JSON.parse(tempWarehouse);
  const [startDate, setStartDate] = useState<any>(new Date());
  const [sellerData, setSellerData] = useState<any>([]);
  const [filterProducts, setFilterProducts] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(false);
  const [districts, setDistricts] = useState([]);
  const [search, setSearch] = useState<string>('');
  const [selectedDistrict, setSelectedDistrict] = useState<any>();
  const [endDate, setEndDate] = useState<any>(new Date());
  const [page, setPage] = useState<any>(1);
  const [active, setActive] = useState(-1);
  const [pageLimit, setPageLimit] = useState<any>(PAGE_LIMIT);
  const [categories, setCategories] = useState<any>([]);
  useEffect(() => {
    (async () => {
      await fetchReports(
        startDate,
        endDate,
        selectedDistrict,
        page,
        pageLimit,
        categories,
        search
      );
      await fetchDistrict(1, 10);
      await fetchCategory();
    })();
  }, []);
  const fetchDistrict = async (pageNo: number, limit: number) => {
    let params = {};
    let apiService = new APICallService(customerService.listDistrict, params,'','','','',Dashboard,tempWarehouse?._id || null);
    let response = await apiService.callAPI();
    let data: any = [];
    response.records.map((val: any) => {
      data.push({
        value: val._id,
        label: val.name,
        title: val.name,
        _id: val._id,
        name: val.name,
      });
    });
    setDistricts(data);
  };
  const fetchReports = async (
    fromDate: any,
    toDate: any,
    district: any,
    pageNo: number,
    limit: number,
    categories: any,
    searchValue: string
  ) => {
    setLoading(true);
    let params: any = {
      fromDate: fromDate,
      toDate: toDate,
      pageNo: pageNo,
      limit: limit,
      needCount: true,
      searchTerm: searchValue,
      utcOffset: new Date().getTimezoneOffset(),
      reportType: '0',
    };
    if (district !== undefined) {
      params = { ...params, 'districts[]': district._id };
    }
    if (categories !== undefined && categories.length) {
      categories.map((val: any, index: number) => {
        params = { ...params, ['categories[' + index + ']']: val.id };
      });
    }
    if (tempWarehouse?._id) {
      params = {
        ...params,
        warehouse: tempWarehouse?._id,
      };
    }
    let apiService = new APICallService(dashboard.sellerReport, params,'','','','',Dashboard,tempWarehouse?._id);
    let response = await apiService.callAPI();
    if (response.total) {
      setTotalRecords(response.total);
    }
    setSellerData(response.records);
    setLoading(false);
  };
  const fetchCategory = async () => {
    let params:any = {
      categoriesDepth: 1,
    };
     if (tempWarehouse?._id) {
       params = {
         ...params,
         warehouse: tempWarehouse?._id,
       };
     }
    let apiService = new APICallService(master.categoryList,params,'','','','',Dashboard,tempWarehouse?._id || null);
    let response = await apiService.callAPI();
    if (response.records) {
      let temp: any = [];
      response.records.map((val: any) => {
        temp.push({
          value: val.title,
          name: val.title,
          title: val.title,
          id: val._id,
          label: (
            <>
              <div className="symbol symbol-40px symbol-circle border me-2">
                <img
                  src={val.image}
                  className="object-fit-contain"
                  alt=""
                />
              </div>
              <label
                className="form-check-label truncate-2 fs-16 fw-600 text-dark"
                htmlFor="Ex2"
              >
                {val.title}
              </label>
            </>
          ),
        });
      });
      setFilterProducts(temp);
    }
  };
  const handleChange = async ([startDate, endDate]: any) => {
    setStartDate(startDate);
    setEndDate(endDate);
    if (startDate && endDate) {
      await fetchReports(
        Method.convertDateToFormat(startDate, 'YYYY-MM-DD'),
        Method.convertDateToFormat(endDate, 'YYYY-MM-DD'),
        selectedDistrict,
        page,
        pageLimit,
        categories,
        search
      );
    }
  };
  const handleDistrictChange = async (event: any) => {
    if (event) {
      setSelectedDistrict(event);
      await fetchReports(
        startDate,
        endDate,
        event,
        page,
        pageLimit,
        categories,
        search
      );
    } else {
      setSelectedDistrict(undefined);
      await fetchReports(
        startDate,
        endDate,
        undefined,
        page,
        pageLimit,
        categories,
        search
      );
    }
  };
  const handleCurrentPage = async (val: number) => {
    setLoading(true);
    setPage(val);
    await fetchReports(
      startDate,
      endDate,
      selectedDistrict,
      val,
      pageLimit,
      categories,
      search
    );
    setLoading(false);
  };
  const handleNextPage = async (val: number) => {
    setLoading(true);
    setPage(val + 1);
    await fetchReports(
      startDate,
      endDate,
      selectedDistrict,
      val + 1,
      pageLimit,
      categories,
      search
    );
    setLoading(false);
  };
  const handlePreviousPage = async (val: number) => {
    setLoading(true);
    setPage(val - 1);
    await await fetchReports(
      startDate,
      endDate,
      selectedDistrict,
      val - 1,
      pageLimit,
      categories,
      search
    );
    setLoading(false);
  };
  const handlePageLimit = async (event: any) => {
    setLoading(true);
    setPage(1);
    await setPageLimit(parseInt(event.target.value));
    await fetchReports(
      startDate,
      endDate,
      selectedDistrict,
      1,
      event.target.value,
      categories,
      search
    );
    setLoading(false);
  };
  const handleCategoryFilter = async (event: any) => {
    setCategories(event);
    setPage(1);
    await fetchReports(
      startDate,
      endDate,
      selectedDistrict,
      page,
      pageLimit,
      event,
      search
    );
  };
  const handleSearch = async (value: string) => {
    setSearch(value);
    setPage(1);
    setLoading(true);
    await fetchReports(
      startDate,
      endDate,
      selectedDistrict,
      page,
      pageLimit,
      categories,
      value
    );
    setLoading(false);
  };
  return (
    <>
      <div className="p-9">
        <Row className="align-items-center g-5">
          <Col
            xs
            className="fs-22 fw-bolder"
          >
            Top sellers
          </Col>
          <Col xs="auto">
            <div className="d-flex align-items-center min-md-264px position-relative">
              <KTSVG
                path="/media/icons/duotune/general/gen021.svg"
                className="svg-icon-3 position-absolute ms-4"
              />
              <input
                type="text"
                id="kt_filter_search"
                className="form-control form-control-custom min-h-50px ps-12 text-dark"
                placeholder="Search by seller name…"
                value={search}
                onChange={(event: any) => {
                  handleSearch(event.target.value.trimStart());
                }}
              />
            </div>
          </Col>
        </Row>
        <Card className="bg-f9f9f9 mt-7">
          <Card.Body>
            <Row>
              <Col md={4}>
                <Form.Label className="fs-16 fw-medium">
                  Filter by district
                </Form.Label>
                <CustomSelect
                  backgroundColor="#ffff"
                  minHeight="60px"
                  disabled={loading}
                  default={selectedDistrict}
                  options={districts}
                  loadingMessage={'Fetching Data'}
                  isClearable={true}
                  onChange={(e: any) => handleDistrictChange(e)}
                />
              </Col>
              <Col md={4}>
                <Form.Label className="fs-16 fw-medium">
                  Filter by categories
                </Form.Label>
                {/* <CustomSelect
                  backgroundColor="#ffff"
                  minHeight="60px"
                /> */}
                <CustomComponentSelect
                  // onChange={(options: any) => {
                  //   if (Array.isArray(options)) {
                  //   }
                  // }}
                  isDisabled={loading}
                  hideSelectedOptions={false}
                  options={filterProducts}
                  onChange={(event: any) => {
                    handleCategoryFilter(event);
                  }}
                  isMulti={true}
                />
              </Col>
              <Col md={4}>
                <Form.Label className="fs-16 fw-medium">
                  Filter by date
                </Form.Label>
                <CustomDatePicker
                  className="bg-white min-h-60px fs-16 fw-bold text-dark min-w-md-260px"
                  onChange={handleChange}
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  dateFormat="dd/MM/yyyy"
                  showFullMonthYearPicker={true}
                  maxDate={new Date()}
                  inputTextBG="bg-white"
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Card className="border border-r10px mt-7">
          <Card.Body className="p-0">
            <div className="table-responsive">
              <table className="table table-row-bordered datatable align-middle gs-7 gy-4 mb-0">
                <thead>
                  <tr className="fs-16 fw-bold text-dark h-70px align-middle">
                    <th className="min-w-175px">Business name</th>
                    <th className="min-w-125px">Units sold</th>
                    <th className="min-w-125px">Total orders</th>
                    <th className="min-w-125px">Total sales</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <>
                      <tr>
                        <td colSpan={4}>
                          <div className="d-flex justify-content-center">
                            <Loader loading={loading} />
                          </div>
                        </td>
                      </tr>
                    </>
                  ) : (
                    <>
                      {sellerData && sellerData.length ? (
                        <>
                          {sellerData.map((val: any, index: number) => {
                            return (
                              <tr key={index}>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <div className="symbol symbol-50px border me-3">
                                      <img
                                        width={50}
                                        height={50}
                                        src={val.business.image}
                                        alt=""
                                        className="object-fit-contain"
                                      />
                                    </div>
                                    <span className="fw-600 fs-15">
                                      {' '}
                                      {val.business.name}
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <div className="badge badge-light border-r23px">
                                    <span className="fs-15 fw-600 text-dark p-3 d-block">
                                      {val.quantityTypes
                                        .map((item: any) => {
                                          if (item.type === CartonWithDozens) {
                                            return `${item.stockCount} C(D)`;
                                          }
                                          if (item.type === CartonWithPieces) {
                                            return `${item.stockCount} C(P)`;
                                          }
                                          if (item.type === Dozen) {
                                            return `${item.stockCount} D`;
                                          }
                                          if (item.type === Piece) {
                                            return `${item.stockCount} P`;
                                          }
                                          return '';
                                        })
                                        .join(', ')}
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <span className="fs-15 fw-600">
                                    {val.totalOrders}
                                  </span>
                                </td>
                                <td>
                                  <span className="fs-15 fw-600">
                                    {'TSh ' +
                                      Method.getGeneralizedAmount(
                                        val.totalSales
                                      )}
                                  </span>
                                </td>
                              </tr>
                            );
                          })}
                        </>
                      ) : (
                        <>
                          <tr>
                            <td colSpan={4}>
                              <div className="d-flex justify-content-center">
                                <span className="fs-14 fw-500">
                                  No Data found
                                </span>
                              </div>
                            </td>
                          </tr>
                        </>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </Card.Body>
        </Card>
        <>
          {sellerData && sellerData.length ? (
            <Pagination
              totalRecords={totalRecords}
              currentPage={page}
              handleCurrentPage={(event: any) => {
                handleCurrentPage(event);
              }}
              handleNextPage={(event: any) => {
                handleNextPage(event);
              }}
              handlePreviousPage={(event: any) => {
                handlePreviousPage(event);
              }}
              pageLimit={pageLimit}
              handlePageLimit={(event: any) => {
                handlePageLimit(event);
              }}
            />
          ) : (
            <></>
          )}
        </>
      </div>
    </>
  );
};
export default TopSellers;
