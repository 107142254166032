import {
  Accepted,
  Actived,
  AdvertisementCompleted,
  AdvertisementOngoing,
  AdvertisementRequest,
  AdvertisementUpcoming,
  AreaBasedCustomerSegmentationReport,
  BrandWiseSalesPerformanceReport,
  Cart,
  CategoryAndSubCategoryAnalysisReport,
  CommissionAnalysisReport,
  CustomerPurchaseBehaviourReport,
  DateSelection,
  Deactivated,
  FrequentCustomerPurchasePatternAnalysisReport,
  General,
  GeographicSalesInsightReport,
  InfoRequired,
  InventoryStatusReport,
  MultiLineText,
  MultiSelection,
  NotRegistered,
  OrderCancelled,
  OrderDelivered,
  OrderOutForDelivery,
  OrderProcessed,
  OrderSubmitted,
  Product,
  ProductRejectedState,
  ProductSalesPerformanceReport,
  Registered,
  Rejected,
  RevenueGenerationReport,
  SingleLineText,
  SingleSelection,
  Skip,
  SuggestOptions,
  TextGuide,
  UnderReview,
  VerificationPending,
} from './constants';
import green from '../_admin/assets/media/svg_uTrade/green_dot.svg';
import red from '../_admin/assets/media/svg_uTrade/red_dot.svg';
import yellow from '../_admin/assets/media/svg_uTrade/yellow_dot.svg';
import gray from '../_admin/assets/media/svg_uTrade/gray_dot.svg';
import accepted from '../_admin/assets/media/select/accepted.png';
import informationRequired from '../_admin/assets/media/select/informationRequired.png';
import Review from '../_admin/assets/media/select/review.png';
export const IMAGES = {
  ErrorWarnGray:
    require('../_admin/assets/media/svg_uTrade/error-warning_gray.svg').default,
  ArrowUp: require('../_admin/assets/media/svg_uTrade/up-arrow.svg').default,
  ArrowDown: require('../_admin/assets/media/svg_uTrade/arrow-down.svg')
    .default,
};
export const expiryJSON = [
  {
    value: '-3',
    label: 'Deleted Products',
    title: 'Deleted Products',
  },
  {
    value: '-1',
    label: 'Expired',
    title: 'Expired',
  },
  {
    value: '-2',
    label: 'Expires After 1 year',
    title: 'Expires After 1 year',
  },
  {
    value: '1',
    label: 'Expires in 1 month',
    title: 'Expires in 1 month',
  },
  {
    value: '2',
    label: 'Expires in 2 month',
    title: 'Expires in 2 month',
  },
  {
    value: '3',
    label: 'Expires in 3 month',
    title: 'Expires in 3 month',
  },
  {
    value: '4',
    label: 'Expires in 4 month',
    title: 'Expires in 4 month',
  },
  {
    value: '5',
    label: 'Expires in 5 month',
    title: 'Expires in 5 month',
  },
  {
    value: '6',
    label: 'Expires in 6 month',
    title: 'Expires in 6 month',
  },
  {
    value: '7',
    label: 'Expires in 7 month',
    title: 'Expires in 7 month',
  },
  {
    value: '8',
    label: 'Expires in 8 month',
    title: 'Expires in 8 month',
  },
  {
    value: '9',
    label: 'Expires in 9 month',
    title: 'Expires in 9 month',
  },
  {
    value: '10',
    label: 'Expires in 10 month',
    title: 'Expires in 10 month',
  },
  {
    value: '11',
    label: 'Expires in 11 month',
    title: 'Expires in 11 month',
  },
  {
    value: '12',
    label: 'Expires in 12 month',
    title: 'Expires in 12 month',
  },
];
export const productStatusJSON = [
  {
    value: Actived,
    label: (
      <>
        <img
          src={green}
          width={12}
          height={12}
          className="me-3"
          alt=""
        />
        <span className="fs-16 fw-600 text-black mb-0">Active</span>
      </>
    ),
    title: 'Active',
  },
  {
    value: VerificationPending,
    label: (
      <>
        <img
          src={yellow}
          width={12}
          height={12}
          className="me-3"
          alt=""
        />
        <span className="fs-16 fw-600 text-black mb-0">
          Verification pending
        </span>
      </>
    ),
    title: 'Verification pending',
  },
  {
    value: Deactivated,
    label: (
      <>
        <img
          src={gray}
          width={12}
          height={12}
          className="me-3"
          alt=""
        />
        <span className="fs-16 fw-600 text-black mb-0">Deactivated</span>
      </>
    ),
    title: 'Deactivated',
  },
  {
    value: ProductRejectedState,
    label: (
      <>
        <img
          src={red}
          width={12}
          height={12}
          className="me-3"
          alt=""
        />
        <span className="fs-16 fw-600 text-black mb-0">Rejected</span>
      </>
    ),
    title: 'Rejected',
  },
];
export const expiryMonthsJSON = [
  {
    value: 1,
    label: '1 month',
    title: '1 month',
  },
  {
    value: 2,
    label: '2 months',
    title: '2 months',
  },
  {
    value: 3,
    label: '3 months',
    title: '3 months',
  },
  {
    value: 4,
    label: '4 months',
    title: '4 months',
  },
  {
    value: 5,
    label: '5 months',
    title: '5 months',
  },
  {
    value: 6,
    label: '6 months',
    title: '6 months',
  },
  {
    value: 7,
    label: '7 months',
    title: '7 months',
  },
  {
    value: 8,
    label: '8 months',
    title: '8 months',
  },
  {
    value: 9,
    label: '9 months',
    title: '9 months',
  },
  {
    value: 10,
    label: '10 months',
    title: '10 months',
  },
  {
    value: 11,
    label: '11 months',
    title: '11 months',
  },
  {
    value: 12,
    label: '12 months',
    title: '12 months',
  },
];
export const operationJSON = [
  {
    value: '1',
    label: 'New Stock Updated',
    title: 'New Stock Updated',
  },
  {
    value: '2',
    label: 'Customer return',
    title: 'Customer return',
  },
  {
    value: '3',
    label: 'Goods return request',
    title: 'Goods return request',
  },
  {
    value: '4',
    label: 'Missing/Damaged goods',
    title: 'Missing/Damaged goods',
  },
  {
    value: '5',
    label: 'New order',
    title: 'New order',
  },
  {
    value: '6',
    label: 'New transfer',
    title: 'New transfer',
  },
];
export const orderStatusJSON = [
  {
    value: OrderSubmitted,
    label: 'New orders',
    title: 'New orders',
  },
  {
    value: OrderProcessed,
    label: 'In progress',
    title: 'In progress',
  },
  {
    value: OrderOutForDelivery,
    label: 'Out for delivery',
    title: 'Out for delivery',
  },
  {
    value: OrderDelivered,
    label: 'Delivered',
    title: 'Delivered',
  },
  {
    value: OrderCancelled,
    label: 'Cancelled',
    title: 'Cancelled',
  },
];
export const advertisementStatusJSON = [
  {
    value: AdvertisementRequest,
    label: 'New request',
    title: 'New request',
  },
  {
    value: AdvertisementOngoing,
    label: 'Ongoing',
    title: 'Ongoing',
  },
  {
    value: AdvertisementUpcoming,
    label: 'Upcoming',
    title: 'Upcoming',
  },
  {
    value: AdvertisementCompleted,
    label: 'Completed',
    title: 'Completed',
  },
];
export const goodsRequestStatusJSON = [
  {
    value: 1,
    label: 'Pending',
    title: 'Pending',
  },
  {
    value: 2,
    label: 'Accepted',
    title: 'Accepted',
  },
  {
    value: 3,
    label: 'Completed',
    title: 'Completed',
  },
  {
    value: 4,
    label: 'Rejected',
    title: 'Rejected',
  },
];
export const refundListJSON = [
  {
    value: 0,
    label: 'All Orders',
    title: 'All Orders',
  },
  {
    value: 1,
    label: 'Refunded Orders',
    title: 'Refunded Orders',
  },
  {
    value: 2,
    label: 'Not Refunded Orders',
    title: 'Not Refunded Orders',
  },
];
export const technicalDetailsOptions = [
  {
    value: SingleLineText,
    name: 'SingleLineTextbox',
    label: (
      <>
        <span className="fs-16 fw-600">Single line textbox</span>
      </>
    ),
    title: 'Single line textbox',
  },
  {
    value: SingleSelection,
    name: ' SingleSelection',
    label: (
      <>
        <span className="fs-16 fw-600">Single selection</span>
      </>
    ),
    title: 'Single selection',
  },
  {
    value: MultiLineText,
    name: 'MultiLineTextbox',
    label: (
      <>
        <span className="fs-16 fw-600">Multi line textbox</span>
      </>
    ),
    title: 'Multi line textbox',
  },
  {
    value: MultiSelection,
    name: 'MultiSelection',
    label: (
      <>
        <span className="fs-16 fw-600">Multi selection</span>
      </>
    ),
    title: 'Multi selection',
  },
  {
    value: DateSelection,
    name: 'DateSelection',
    label: (
      <>
        <span className="fs-16 fw-600">Date selection</span>
      </>
    ),
    title: 'Date selection',
  },
];
export const kycOptions = [
  {
    value: UnderReview - 1,
    name: 'underReview',
    title: 'Under Review',
    label: (
      <>
        <img
          src={Review}
          height={11}
          className=" me-2"
          alt=""
        />
        <span>Under Review</span>
      </>
    ),
  },
  {
    value: InfoRequired - 1,
    name: 'informationRequired',
    label: (
      <>
        <img
          src={informationRequired}
          height={11}
          className=" me-2"
          alt=""
        />
        <span>Information Required</span>
      </>
    ),
    title: 'Information Required',
  },
  {
    value: Accepted - 1,
    name: 'accepted',
    label: (
      <>
        <img
          src={accepted}
          height={11}
          className=" me-2"
          alt=""
        />
        <span>Accepted</span>
      </>
    ),
    title: 'Accepted',
  },
  {
    value: Rejected - 1,
    name: 'rejected',
    label: (
      <>
        <img
          src={informationRequired}
          height={11}
          className=" me-2"
          alt=""
        />
        <span>Rejected</span>
      </>
    ),
    title: 'Rejected',
  },
];
export const variantJSON = [
  {
    value: Skip,
    name: 'SkipNoNeed',
    label: (
      <>
        <span className="fs-16 fw-600">Skip (Not needed)</span>
      </>
    ),
    title: 'Skip (Not needed)',
  },
  {
    value: SuggestOptions,
    name: 'SuggestOptions',
    label: (
      <>
        <span className="fs-16 fw-600">Suggest options</span>
      </>
    ),
    title: 'Suggest options',
  },
  {
    value: TextGuide,
    name: 'TextGuide',
    label: (
      <>
        <span className="fs-16 fw-600">Text guide</span>
      </>
    ),
    title: 'Text guide',
  },
];
export const paymentStatusJSON = [
  {
    value: 3,
    label: 'All orders',
    title: 'All orders',
  },
  {
    value: 2,
    label: 'Not paid',
    title: 'Not paid',
  },
  {
    value: 1,
    label: 'Paid',
    title: 'Paid',
  },
];
export const orderJSON = [
  {
    value: 3,
    label: 'All orders',
    title: 'All orders',
  },
  {
    value: 2,
    label: 'Not paid',
    title: 'Not paid',
  },
  {
    value: 1,
    label: 'Paid',
    title: 'Paid',
  },
];
export const notificationJSON = [
  {
    value: 1,
    label: 'Custom Notification',
    title: 'Custom Notification',
    type: General,
  },
  {
    value: 2,
    label: 'Top Ordered Product',
    title: 'Top Ordered Product',
    type: Product,
  },
  {
    value: 3,
    label: 'Customer Cart',
    title: 'Customer Cart',
    type: Cart,
  },
  {
    value: 4,
    label: 'All Products',
    title: 'All Products',
    type: Product,
  },
  {
    value: 5,
    label: 'Top Products',
    title: 'Top Products',
    type: Product,
  },
  {
    value: 6,
    label: 'Top Customers',
    title: 'Top Customers',
    type: General,
  },
];
export const stockJSON = [
  {
    value: '10',
    label: '10 Days',
    title: '10 Days',
  },
  {
    value: '15',
    label: '15 Days',
    title: '15 Days',
  },
  {
    value: '30',
    label: '30 Days',
    title: '30 Days',
  },
  {
    value: '60',
    label: '60 Days',
    title: '60 Days',
  },
  {
    value: '90',
    label: '90 Days',
    title: '90 Days',
  },
  {
    value: '180',
    label: '180 Days',
    title: '180 Days',
  },
  {
    value: '360',
    label: '360 Days',
    title: '360 Days',
  },
];
export const customerStateJSON = [
  {
    value: -1,
    label: (
      <>
        <img
          src={gray}
          width={12}
          height={12}
          className="me-3"
          alt=""
        />
        <span className="fs-16 fw-600 text-black mb-0">All</span>
      </>
    ),
    title: 'All',
  },
  {
    value: Registered,
    label: (
      <>
        <img
          src={green}
          width={12}
          height={12}
          className="me-3"
          alt=""
        />
        <span className="fs-16 fw-600 text-black mb-0">Completed</span>
      </>
    ),
    title: 'Completed',
  },
  {
    value: NotRegistered,
    label: (
      <>
        <img
          src={red}
          width={12}
          height={12}
          className="me-3"
          alt=""
        />
        <span className="fs-16 fw-600 text-black mb-0">Pending</span>
      </>
    ),
    title: 'Pending',
  },
];
export const ReportTypes = [
  {
    value: CustomerPurchaseBehaviourReport,
    label: 'Customer Purchase Behavior Analysis',
    title: 'Customer Purchase Behavior Analysis',
  },
  {
    value: GeographicSalesInsightReport,
    label: 'Geographic Sales Insights Report',
    title: 'Geographic Sales Insights Report',
  },
  {
    value: AreaBasedCustomerSegmentationReport,
    label: 'Area-Based Customer Segmentation',
    title: 'Area-Based Customer Segmentation',
  },
  {
    value: FrequentCustomerPurchasePatternAnalysisReport,
    label: 'Frequent Customer Purchase Pattern Analysis Report',
    title: 'Frequent Customer Purchase Pattern Analysis Report',
  },
  {
    value: ProductSalesPerformanceReport,
    label: 'Product Sales Performance Report',
    title: 'Product Sales Performance Report',
  },
  {
    value: BrandWiseSalesPerformanceReport,
    label: 'Brand-Wise Sales Performance Report',
    title: 'Brand-Wise Sales Performance Report',
  },
  {
    value: CategoryAndSubCategoryAnalysisReport,
    label: 'Category and Sub-Category Sales Analysis Report',
    title: 'Category and Sub-Category Sales Analysis Report',
  },
  {
    value: RevenueGenerationReport,
    label: 'Revenue Generation Report',
    title: 'Revenue Generation Report',
  },
  {
    value: CommissionAnalysisReport,
    label: 'Commission Analysis Report',
    title: 'Commission Analysis Report',
  },
  {
    value: InventoryStatusReport,
    label: 'Product Variant Inventory Status Report',
    title: 'Product Variant Inventory Status Report',
  },
];
export const receivableOrderJSON = [
  {
    value: 1,
    label: 'All orders',
    title: 'All orders',
  },
  {
    value: 2,
    label: 'Pay later',
    title: 'Not paid',
  },
];
export const driverTypeJSON = [
  {
    value: 1,
    label: 'Freelance',
    title: 'Freelance',
  },
  {
    value: 2,
    label: 'System',
    title: 'System',
  },
  {
    value: 3,
    label: 'All Drivers',
    title: 'All Drivers',
  },
];
export const driverStatusJSON = [
  {
    value: 1,
    label: 'Not Deleted',
    title: 'Not Deleted',
  },
  {
    value: 2,
    label: 'Deleted',
    title: 'Deleted',
  },
  {
    value: 3,
    label: 'All',
    title: 'All',
  },
];
export const roundValueJSON = [
  {
    value: 10,
    label: '10',
    title: '10',
  },
  {
    value: 100,
    label: '100',
    title: '100',
  },
  {
    value: 1000,
    label: '1000',
    title: '1000',
  },
];
