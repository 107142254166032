import { Card, Col, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useEffect, useState } from "react";
import { routePlanning } from "../../../api/apiEndPoints";
import { routePlanningJSON } from "../../../api/apiJSON/routePlanning";
import Method from "../../../utils/methods";
import APICallService from "../../../api/apiCallService";
import Loader from "../../../Global/loader";
import {
  OrdersDelivery,
  RoutePlanCancelled,
  RoutePlanCompleted,
  RoutePlanFailed,
  RoutePlanned,
} from "../../../utils/constants";
import clsx from "clsx";
// import { IMAGES } from '../../../utils/staticJSON';
import { useLocation, useNavigate } from "react-router-dom";
import { IMAGES } from "../../../utils/dummyJSON";
import Notification from "../../notification/notification2";
import GeneratePlan from "../../modals/generate-plan";
import { getKey, setKey } from "../../../Global/history";
import {
  listOrdersDelivery,
  warehouse as warehouseStoreString,
} from "../../../utils/storeString";
import { error } from "../../../Global/toast";
const DeliveryRoutesPlanDetails = () => {
  let tempWarehouse: any = getKey(warehouseStoreString.warehouseRef);
  tempWarehouse = JSON.parse(tempWarehouse);
  const navigate = useNavigate();
  const { state }: any = useLocation();
  const [fetchLoader, setFetchLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [routeId, setRouteId] = useState("");
  const [summary, setSummary] = useState<any>({});
  const [isPlanCreate, setIsPlanCreate] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [availableVehicle, setAvailableVehicle] = useState<any>(true);
  const [validation, setValidation] = useState<any>(false);
  const [discarded, setDiscarded] = useState<any>(false);
  const [selectedVehicle, setSelectedVehicle] = useState<any>([]);
  const [showGeneratePlan, setShowGeneratePlan] = useState<boolean>(false);
  const [analytic, setAnalytic] = useState<any>([]);
  const [coordinates, setCoordinates] = useState<any>([]);
  const [vehicleData, setVehicleData] = useState<any>();
  const [showRouteDetails, setShowRouteDetails] = useState<any>(false);
  const [vehicleId, setVehicleId] = useState<any>();

  useEffect(() => {
    (async () => {
      setFetchLoader(true);
      await fetchSummary();
      setFetchLoader(false);
    })();
  }, []);
  useEffect(() => {
    window.onbeforeunload = function (e: any) {
      var message = "Are you sure you want to leave this page?";
      if (typeof e == "undefined") {
        e = window.event;
      }
      if (e) {
        e.returnValue = message;
      }
      return message;
    };
  }, []);
  const fetchSummary = async (routeId?: string) => {
    let params: any = {
      date: Method.getTodayDate("YYYY-MM-DD"),
      orders: state.orderIds,
    };
    if (routeId) {
      params = { ...params, route: routeId };
    }
    let apiService = new APICallService(
      routePlanning.planSummary,
      routePlanningJSON.listPlanSummary(params),
      "",
      "",
      "",
      "",
      OrdersDelivery,
      tempWarehouse?._id
    );
    let response = await apiService.callAPI();
    if (response.status === RoutePlanCompleted) {
      navigate("/orders/review-order-list", {
        state: {
          routeId: response._id,
        },
      });
      state.onHide();
    }
    if (response) {
      let temp = { ...response };
      temp.planData.plan.trucksWithOrders =
        temp.planData.plan.trucksWithOrders.map((val: any) => {
          return {
            ...val,
            isOpen: false,
          };
        });
      let result = temp.planData.plan.trucksWithOrders.reduce(
        (acc: any, current: any) => {
          acc.totalTrucks += 1;
          acc.totalConsumedWeight += current.consumedWeight;
          acc.totalOrders += current.orders.length;
          return acc;
        },
        { totalTrucks: 0, totalConsumedWeight: 0, totalOrders: 0 }
      );
      console.log(result);
      setAnalytic(result);
      setSummary(temp);
    } else {
      window.history.back();
    }
  };
  // const scheduleFetchSummary = () => {
  //   setTimeout(async () => {
  //     if (isPlanCreate) {
  //       await fetchSummary(routeId);
  //     } // Schedule the next execution
  //     scheduleFetchSummary();
  //   }, 10000); // 10 seconds in milliseconds
  // };
  const kgToTons = (kilograms: string) => {
    // Conversion factor
    const conversionFactor = 0.001;
    // Calculate metric tons
    const tons = parseInt(kilograms) * conversionFactor;
    return tons.toFixed(2);
  };
  const handleVehicleSelect = (id: string) => {
    let temp = [...selectedVehicle];
    if (temp.includes(id)) {
      temp = temp.filter((item) => item != id);
    } else {
      temp.push(id);
    }
    if (temp.length) {
      setValidation(false);
    } else {
      setValidation(true);
    }
    setSelectedVehicle(temp);
  };
  const handleSelectAll = () => {
    let temp: any = [];
    if (selectedVehicle.length !== summary.availableVehicles.length) {
      summary.availableVehicles.map((val: any) => {
        temp.push(val._id);
      });
    }
    if (temp.length) {
      setValidation(false);
    } else {
      setValidation(true);
    }
    setSelectedVehicle(temp);
  };
  const planRoute = async () => {
    let params: any = {
      date: Method.getTodayDate("YYYY-MM-DD"),
      orders: state.orderIds,
    };
    if (!availableVehicle) {
      if (!selectedVehicle.length) {
        setValidation(true);
        return;
      } else {
        params = { ...params, vehicles: selectedVehicle };
      }
    }
    setLoading(true);
    let apiService = new APICallService(
      routePlanning.planCreate,
      routePlanningJSON.createPlan(params),
      "",
      "",
      "",
      "",
      OrdersDelivery,
      tempWarehouse?._id
    );
    let response = await apiService.callAPI();
    if (response) {
      setRouteId(response._id);
      // setSummary(response);
      setIsPlanCreate(true);
      setKey(listOrdersDelivery.selectedOrders, [], true);
    } else {
      setShowGeneratePlan(false);
      setLoading(false);
    }
  };
  useEffect(() => {
    const checkPlanStatus = async () => {
      if (isPlanCreate) {
        let params: any = {
          date: Method.getTodayDate("YYYY-MM-DD"),
          orders: state.orderIds,
        };
        if (routeId) {
          params = { ...params, route: routeId };
        }
        let apiService = new APICallService(
          routePlanning.planSummary,
          routePlanningJSON.listPlanSummary(params),
          "",
          "",
          "",
          "",
          OrdersDelivery,
          tempWarehouse?._id
        );
        let response = await apiService.callAPI();
        if (response.status === RoutePlanned) {
          setIsPlanCreate(false);
          navigate("/orders/review-order-list", {
            state: {
              routeId: response._id,
            },
          });
          setIsPlanCreate(false);
          setShowGeneratePlan(false);
          setLoading(false);
        } else if (
          response.status === RoutePlanFailed ||
          response.status === RoutePlanCancelled
        ) {
          error("Plan Generation Failed or Cancelled");
          setIsPlanCreate(false);
          setShowGeneratePlan(false);
          setLoading(false);
        } else {
          setTimeout(checkPlanStatus, 1000); // Call again after 1 second
        }
      }
    };
    if (isPlanCreate == true) {
      checkPlanStatus();
    }
  }, [isPlanCreate]);
  const handleShow = (index: number) => {
    let temp = { ...summary };
    temp.planData.plan.trucksWithOrders[index].isOpen =
      !temp.planData.plan.trucksWithOrders[index].isOpen;
    setSummary(temp);
  };
  const handleDiscardedShow = () => {
    setDiscarded(!discarded);
  };
  const planRouteWithVehicles = async (orderIds: any, vehicleId: any) => {
    let params: any = {
      date: Method.getTodayDate('YYYY-MM-DD'),
      orders: orderIds,
    };
    if (isPlanCreate) {
      error('Route planning is in progress!!Please wait...');
    } else {
      params = { ...params, vehicles: [vehicleId] };
    }
    setLoading(true);
    let apiService = new APICallService(
      routePlanning.planCreate,
      routePlanningJSON.createPlan(params),
      '',
      '',
      '',
      '',
      OrdersDelivery,
      tempWarehouse?._id
    );
    let response = await apiService.callAPI();
    if (response) {
      setIsPlanCreate(true);
      let temp = [...selectedVehicle];
      temp.push(vehicleId);
      setSelectedVehicle(temp);
      setRouteId(response._id);
      // setSummary(response);
      setKey(listOrdersDelivery.selectedOrders, [], true);
    } else {
      setIsPlanCreate(false);
      setShowGeneratePlan(false);
      setLoading(false);
    }
  };
  const handleAddOrderToList = async (data: any,routeId:any,vehicleId:any) => {
    setLoading(true);
    const processedRefKeys = new Set();

const updatedOrders = data.map((order:any) => {
    // Check if the refKey has already been processed
    if (!processedRefKeys.has(order.refKey)) {
        // If not, mark this order as not existing and add the refKey to the set
        processedRefKeys.add(order.refKey);
        return {
            ...order,
            isExistingOrder: false // Set isExistingOrder to false for the first occurrence
        };
    } else {
        // If it has been processed, keep isExistingOrder as true
        return {
            ...order,
            isExistingOrder: true // Keep isExistingOrder as true for subsequent occurrences
        };
    }
});

// Now filter the orders based on the updated isExistingOrder
const filteredOrders = updatedOrders.filter((order:any) => !order.isExistingOrder);
console.log(filteredOrders);
let orders:any=[]
filteredOrders.map((val:any)=>{
  orders.push(val.postData._id)
})
    let params = {
      order: orders,
    };
    let apiService = new APICallService(
      routePlanning.addOrder,
      routePlanningJSON.addOrder(params),
      {
        routeId: routeId,
        vehicleId: vehicleId,
      },
      '',
      '',
      '',
      OrdersDelivery,
      tempWarehouse?._id || null
    );
    let response: any = await apiService.callAPI();
    if (response) {
      setIsPlanCreate(true);
      let temp = [...selectedVehicle];
      temp.push(vehicleId);
      setSelectedVehicle(temp);
      setRouteId(routeId);
      // setSummary(response);
      setKey(listOrdersDelivery.selectedOrders, [], true);
    } else {
      setIsPlanCreate(false);
      setShowGeneratePlan(false);
      setLoading(false);
    }
    setLoading(false);
  };
  return (
    <>
      {fetchLoader ? (
        <></>
      ) : (
        <>
          {summary && Object.keys(summary).length > 0 ? (
            <>
              {summary.planData.plan.discardedOrders.length ? (
                <div>
                  <Notification
                    bgColor="bg-light-danger"
                    textColor="dark"
                    text={`You have selected ${state.orderIds.length} orders, but ${summary.planData.plan.discardedOrders.length} orders are due to either vehicle capacity or the time duration needed to deliver the order, which will not fit in any truck.`}
                    onClick={() => {
                      handleDiscardedShow();
                    }}
                    click={true}
                    clickText={
                      !discarded
                        ? "Show Discarded Orders"
                        : "Show Planned Orders"
                    }
                  />
                </div>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
        </>
      )}
      <div className="p-9">
        <Row className="align-items-center g-md-8 g-6 mb-5">
          <Col xs={12}>
            <Row className="align-items-center g-3">
              <Col xs>
                <h1 className="fs-22 mb-0 fw-bolder">Plan details</h1>
              </Col>
              {!fetchLoader ? (
                <>
                  {summary && Object.keys(summary).length > 0 ? (
                    <Col xs="auto">
                      <Button
                        onClick={() => {
                          setShowGeneratePlan(true);
                        }}
                      >
                        Generate Plan
                      </Button>
                      <GeneratePlan
                        show={showGeneratePlan}
                        availableVehicles={summary.availableVehicles}
                        discardedOrders={summary.planData.plan.discardedOrders}
                        onHide={() => {
                          setShowGeneratePlan(false);
                        }}
                        handleVehicleSelect={handleVehicleSelect}
                        loading={loading}
                        onSave={() => {
                          planRoute();
                        }}
                        selectedVehicle={selectedVehicle}
                        availableVehicle={availableVehicle}
                        handleSelectAll={handleSelectAll}
                        setAvailableVehicle={(val: boolean) => {
                          setAvailableVehicle(val);
                        }}
                      />
                    </Col>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
            </Row>
          </Col>
        </Row>
        {fetchLoader ? (
          <>
            <div className="d-flex justify-content-center">
              <Loader loading={fetchLoader} />
            </div>
          </>
        ) : (
          <>
            {summary && Object.keys(summary).length > 0 ? (
              <Row className="g-7">
                {discarded ? (
                  <>
                    {summary.planData.plan.discardedOrders.length ? (
                      <>
                        <Col xs={12}>
                          <Card className="p-0 bg-light">
                            <Card.Body className="p-0">
                              <div className="table-responsive">
                                <table className="table table-rounded table-row-bordered align-middle gs-9 gy-4 mb-0">
                                  <thead>
                                    <tr className="fs-16 fw-600 h-60px align-middle">
                                      <th className="w-md-150px ">Order No</th>
                                      <th className="min-w-md-475px">
                                        Shop Name/Address
                                      </th>
                                      <th className="min-w-md-150px">
                                        Duration (Warehouse)
                                      </th>
                                      <th className="w-md-200px">
                                        Distance (Warehouse)
                                      </th>
                                      <th className="min-w-md-100px text-end">
                                        Weight
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {summary.planData.plan.discardedOrders.map(
                                      (val: any, vehicleIndex: number) => {
                                        return (
                                          <>
                                            <tr>
                                              <td>
                                                <span className="text-gray d-block">
                                                  #{val.refKey}
                                                </span>
                                              </td>{" "}
                                              <td>
                                                <span className="fs-15 fw-500">
                                                  {`${val.address.name}  |  ${
                                                    val.address.addressLine1
                                                  } , ${
                                                    val.address.landmark
                                                      ? val.address.landmark
                                                      : ""
                                                  } | ${
                                                    val.address.phoneCountry
                                                  } ${val.address.phone}`}
                                                </span>
                                              </td>
                                              <td>
                                                <span className="fs-15 fw-500">
                                                  {Method.decimalHoursToHoursMinutes(
                                                    val.duration
                                                  )}
                                                </span>
                                              </td>
                                              <td>
                                                <span className="fs-15 fw-500">
                                                  {Method.metersToKilometersMeters(
                                                    val.distance
                                                  )}
                                                </span>
                                              </td>
                                              <td className="text-end">
                                                <span className="fs-15 fw-500">
                                                  {kgToTons(
                                                    val.mDimensions.orderWeight
                                                  )}
                                                </span>
                                              </td>
                                            </tr>
                                          </>
                                        );
                                      }
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </Card.Body>
                          </Card>
                        </Col>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <>
                    <Col xs={12} className="mb-7">
                      <Row className="g-6">
                        <Col xl={3} md={6} sm={4}>
                          <div className="border-r8px bg-ccebfd rounded py-4 px-5">
                            <div className="d-flex align-items-center">
                              <div className="fs-22 fw-bolder">
                                {summary.planData.plan.trucksWithOrders.length}
                              </div>
                            </div>
                            <div className="fw-500 fs-16">
                              Total Vehicle Used
                            </div>
                          </div>
                        </Col>
                        <Col xl={3} md={6} sm={4}>
                          <div className="border-r8px bg-d4e1fc rounded py-4 px-5">
                            <div className="d-flex align-items-center">
                              <div className="fs-22 fw-bolder">
                                {kgToTons(analytic.totalConsumedWeight)}
                              </div>
                            </div>
                            <div className="fw-500 fs-16">Total Weight</div>
                          </div>
                        </Col>
                        <Col xl={3} md={6} sm={4}>
                          <div className="border-r8px bg-ccf3f0 rounded py-4 px-5">
                            <div className="d-flex align-items-center">
                              <div className="fs-22 fw-bolder">
                                {analytic.totalOrders}{" "}
                                {analytic.totalOrders == 1 ? "order" : "orders"}
                              </div>
                            </div>
                            <div className="fw-500 fs-16">Total orders</div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    {summary.planData.plan.trucksWithOrders.length ? (
                      <>
                        {summary.planData.plan.trucksWithOrders.map(
                          (vehicleVal: any, vehicleIndex: number) => {
                            return (
                              <Col xs={12}>
                                <Card className="p-0 bg-light">
                                  <Card.Header
                                    className={clsx(
                                      "border h-77px",
                                      vehicleVal.isOpen ? "" : "border-r10px"
                                      // generalDataValidation.combinations[index].validation
                                      //   ? "border-danger"
                                      //   : ""
                                    )}
                                  >
                                    <Row className="fs-16 fw-600 align-items-center w-100">
                                      <Col
                                        md
                                        className="mb-md-0 mb-5 text-start"
                                      >
                                        <Button
                                          className="btn-flush btn-white w-100 text-start"
                                          variant=""
                                        >
                                          {vehicleVal.postData.dModel.name ? (
                                            <span
                                              className={clsx(
                                                "fs-22 fw-bolder text-dark"
                                              )}
                                            >
                                              {vehicleVal.postData.dModel.name}/{" "}
                                              {vehicleVal.postData.vModel.name +
                                                "-" +
                                                vehicleVal.postData.vModel
                                                  .number}
                                            </span>
                                          ) : (
                                            <></>
                                          )}{" "}
                                        </Button>
                                      </Col>
                                      {/* <Col md="auto">
                                    <div className="d-flex flex-row">
                                      <div className="d-flex align-items-center">
                                        {vehicleVal.isOpen ? (
                                          <Button
                                            variant="link"
                                            className="btn-flush"
                                            onClick={() => {
                                              handleShow(vehicleIndex);
                                            }}
                                          >
                                            <img
                                              src={IMAGES.ArrowUp}
                                              alt=""
                                            />
                                          </Button>
                                        ) : (
                                          <Button
                                            variant="link"
                                            className="btn-flush"
                                            onClick={() => {
                                              handleShow(vehicleIndex);
                                            }}
                                          >
                                            <img
                                              src={IMAGES.ArrowDown}
                                              alt=""
                                            />
                                          </Button>
                                        )}
                                      </div>
                                    </div>
                                  </Col> */}
                                   <Col md="auto">
                                        <Button
                                          className={clsx(
                                            selectedVehicle.includes(
                                              vehicleVal.postData.vModel
                                                .reference
                                            )
                                              ? 'btn-flush  w-100 text-start'
                                              : 'btn-primary  w-100 text-start'
                                          )}
                                          variant=""
                                          disabled={
                                            isPlanCreate ||
                                            selectedVehicle.includes(
                                              vehicleVal.postData.vModel
                                                .reference
                                            )
                                          }
                                          onClick={() => {
                                            let temp: any = [];
                                            vehicleVal.routeId?    vehicleVal.orders.map(
                                              (orderVal: any) => {
                                                temp.push(
                                                  orderVal
                                                );
                                              }
                                            ):vehicleVal.orders.map(
                                              (orderVal: any) => {
                                                temp.push(
                                                  orderVal.postData._id
                                                );
                                              }
                                            );
                                            setVehicleId(
                                              vehicleVal.postData.vModel
                                                .reference
                                            );
                                            setVehicleData(
                                              vehicleVal.postData.vModel
                                            );
                                            vehicleVal.routeId? 
                                            handleAddOrderToList(
                                              temp,vehicleVal.routeId,
                                              vehicleVal.postData.vModel
                                                .reference
                                            ):    
                                            planRouteWithVehicles(
                                              temp,
                                              vehicleVal.postData.vModel
                                                .reference
                                            );
                                          }}
                                        >
                                          {selectedVehicle.includes(
                                            vehicleVal.postData.vModel.reference
                                          )
                                            ? 'Plan generated'
                                            : 'Generate Plan'}{' '}
                                        </Button>
                                      </Col>
                                      <Col md="auto">
                                        <Button
                                          className="btn-primary  w-100 text-start"
                                          variant=""
                                          onClick={() => {
                                            let temp: any = [
                                              {
                                                lat: -6.835412502288818,
                                                lng: 39.289344787597656,
                                              },
                                            ];
                                            vehicleVal.orders.map(
                                              (orderVal: any) => {
                                                temp.push({
                                                  lat: orderVal.lat,
                                                  lng: orderVal.lng,
                                                });
                                              }
                                            );
                                            temp.push({
                                              lat: -6.835412502288818,
                                              lng: 39.289344787597656,
                                            });
                                            setCoordinates(temp);
                                            setShowRouteDetails(true);
                                          }}
                                        >
                                          View Route
                                        </Button>
                                      </Col>
                                      <Col md="auto">
                                        <div className="d-flex flex-row">
                                          <div className="d-flex align-items-center">
                                            {vehicleVal.orders.reduce(
                                              (p: any, c: any) => {
                                                return p + c.duration;
                                              },
                                              0
                                            ) >
                                            vehicleVal.vehicleDurationLimit ? (
                                              <span className="badge badge-danger fs-15 fw-600 text-white p-3 border-r4px me-5">
                                                {Method.decimalHoursToHoursMinutesColon(
                                                  vehicleVal.orders.reduce(
                                                    (p: any, c: any) => {
                                                      return p + c.duration;
                                                    },
                                                    0
                                                  )
                                                )}
                                                /{" "}
                                                {Method.decimalHoursToHoursMinutesColon(
                                                  vehicleVal.vehicleDurationLimit
                                                )}
                                              </span>
                                            ) : (
                                              <span className="badge badge-light-warning fs-15 fw-600 text-dark p-3 border-r4px me-5">
                                                {Method.decimalHoursToHoursMinutesColon(
                                                  vehicleVal.orders.reduce(
                                                    (p: any, c: any) => {
                                                      return p + c.duration;
                                                    },
                                                    0
                                                  )
                                                )}
                                                /{" "}
                                                {Method.decimalHoursToHoursMinutesColon(
                                                  vehicleVal.vehicleDurationLimit
                                                )}
                                              </span>
                                            )}
                                          </div>
                                          <div className="d-flex align-items-center">
                                            <span className="badge badge-light-warning fs-15 fw-600 text-dark p-3 border-r4px me-5">
                                              {kgToTons(
                                                vehicleVal.consumedWeight
                                              )}
                                              /{" "}
                                              {kgToTons(
                                                vehicleVal.vehicleCapacity
                                              )}
                                            </span>
                                          </div>
                                          <div className="d-flex align-items-center">
                                            {vehicleVal.isOpen ? (
                                              <Button
                                                variant="link"
                                                className="btn-flush"
                                                onClick={() => {
                                                  handleShow(vehicleIndex);
                                                }}
                                              >
                                                <img
                                                  src={IMAGES.ArrowUp}
                                                  alt=""
                                                />
                                              </Button>
                                            ) : (
                                              <Button
                                                variant="link"
                                                className="btn-flush"
                                                onClick={() => {
                                                  handleShow(vehicleIndex);
                                                }}
                                              >
                                                <img
                                                  src={IMAGES.ArrowDown}
                                                  alt=""
                                                />
                                              </Button>
                                            )}
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Card.Header>
                                  {vehicleVal.isOpen ? (
                                    <Card.Body className="p-0">
                                      <div className="table-responsive">
                                        <table className="table table-rounded table-row-bordered align-middle gs-9 gy-4 mb-0">
                                          <thead>
                                            <tr className="fs-16 fw-600 h-60px align-middle">
                                              <th className="w-md-150px ">
                                                Order No
                                              </th>
                                              <th className="min-w-md-475px">
                                                Shop Name/Address
                                              </th>
                                              <th className="min-w-md-150px">
                                                Duration
                                              </th>
                                              <th className="w-md-200px">
                                                Distance
                                              </th>
                                              <th className="min-w-md-100px text-end">
                                                Weight
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {vehicleVal.orders.map(
                                              (val: any) => {
                                                return (
                                                  <tr>
                                                    <td>
                                                      {val.partialDelivery ? (
                                                        <div className="d-flex align-items-start">
                                                          <div className="d-flex flex-row">
                                                            {val.partialDelivery ? (
                                                              <>
                                                                <img
                                                                  className="me-2"
                                                                  width={28}
                                                                  height={21}
                                                                  src={
                                                                    IMAGES.PartialTruckTwo
                                                                  }
                                                                  alt=""
                                                                />
                                                              </>
                                                            ) : (
                                                              <></>
                                                            )}
                                                          </div>
                                                          <span className=" fs-15 fw-500">
                                                            #{val.refKey}
                                                          </span>
                                                        </div>
                                                      ) : (
                                                        <>
                                                          <span className=" fs-15 fw-500">
                                                            #{val.refKey}
                                                          </span>
                                                        </>
                                                      )}
                                                    </td>{" "}
                                                    <td>
                                                      <span className="fs-15 fw-500">
                                                        {`${
                                                          val.address.name
                                                        }  |  ${
                                                          val.address
                                                            .addressLine1
                                                        } , ${
                                                          val.address.landmark
                                                            ? val.address
                                                                .landmark
                                                            : ""
                                                        } | ${
                                                          val.address
                                                            .phoneCountry
                                                        } ${val.address.phone}`}
                                                      </span>
                                                    </td>
                                                    <td>
                                                      <span className="fs-15 fw-500">
                                                        {Method.decimalHoursToHoursMinutes(
                                                          val.duration
                                                        )}
                                                      </span>
                                                    </td>
                                                    <td>
                                                      <span className="fs-15 fw-500">
                                                        {Method.metersToKilometersMeters(
                                                          val.distance
                                                        )}
                                                      </span>
                                                    </td>
                                                    <td className="text-end">
                                                      <span className="fs-15 fw-500">
                                                        {kgToTons(val.weight)}
                                                      </span>
                                                    </td>
                                                  </tr>
                                                );
                                              }
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </Card.Body>
                                  ) : (
                                    <></>
                                  )}
                                </Card>
                              </Col>
                            );
                          }
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </Row>
            ) : (
              <div className="d-flex justify-content-center">
                <Loader loading={fetchLoader} />
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};
export default DeliveryRoutesPlanDetails;
