import clsx from 'clsx';
import { useState } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import Method from '../../utils/methods';
import { CustomSelectWhite } from '../custom/Select/CustomSelectWhite';
import { customerCategory } from '../../api/apiEndPoints';
import APICallService from '../../api/apiCallService';
import { customerJSON } from '../../api/apiJSON/customer';
import { success } from '../../Global/toast';
import { roundValueJSON } from '../../utils/staticJSON';
const AddCustomerCategoryModal = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [categoryData, setCategoryData] = useState<any>({
    title: '',
    roundValue: '',
    discount1: '',
    discount2: '',
  });
  console.log('categoryData', categoryData);
  const [validation, setValidation] = useState<any>({
    title: false,
    roundValue: false,
    discount1: false,
    discount2: false,
  });
  const handleChange = (value: any, name: any) => {
    const temp = { ...categoryData };
    const tempValidation: any = { ...validation };
    temp[name] = value;
    if (name === 'discount1' || name === 'discount2') {
      if (isNaN(value)) value = '0'; // Handle empty or invalid input
      if (value < 0) value = '0'; // Restrict below 0
      if (value > 100) value = '100'; // Restrict above 100
      temp[name] = value;
      tempValidation['discount1'] = false;
      tempValidation['discount2'] = false;
    } else {
      tempValidation[name] = value.trim().length === 0;
    }
    setValidation(tempValidation);
    setCategoryData(temp);
  };
  const handleAddCategory = async () => {
    const tempValidation = { ...validation };
    tempValidation.title = categoryData.title.trim().length === 0;
    tempValidation.roundValue = categoryData.roundValue.trim().length === 0;
    tempValidation.discount1 =
      categoryData.discount1.trim().length === 0 &&
      categoryData.discount2.trim().length === 0;
    tempValidation.discount2 =
      categoryData.discount1.trim().length === 0 &&
      categoryData.discount2.trim().length === 0;
    setValidation(tempValidation);
    if (Object.values(tempValidation).includes(true)) {
      return;
    }
    setIsSubmitting(true);
    const apiService = new APICallService(
      customerCategory.addCustomerCategory,
      customerJSON.addCustomerCategory(categoryData),
      '',
      '',
      '',
      '',
      ''
      //  tempWarehouse?._id
    );
    const response = await apiService.callAPI();
    if (response) {
      success('Customer Category created successfully');
      props.handleFetchCategory();
    }
    setIsSubmitting(false);
  };
  const isDiscount1Disabled = categoryData.discount2.trim().length > 0;
  const isDiscount2Disabled = categoryData.discount1.trim().length > 0;
  return (
    <Modal
      {...props}
      show={props.show}
      onHide={props.handleClose}
      centered
      size="md"
    >
      <Modal.Body>
        <div className="fs-26 fw-bolder mb-4">Add Customer Category</div>
        <Form.Group className="mb-4">
          <Form.Label className="fs-14 fw-500">Customer Category</Form.Label>
          <Form.Control
            className={clsx('form-control-custom bg-white', {
              'border-danger': validation.title,
            })}
            type="text"
            placeholder="Type here…"
            name="name"
            value={categoryData.title}
            onChange={(e: any) => handleChange(e.target.value, 'title')}
          />
        </Form.Group>
        <Row className="mb-4">
          <Col sm={6}>
            <Form.Group>
              <Form.Label className="fs-14 fw-500">
                Product Price (+)
              </Form.Label>
              <Form.Control
                className={clsx('form-control-custom bg-white', {
                  'border-danger': validation.discount1,
                })}
                type="number"
                placeholder="Type here…"
                name="discount1"
                value={categoryData.discount1}
                onChange={(e: any) => handleChange(e.target.value, 'discount1')}
                disabled={isDiscount1Disabled}
              />
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group>
              <Form.Label className="fs-14 fw-500">
                Product Price (-)
              </Form.Label>
              <Form.Control
                className={clsx('form-control-custom bg-white', {
                  'border-danger': validation.discount2,
                })}
                type="number"
                placeholder="Type here…"
                name="discount2"
                value={categoryData.discount2}
                onChange={(e: any) => handleChange(e.target.value, 'discount2')}
                disabled={isDiscount2Disabled}
              />
            </Form.Group>
          </Col>
        </Row>
        <Form.Group className="mb-4">
          <Form.Label className="fs-14 fw-500">Rounded Value</Form.Label>
          <CustomSelectWhite
            border={clsx(validation.roundValue ? '#e55451' : '')}
            options={roundValueJSON}
            onChange={(e: any) => handleChange(e.title, 'roundValue')}
            // value={categoryData.roundValue}
          />
          {/* <Form.Control
            className={clsx('form-control-custom bg-white', {
              'border-danger': validation.roundValue,
            })}
            type="number"
            placeholder="Type here…"
            name="roundValue"
            value={categoryData.roundValue}
            onChange={(e: any) => handleChange(e.target.value, 'roundValue')}
          /> */}
        </Form.Group>
        <div className="d-flex gap-3">
          <Button
            type="submit"
            className="btn btn-primary"
            disabled={isSubmitting}
            onClick={handleAddCategory}
          >
            {!isSubmitting && (
              <span className="indicator-label">Save details</span>
            )}
            {isSubmitting && (
              <span
                className="indicator-progress"
                style={{ display: 'block' }}
              >
                Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </Button>
          <button
            onClick={props.handleClose}
            className="btn btn-danger fw-bolder"
          >
            Cancel
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default AddCustomerCategoryModal;
