import { Button, Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import NewRequest from './tabs/new-request';
import Upcoming from './tabs/upcoming';
import Ongoing from './tabs/ongoing';
import Completed from './tabs/completed';
import { useEffect, useState } from 'react';
import {
  Add,
  AdvertisementCompleted,
  AdvertisementOngoing,
  AdvertisementRequest,
  AdvertisementUpcoming,
  Advertising,
  FinanceManager,
  PAGE_LIMIT,
} from '../../../utils/constants';
import { useAuth } from '../auth';
import APICallService from '../../../api/apiCallService';
import { advertisements } from '../../../api/apiEndPoints';
import { advertisementJSON } from '../../../api/apiJSON/advertisement';
import { IGetAdvertisement } from '../../../types/responseIndex';
import { listAdvertisement } from '../../../utils/storeString';
import { getKey, setKey } from '../../../Global/history';
import { ElementAnimateUtil } from '../../../_admin/assets/ts/_utils';
import Loader from '../../../Global/loader';
import { KTSVG } from '../../../_admin/helpers';
import Method from '../../../utils/methods';
import { warehouse as warehouseStoreString } from '../../../utils/storeString';
const ViewAdvertisement = () => {
  let tempWarehouse: any = getKey(warehouseStoreString.warehouseRef);
  tempWarehouse = JSON.parse(tempWarehouse);
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState('0');
  const [fetchLoading, setFetchLoading] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const [advertisement, setAdvertisement] = useState<IGetAdvertisement | any>(
    []
  );
  const [search, setSearch] = useState(getKey(listAdvertisement.search)?.toString() || '');
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState<any>(getKey(listAdvertisement.page) || 1);
  const [pageLimit, setPageLimit] = useState<any>(
    getKey(listAdvertisement.limit) || PAGE_LIMIT
  );
  const [tab, setTab] = useState(
    currentUser?.role.some((obj: any) => obj.type === FinanceManager)
      ? [
          {
            name: 'Ongoing',
            content: (
              <Ongoing
                advertisement={advertisement}
                fetchLoading={true}
                loading={loading}
                search={search}
                moduleName = {Advertising}
              />
            ),
            value: AdvertisementOngoing,
          },
          {
            name: 'Completed',
            content: (
              <Completed
                advertisement={advertisement}
                fetchLoading={true}
                loading={loading}
                search={search}
                moduleName = {Advertising}
              />
            ),
            value: AdvertisementCompleted,
          },
        ]
      : [
          {
            name: 'New request',
            content: (
              <NewRequest
                advertisement={advertisement}
                fetchLoading={true}
                loading={loading}
                search={search}
                moduleName = {Advertising}
              />
            ),
            value: AdvertisementRequest,
          },
          {
            name: 'Upcoming',
            content: (
              <Upcoming
                advertisement={advertisement}
                fetchLoading={true}
                loading={loading}
                search={search}
                moduleName = {Advertising}
              />
            ),
            value: AdvertisementUpcoming,
          },
          {
            name: 'Ongoing',
            content: (
              <Ongoing
                advertisement={advertisement}
                fetchLoading={true}
                loading={loading}
                search={search}
                moduleName = {Advertising}
              />
            ),
            value: AdvertisementOngoing,
          },
          {
            name: 'Completed',
            content: (
              <Completed
                advertisement={advertisement}
                fetchLoading={true}
                loading={loading}
                search={search}
                moduleName = {Advertising}
              />
            ),
            value: AdvertisementCompleted,
          },
        ]
  );
  const [previousTab, setPreviousTab] = useState<any>({
    name: 'New request',
    content: <NewRequest advertisement={undefined} fetchLoading={true}  moduleName = {Advertising}/>,
    value: AdvertisementRequest,
  });
  const [currentTab, setCurrentTab] = useState<any>(
    currentUser?.role.some((obj: any) => obj.type === FinanceManager)
      ? () => {
          let tabTemp = getKey(listAdvertisement.currentTab)
            ? getKey(listAdvertisement.currentTab)
            : AdvertisementOngoing;
          let currentTabTemp = {};
          switch (tabTemp) {
            case AdvertisementRequest:
              currentTabTemp = {
                name: 'New request',
                content: (
                  <NewRequest
                    advertisement={advertisement}
                    fetchLoading={true}
                    loading={loading}
                    search={search}
                    moduleName = {Advertising}
                  />
                ),
                value: AdvertisementRequest,
              };
              break;
            case AdvertisementUpcoming:
              currentTabTemp = {
                name: 'Upcoming',
                content: (
                  <Upcoming
                    advertisement={advertisement}
                    fetchLoading={true}
                    loading={loading}
                    search={search}
                    moduleName = {Advertising}
                  />
                ),
                value: AdvertisementUpcoming,
              };
              break;
            case AdvertisementOngoing:
              currentTabTemp = {
                name: 'Ongoing',
                content: (
                  <Ongoing
                    advertisement={advertisement}
                    fetchLoading={true}
                    loading={loading}
                    search={search}
                    moduleName = {Advertising}
                  />
                ),
                value: AdvertisementOngoing,
              };
              break;
            case AdvertisementCompleted:
              currentTabTemp = {
                name: 'Completed',
                content: (
                  <Completed
                    advertisement={advertisement}
                    fetchLoading={true}
                    loading={loading}
                    search={search}
                    moduleName = {Advertising}
                  />
                ),
                value: AdvertisementCompleted,
              };
              break;
            default:
          }
          return currentTabTemp;
        }
      : () => {
          let tabTemp: any = getKey(listAdvertisement.currentTab)
            ? getKey(listAdvertisement.currentTab)
            : AdvertisementRequest;
          let currentTabTemp = {};
          switch (parseInt(tabTemp)) {
            case AdvertisementRequest:
              currentTabTemp = {
                name: 'New request',
                content: (
                  <NewRequest
                    advertisement={advertisement}
                    fetchLoading={true}
                    loading={loading}
                    search={search}
                    moduleName = {Advertising}
                  />
                ),
                value: AdvertisementRequest,
              };
              break;
            case AdvertisementUpcoming:
              currentTabTemp = {
                name: 'Upcoming',
                content: (
                  <Upcoming
                    advertisement={advertisement}
                    fetchLoading={true}
                    loading={loading}
                    search={search}
                    moduleName = {Advertising}
                  />
                ),
                value: AdvertisementUpcoming,
              };
              break;
            case AdvertisementOngoing:
              currentTabTemp = {
                name: 'Ongoing',
                content: (
                  <Ongoing
                    advertisement={advertisement}
                    fetchLoading={true}
                    loading={loading}
                    search={search}
                    moduleName = {Advertising}
                  />
                ),
                value: AdvertisementOngoing,
              };
              break;
            case AdvertisementCompleted:
              currentTabTemp = {
                name: 'Completed',
                content: (
                  <Completed
                    advertisement={advertisement}
                    fetchLoading={true}
                    loading={loading}
                    search={search}
                    moduleName = {Advertising}
                  />
                ),
                value: AdvertisementCompleted,
              };
              break;
            default:
          }
          return currentTabTemp;
        }
  );

  useEffect(() => {
    (async () => {
      if (!Method.hasModulePermission(Advertising, currentUser)) {
        return window.history.back();
      }
      console.log('tempWarehouse?._id', tempWarehouse?._id)
      setFetchLoading(true);
      await fetchAdvertisementData(page, pageLimit, currentTab.value, search , tempWarehouse?._id || null);
      setDataFetched(true);
      setFetchLoading(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
    if (dataFetched) {
      let initialScrollPosition: any = getKey(listAdvertisement.scrollPosition);
      if (initialScrollPosition) {
            ElementAnimateUtil.scrollTo(parseInt(initialScrollPosition));
            setDataFetched(false) 
      }
      setKey(listAdvertisement.search, '', false); 
    }
    })();
  }, [dataFetched]);

  useEffect(() => {
    let tabTemp = [...tab];
    if (previousTab) {
      if (previousTab.value !== currentTab.value) {
        var itemIndex = tabTemp.findIndex((x) => x.value == previousTab.value);
        switch (previousTab.value) {
          case AdvertisementRequest:
            tabTemp[itemIndex] = {
              ...tabTemp[itemIndex],
              name: 'New request',
            };
            break;
          case AdvertisementUpcoming:
            tabTemp[itemIndex] = {
              ...tabTemp[itemIndex],
              name: 'Upcoming',
            };
            break;
          case AdvertisementOngoing:
            tabTemp[itemIndex] = {
              ...tabTemp[itemIndex],
              name: 'Ongoing',
            };
            break;
          case AdvertisementCompleted:
            tabTemp[itemIndex] = {
              ...tabTemp[itemIndex],
              name: 'Completed',
            };
            break;
          default:
        }
      }
    }
    setTab(tabTemp);
  }, [currentTab]);
  const fetchAdvertisementData = async (
    pageNo: number,
    limit: number,
    listType: number,
    search?: string,
    warehouse?: any
  ) => {
    let params:any = {
      pageNo: pageNo,
      limit: limit,
      sortKey: 'name',
      sortOrder: 1,
      searchTerm: search ? search : '',
      state: listType,
    };
    if (warehouse) {
      params = {
        ...params,
        warehouse: warehouse,
      };
    }
    console.log('warehouse', warehouse)
    console.log('params', params)
    let apiService = new APICallService(
      advertisements.listAdvertisement,
      advertisementJSON.listAdvertisement(params),
      '','','','',Advertising,tempWarehouse?._id || null
    );
    let response = await apiService.callAPI();
    if(response.records){
    setTotalRecords(response.total);
    setNavTab(listType, response.records, response.total, pageNo, limit, false, search);
    setAdvertisement(response.records);
    }
  };
  const createTabs = () => {
    const localTab = tab;
    let current = currentTab;
    const allTabs = localTab.map((tab) => {
      return (
        <li className='nav-item'>
          <a
            href={'/'}
            className={
              current.value === tab.value
                ? 'nav-link active fs-16 fw-600 pb-5 w-lg-140px text-active-dark text-center'
                : 'nav-link fs-16 fw-600 pb-5 w-lg-140px text-hover-dark text-active-dark text-center'
            }
            data-bs-toggle='tab'
            onClick={() => handleSelectTab(tab)}
          >
            {tab.name}
          </a>
        </li>
      );
    });
    return allTabs;
  };
  const handleSelectTab = async (tab: any) => {
    if (!fetchLoading) {
      setPage(1);
      setAdvertisement(undefined);
      setTotalRecords(0);
      setKey(listAdvertisement.currentTab, parseInt(tab.value));
      setKey(listAdvertisement.page, 1);
      await setPreviousTab(JSON.parse(JSON.stringify(currentTab)));
      await setNavTab(tab.value, 0, 0, 1, pageLimit, true,search);
      await fetchAdvertisementData(1, pageLimit, tab.value, search, tempWarehouse?._id);
    }
  };
  const setNavTab = (
    value: any,
    data: any,
    total: any,
    pageNo: any,
    limit: any,
    fetchLoad: boolean,
    search: any,
  ) => {
    let tabTemp = [...tab];
    let currentTab = {};
    switch (value) {
      case AdvertisementRequest:
        currentTab = {
          name: 'New request (' + total + ')',
          content: (
            <NewRequest
              advertisement={data}
              fetchLoading={fetchLoad}
              search={search}
              total={total}
              currentPage={pageNo}
              handleCurrentPage={handleCurrentPage}
              handleNextPage={handleNextPage}
              handlePreviousPage={handlePreviousPage}
              pageLimit={limit}
              handlePageLimit={handlePageLimit}
              moduleName = {Advertising}
            />
          ),
          value: AdvertisementRequest,
        };
        var itemIndex = tabTemp.findIndex((x) => x.value == value);
        tabTemp[itemIndex] = {
          ...tabTemp[itemIndex],
          name: 'New request (' + total + ')',
        };
        break;
      case AdvertisementUpcoming:
        currentTab = {
          name: 'Upcoming (' + total + ')',
          content: (
            <Upcoming
              advertisement={data}
              fetchLoading={fetchLoad}
              loading={loading}
              search={search}
              currentPage={pageNo}
              handleCurrentPage={handleCurrentPage}
              handleNextPage={handleNextPage}
              handlePreviousPage={handlePreviousPage}
              pageLimit={limit}
              handlePageLimit={handlePageLimit}
              handleSearch={handleSearch}
              total={total}
              moduleName = {Advertising}
            />
          ),
          value: AdvertisementUpcoming,
        };
        var itemIndex = tabTemp.findIndex((x) => x.value == value);
        tabTemp[itemIndex] = {
          ...tabTemp[itemIndex],
          name: 'Upcoming (' + total + ')',
        };
        break;
      case AdvertisementOngoing:
        currentTab = {
          name: 'Ongoing (' + total + ')',
          content: (
            <Ongoing
              advertisement={data}
              fetchLoading={fetchLoad}
              loading={loading}
              search={search}
              currentPage={pageNo}
              handleCurrentPage={handleCurrentPage}
              handleNextPage={handleNextPage}
              handlePreviousPage={handlePreviousPage}
              pageLimit={limit}
              handlePageLimit={handlePageLimit}
              handleSearch={handleSearch}
              total={total}
              moduleName = {Advertising}
            />
          ),
          value: AdvertisementOngoing,
        };
        var itemIndex = tabTemp.findIndex((x) => x.value == value);
        tabTemp[itemIndex] = {
          ...tabTemp[itemIndex],
          name: 'Ongoing (' + total + ')',
        };
        break;
      case AdvertisementCompleted:
        currentTab = {
          name: 'Completed (' + total + ')',
          content: (
            <Completed
              advertisement={data}
              fetchLoading={fetchLoad}
              loading={loading}
              search={search}
              currentPage={pageNo}
              handleCurrentPage={handleCurrentPage}
              handleNextPage={handleNextPage}
              handlePreviousPage={handlePreviousPage}
              pageLimit={limit}
              handlePageLimit={handlePageLimit}
              handleSearch={handleSearch}
              total={total}
              moduleName = {Advertising}
            />
          ),
          value: AdvertisementCompleted,
        };
        var itemIndex = tabTemp.findIndex((x) => x.value == value);
        tabTemp[itemIndex] = {
          ...tabTemp[itemIndex],
          name: 'Completed (' + total + ')',
        };
        break;
      default:
    }
    setCurrentTab(currentTab);
    setTab(tabTemp);
  };
  const handleCurrentPage = async (val: number, currentTabValue: number) => {
    setPage(val);
    setKey(listAdvertisement.page, val);
    await fetchAdvertisementData(val, pageLimit, currentTabValue, search,tempWarehouse?._id|| null);
  };
  const handleNextPage = async (val: number, currentTabValue: number) => {
    setPage(val + 1);
    setKey(listAdvertisement.page, val + 1);
    await fetchAdvertisementData(val + 1, pageLimit, currentTabValue, search,tempWarehouse?._id||null);
  };
  const handlePreviousPage = async (val: number, currentTabValue: number) => {
    setPage(val - 1);
    setKey(listAdvertisement.page, val - 1);
    await fetchAdvertisementData(val - 1, pageLimit, currentTabValue, search,tempWarehouse?._id || null);
  };
  const handlePageLimit = async (event: any, currentTabValue: number) => {
    setPage(1);
    await setPageLimit(parseInt(event.target.value));
    setKey(listAdvertisement.page, 1);
    setKey(listAdvertisement.limit, parseInt(event.target.value));
    await fetchAdvertisementData(
      1,
      event.target.value,
      currentTabValue,
      search,
      tempWarehouse?._id || null
    );
  };
  const handleSearch = async (event: any, currentTabValue: number) => {
    setSearch(event.target.value);
    await fetchAdvertisementData(
      1,
      pageLimit,
      currentTabValue,
      event.target.value,
      tempWarehouse?._id || null
    );
  };
  return (
    <div className='p-9'>
      <Row className='align-items-center'>
        <Col sm>
          <h1 className='fs-22 fw-bolder mb-md-0'>Advertising</h1>
        </Col>
        <Col sm='auto'>
        {Method.hasPermission(Advertising, Add, currentUser) ? (
          <Link to='/advertisement/add-new-advertisement'>
            <Button variant='primary' className='min-h-50px'
            onClick={
              () => {
                setKey(listAdvertisement.search, search, false);
              }
            }>
              Add new advertisement
            </Button>
          </Link>
        ) : (
          <></>
        )}
        </Col>
      </Row>
      <Card className='border border-r10px mt-7'>
        <Card.Body className='p-9'>
          <Row>
          {fetchLoading ? (
            <div className='w-100 d-flex justify-content-center'>
              <Loader loading={fetchLoading} />
            </div>
            ) : (
              <>
            <Col>
              <ul className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6'>
                {createTabs()}
              </ul>
              {advertisement && advertisement.length ? (
              <>
                <div className='tab-content' id='myTabContent'>
                  <div className="position-relative my-1">
                    <KTSVG
                      path="/media/icons/duotune/general/gen021.svg"
                      className="svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ps-13"
                    />
                    <input
                      type="text"
                      className="form-control form-control-custom borde-r8px bg-light w-375px ps-11"
                      name="Search Team"
                      value={search}
                      onChange={(event: any) => {
                        handleSearch(event, currentTab.value);
                      }}
                      placeholder="Search by seller name…"
                    />
                  </div>
                </div>
              </>
              ): search.length ? (
              <>
                <div className='tab-content' id='myTabContent'>
                  <div className="position-relative my-1">
                    <KTSVG
                      path="/media/icons/duotune/general/gen021.svg"
                      className="svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ps-13"
                    />
                    <input
                      type="text"
                      className="form-control form-control-custom borde-r8px bg-light w-375px ps-11"
                      name="Search Team"
                      value={search}
                      onChange={(event: any) => {
                        handleSearch(event, currentTab.value);
                      }}
                      placeholder="Search by seller name…"
                    />
                  </div>
                </div>
              </>
              ): (
              <></>
              )}
                {/* <div
                  className='tab-pane fade show active'
                  id='kt_tab_pane_1'
                  role='tabpanel'
                >
                  <NewRequest />
                </div>
                <div
                  className='tab-pane fade'
                  id='kt_tab_pane_2'
                  role='tabpanel'
                >
                  <Upcoming />
                </div>
                <div
                  className='tab-pane fade'
                  id='kt_tab_pane_3'
                  role='tabpanel'
                >
                  <Ongoing />
                </div>
                <div
                  className='tab-pane fade'
                  id='kt_tab_pane_4'
                  role='tabpanel'
                >
                  <Completed />
                </div> */}
              <div className='tab-pane fade show active'>
                  <>{currentTab.content}</>
              </div>
            </Col>
            </>
          )}
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
};
export default ViewAdvertisement;
