import {
  IAddAdvertisement,
  IListAdvertisement,
  IPayAdvertisement,
} from '../../types';
export const advertisementJSON = {
  addAdvertisement: ({
    placement,
    amount,
    description,
    endDate,
    productType,
    startDate,
    totalAmount,
    imageReader,
    variants,
  }: IAddAdvertisement) => {
    let form = new FormData();
    form.append('placement', placement);
    form.append('amount', amount);
    form.append('description', description);
    form.append('endDate', endDate);
    form.append('productType', productType);
    form.append('startDate', startDate);
    form.append('totalAmount', totalAmount);
    form.append('image', imageReader);
    if (variants.length) {
      variants.map((variantVal: any, index: number) => {
        form.append('variants[' + index + '][reference]', variantVal);
      });
    }
    return form;
  },
  listAdvertisement: ({
    limit,
    sortKey,
    sortOrder,
    state,
    pageNo,
    searchTerm,
    fromDate,
    toDate,
    warehouse
  }: IListAdvertisement) => {
    let params :any = {
      limit: limit,
      sortKey: sortKey,
      sortOrder: sortOrder,
      state: state,
      needCount: true,
      pageNo: pageNo,
      searchTerm: searchTerm,
      fromDate: fromDate ? fromDate : '',
      toDate: toDate ? toDate : '',
    };
    if(warehouse){
      params.warehouse = warehouse
    }
    return params;
  },
  payAdvertisement: ({ paymentMethod }: IPayAdvertisement) => {
    return { paymentMethod: paymentMethod };
  },
};
