import { Card, Col, Row } from "react-bootstrap";
import "./InterRequestTimlineCss.css";
import {
  ShipmentAccepted,
  ShipmentCancelled,
  ShipmentCompleted,
  ShipmentPickedUp,
  ShipmentRejected,
  ShipmentDelivered,
  ShipmentSubmitted,
  TransferRequestDelivered,
} from "../../../../utils/constants";
import { useEffect } from "react";
import clsx from "clsx";
const TimelineForInterRequests = (props: any) => {
    const TransferRequestPickupStarted = 3.5;
  useEffect(() => {
    if (props.isChanged) {
      if (props.statusesLogs) {
      }
    }
  }, [props.isChanged]);
  const getLineWidth = (state: any) => {
    if (state === ShipmentAccepted) {
      return "37%";
    }else if (state === TransferRequestPickupStarted) {
      return "60%";
    } else if (state === ShipmentPickedUp ) {
      return "75%";
    }else if(state == TransferRequestDelivered){
      return "85%";
    } else if (
      state === ShipmentCompleted ||
      state === ShipmentCancelled ||
      state === ShipmentRejected
    ) {
      return "100%";
    } else {
      return "0%";
    }
  };
  // const getStatusDate = (statusIndex: number) => {
  //   const log = props.statusesLogs[statusIndex];
  //   if (log && log.statusUpdatedAt) {
  //     const date = new Date(log.statusUpdatedAt);
  //     return date.toDateString();
  //   }
  //   return '';
  // };
  const getStatusDate = (status: number) => {
    const log = props?.statusesLogs.find((log: any) => log.status === status);
    if (log && log.statusUpdatedAt) {
      const date = new Date(log.statusUpdatedAt);
      return date.toDateString();
    }
    return "";
  };
  const getStatusClass = (
    statusIndex: number,
    currentIndex: number,
  ) => {
    if (statusIndex <= currentIndex) {
      return "current";
    } else {
      return "";
    }
  };
  const isStatusInLogs = (status: number) => {
    return props.statusesLogs.some((log: any) => log.status === status);
  };
  return (
    <>
      <Card className="bg-light border overflow-x-scroll">
        <Card.Body className="py-0">
          <Row className="justify-content-center py-5 order-timeline-box">
            <Col lg={11}>
              <div
                className={clsx(
                  "horizontal timeline min-h-100px pt-3",
                  props.status === ShipmentCancelled ||
                    props.status === ShipmentRejected
                    ? "cancelled"
                    : ""
                )}
              >
                <div
                  className={`steps ${
                    props.status === ShipmentCancelled ||
                    props.status === ShipmentRejected
                      ? "cancelled"
                      : ""
                  } `}
                >
                  <div
                    className={`step ${getStatusClass(
                      ShipmentSubmitted,
                      props.status
                    )}`}
                  >
                    <span className="timeline-text">Request sent</span>
                    <span className="order-date">
                      {getStatusDate(ShipmentSubmitted)}
                    </span>
                  </div>
                  {props.status === ShipmentCancelled ||
                  props.status === ShipmentRejected ? (
                    <>
                      {isStatusInLogs(ShipmentAccepted) && (
                        <div
                          className={`step ${getStatusClass(
                            ShipmentAccepted,
                            props.status
                          )}`}
                        >
                          <span className="timeline-text">Accepted</span>
                          <span className="order-date">
                            {getStatusDate(ShipmentAccepted)}
                          </span>
                        </div>
                      )}
                      {isStatusInLogs(TransferRequestPickupStarted) && (
                        <div
                          className={`step ${getStatusClass(
                            TransferRequestPickupStarted,
                            props.status
                          )}`}
                        >
                          <span className="timeline-text">Picked Up</span>
                          <span className="order-date">
                            {getStatusDate(TransferRequestPickupStarted)}
                          </span>
                        </div>
                      )}
                      {isStatusInLogs(
                        props.isGoodsDelivered
                          ? ShipmentDelivered
                          : ShipmentPickedUp
                      ) && (
                        <div
                          className={`step ${getStatusClass(
                            props.isGoodsDelivered
                              ? ShipmentDelivered
                              : ShipmentPickedUp,
                            props.status
                          )}`}
                        >
                          <span className="timeline-text">
                            {props.isGoodsDelivered
                              ? "Goods Delivered"
                              : "Out for delivery 2"}
                          </span>
                          <span className="order-date">
                            {getStatusDate(
                              props.isGoodsDelivered
                                ? ShipmentDelivered
                                : ShipmentPickedUp
                            )}
                          </span>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <div
                        className={`step ${getStatusClass(
                          ShipmentAccepted,
                          props.status
                        )}`}
                      >
                        <span className="timeline-text">Accepted</span>
                        <span className="order-date">
                          {getStatusDate(ShipmentAccepted)}
                        </span>
                      </div>
                      <div
                        className={`step ${getStatusClass(
                          TransferRequestPickupStarted,
                          props.status
                        )}`}
                      >
                        <span className="timeline-text">Picked Up </span>
                        <span className="order-date">
                          {getStatusDate(TransferRequestPickupStarted)}
                        </span>
                      </div>
                      <div
                        className={`step ${getStatusClass(
                          props.isGoodsDelivered
                            ? ShipmentDelivered
                            : ShipmentPickedUp,
                          props.status
                        )}`}
                      >
                        <span className="timeline-text">
                          {props.isGoodsDelivered
                            ? "Goods Delivered"
                            : "Out for delivery "}
                        </span>
                        <span className="order-date">
                          {getStatusDate(
                            props.isGoodsDelivered
                              ? ShipmentDelivered
                              : ShipmentPickedUp
                          )}
                        </span>
                      </div>
                    </>
                  )}

                  {props.status === ShipmentCancelled ||
                  props.status === ShipmentRejected ? (
                    <></>
                  ) : (
                    <div
                      className="line"
                      style={{
                        width: getLineWidth(props.status),
                        transition: "5s ease-out",
                      }}
                    ></div>
                  )}
                  {props.status === ShipmentCancelled ||
                  props.status === ShipmentRejected ? (
                    <>
                      {isStatusInLogs(ShipmentRejected) && (
                        <div
                          className={`step ${getStatusClass(
                            ShipmentRejected,
                            props.status
                          )}`}
                        >
                          <span className="timeline-text">Cancelled</span>
                          <span className="order-date">
                            {getStatusDate(ShipmentRejected)}
                          </span>
                        </div>
                      )}
                      {isStatusInLogs(ShipmentCancelled) && (
                        <div
                          className={`step ${getStatusClass(
                            ShipmentCancelled,
                            props.status
                          )}`}
                        >
                          <span className="timeline-text">Cancelled</span>
                          <span className="order-date">
                            {getStatusDate(ShipmentCancelled)}
                          </span>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <div
                        className={`step ${getStatusClass(
                          ShipmentCompleted,
                          props.status,
                        )}`}
                      >
                        <span className="timeline-text">Goods received</span>
                        <span className="order-date">
                          {getStatusDate(ShipmentCompleted)}
                        </span>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};
export default TimelineForInterRequests;
