import { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Nav,
  Row,
  Tab,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import green from '../../../../../_admin/assets/media/svg_uTrade/green_dot.svg';
import red from '../../../../../_admin/assets/media/svg_uTrade/red_dot.svg';
import yellow from '../../../../../_admin/assets/media/svg_uTrade/yellow_dot.svg';
import gray from '../../../../../_admin/assets/media/svg_uTrade/gray_dot.svg';
import { KTSVG } from '../../../../../_admin/helpers';
import { IMAGES } from '../../../../../utils/dummyJSON';
import { CustomSelect } from '../../../../custom/Select/CustomSelect';
import { CustomSelectTable2 } from '../../../../custom/Select/custom-select-table';
import BatchExpiery from '../../../../modals/batch-expiry';
import GoodsNote from '../../../../modals/goods-note';
import { CustomComponentSelect } from '../../../../custom/Select/CustomComponentSelect';
import APICallService from '../../../../../api/apiCallService';
import { inventory, master, seller } from '../../../../../api/apiEndPoints';
import { CustomSelectWhite } from '../../../../custom/Select/CustomSelectWhite';
import errorWarning from '../../../../../_admin/assets/media/svg_uTrade/error-warning.svg';
import {
  Actived,
  Add,
  All_Products,
  AllProduct,
  AllSeller,
  CartonWithDozens,
  CartonWithPieces,
  Deactivated,
  Dozen,
  Edit,
  GoodsWarehouse,
  PAGE_LIMIT,
  Piece,
  ProductRejectedState,
  VerificationPending,
  View,
} from '../../../../../utils/constants';
import {
  expiryJSON,
  expiryMonthsJSON,
  productStatusJSON,
} from '../../../../../utils/staticJSON';
import { inventoryJSON } from '../../../../../api/apiJSON/inventory';
import clsx from 'clsx';
import Method from '../../../../../utils/methods';
import Loader from '../../../../../Global/loader';
import Pagination from '../../../../../Global/pagination';
import { getKey, setKey } from '../../../../../Global/history';
import {
  listSellerInventory,
  warehouse as warehouseStoreString,
} from '../../../../../utils/storeString';
import PermissionModal from '../../../../modals/permission';
import { useAuth } from '../../../auth';
const SellerInventory = (props: any) => {
  let tempWarehouse: any = getKey(warehouseStoreString.warehouseRef);
  tempWarehouse = JSON.parse(tempWarehouse);
  const { currentUser } = useAuth();
  const [filterCategories, setFilterCategories] = useState([]);
  const [search, setSearch] = useState<string>(
    getKey(listSellerInventory.search)?.toString() || ''
  );
  const [expiry, setExpiry] = useState<any>(
    getKey(listSellerInventory.filterExpiry) || ''
  );
  const [tabKey, setTabKey] = useState<any>(1);
  const navigate = useNavigate();
  const [expieryModal, setExpieryModal] = useState(false);
  const [goodsNote, setGoodsNote] = useState(false);
  const [fetchLoader, setFetchLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState<any>(
    getKey(listSellerInventory.filterCategories) || []
  );
  const [productState, setProductState] = useState<any>(
    getKey(listSellerInventory.filterStatus) || {}
  );
  const [page, setPage] = useState<any>(getKey(listSellerInventory.page) || 1);
  const [pageLimit, setPageLimit] = useState<any>(
    getKey(listSellerInventory.limit) || PAGE_LIMIT
  );
  const [totalRecords, setTotalRecords] = useState(0);
  const [products, setProducts] = useState<any>();
  const [removedVariant, setRemovedVariant] = useState(false);
  const [showPermissionModal, setShowPermissionModal] = useState(false);
  const hasViewPermission = !Method.hasPermission(
    GoodsWarehouse,
    Add,
    currentUser
  );
  useEffect(() => {
    (async () => {
      setFetchLoader(true);
      await fetchCategory();
      await fetchInventoryProduct(
        page,
        pageLimit,
        expiry,
        productState.value,
        categories,
        removedVariant,
        search
      );
      setFetchLoader(false);
    })();
  }, []);
  useEffect(() => {
    let temp = { ...productState };
    if (temp.value === Actived) {
      temp.label = (
        <>
          <img
            src={green}
            width={12}
            height={12}
            className="me-3"
            alt=""
          />
          <span className="fs-16 fw-600 text-black mb-0">
            Active {totalRecords ? ' (' + totalRecords + ')' : ''}
          </span>
        </>
      );
    }
    if (temp.value === VerificationPending) {
      temp.label = (
        <>
          <img
            src={yellow}
            width={12}
            height={12}
            className="me-3"
            alt=""
          />
          <span className="fs-16 fw-600 text-black mb-0">
            Verification pending
            {totalRecords ? ' (' + totalRecords + ')' : ''}
          </span>
        </>
      );
    }
    if (temp.value === Deactivated) {
      temp.label = (
        <>
          <img
            src={gray}
            width={12}
            height={12}
            className="me-3"
            alt=""
          />
          <span className="fs-16 fw-600 text-black mb-0">
            Deactivated {totalRecords ? ' (' + totalRecords + ')' : ''}
          </span>
        </>
      );
    }
    if (temp.value === ProductRejectedState) {
      temp.label = (
        <>
          <img
            src={red}
            width={12}
            height={12}
            className="me-3"
            alt=""
          />
          <span className="fs-16 fw-600 text-black mb-0">
            Rejected {totalRecords ? ' (' + totalRecords + ')' : ''}
          </span>
        </>
      );
    }
    setProductState(temp);
  }, [totalRecords]);
  const fetchInventoryProduct = async (
    pageNo: number,
    limit: number,
    expiry: string,
    state: string,
    categories?: any,
    viewRemovedOnly?: boolean,
    searchTerm?: any
  ) => {
    let params: any = {
      pageNo: pageNo,
      limit: limit,
      sortKey: 'title',
      sortOrder: -1,
      state: state,
      expiry: expiry == '-3' ? '' : expiry,
      needCount: true,
      searchTerm: searchTerm,
      viewRemovedOnly: viewRemovedOnly ? viewRemovedOnly : false,
    };
    if (categories.length) {
      categories.map((data: any, index: number) => {
        params = { ...params, ['categories[' + index + ']']: data.id };
      });
    }
    if (tempWarehouse?._id) {
      params.warehouse = tempWarehouse?._id;
    }
    let apiService = new APICallService(
      seller.listInventory,
      params,
      {
        id: props.businessId,
      },
      '',
      '',
      '',
      AllSeller,
      tempWarehouse?._id
    );
    let response = await apiService.callAPI();
    if (response.records) {
      if (response.total) {
        setTotalRecords(response.total);
      }
    }
    setProducts(response.records);
  };
  const fetchCategory = async () => {
    const params: any = {
      categoriesDepth: 1,
    };
    if (tempWarehouse?._id) {
      params.warehouse = tempWarehouse?._id;
    }
    let apiService = new APICallService(
      master.categoryList,
      params,
      '',
      '',
      '',
      '',
      AllSeller,
      tempWarehouse?._id
    );
    let response = await apiService.callAPI();
    if (response.records) {
      let temp: any = [];
      response.records.map((val: any) => {
        temp.push({
          value: val.title,
          id: val._id,
          name: val.title,
          title: val.title,
          label: (
            <>
              <div className="symbol symbol-40px symbol-circle border me-2">
                <img
                  src={val.image}
                  className="object-fit-contain"
                  alt=""
                />
              </div>
              <label
                className="form-check-label truncate-2 fs-16 fw-600 text-dark"
                htmlFor="Ex2"
              >
                {val.title}
              </label>
            </>
          ),
        });
      });
      setFilterCategories(temp);
    }
  };
  const handleCategoryFilter = async (event: any) => {
    setCategories(event);
    setKey(listSellerInventory.filterCategories, event, false);
    await fetchInventoryProduct(
      1,
      pageLimit,
      expiry,
      productState.value,
      event,
      removedVariant,
      search
    );
  };
  const handleProductState = async (event: any) => {
    setFetchLoader(true);
    setPage(1);
    if (event) {
      setProductState(event);
      setKey(listSellerInventory.filterStatus, event, false);
      await fetchInventoryProduct(
        1,
        pageLimit,
        expiry,
        event.value,
        categories,
        removedVariant,
        search
      );
    } else {
      setProductState({});
      setKey(listSellerInventory.filterStatus, {}, false);
      await fetchInventoryProduct(
        1,
        pageLimit,
        expiry,
        '',
        categories,
        removedVariant,
        search
      );
    }
    setFetchLoader(false);
  };
  const handleExpiry = async (event: any) => {
    setFetchLoader(true);
    setPage(1);
    setTotalRecords(0);
    setProducts([]);
    if (event) {
      if (event.value === '-3') {
        setExpiry(event.value);
        setKey(listSellerInventory.filterExpiry, event.value, false);
        setRemovedVariant(true);
        await fetchInventoryProduct(
          1,
          pageLimit,
          event.value,
          productState.value,
          categories,
          true,
          search
        );
      } else {
        setRemovedVariant(false);
        setExpiry(event.value);
        setKey(listSellerInventory.filterExpiry, event.value, false);
        await fetchInventoryProduct(
          1,
          pageLimit,
          event.value,
          productState.value,
          categories,
          false,
          search
        );
      }
    } else {
      setExpiry('');
      setKey(listSellerInventory.filterExpiry, '', false);
      setRemovedVariant(false);
      await fetchInventoryProduct(
        1,
        pageLimit,
        '',
        productState.value,
        categories,
        false,
        search
      );
    }
    setFetchLoader(false);
  };
  const handleExpiryRemainder = async (
    event: any,
    id: string,
    isProduct: boolean,
    index: number,
    variantIndex: number
  ) => {
    let params = {
      days: (event.value * 30).toString(),
    };
    let apiService = new APICallService(
      inventory.variantRemainder,
      inventoryJSON.updateVariantInventoryRemainder(params),
      { id: id },
      '',
      '',
      '',
      GoodsWarehouse,
      tempWarehouse?._id
    );
    let response = await apiService.callAPI();
    if (response) {
      let temp = [...products];
      if (isProduct) {
        temp[index].variant.inventoryInfo = {
          ...temp[index].variant.inventoryInfo,
          reference: {
            reminder: {
              active: true,
              days: event.value * 30,
            },
          },
        };
      } else {
        temp[index].variants[variantIndex].variant.inventoryInfo = {
          ...temp[index].variants[variantIndex].variant.inventoryInfo,
          reference: {
            reminder: {
              active: true,
              days: event.value * 30,
            },
          },
        };
      }
      setProducts(temp);
    }
  };
  const handleCurrentPage = async (val: number) => {
    setFetchLoader(true);
    setPage(val);
    setKey(listSellerInventory.page, val);
    await fetchInventoryProduct(
      val,
      pageLimit,
      expiry,
      productState.value,
      categories,
      removedVariant,
      search
    );
    setFetchLoader(false);
  };
  const handleNextPage = async (val: number) => {
    setFetchLoader(true);
    setPage(val + 1);
    setKey(listSellerInventory.page, val + 1);
    await fetchInventoryProduct(
      val + 1,
      pageLimit,
      expiry,
      productState.value,
      categories,
      removedVariant,
      search
    );
    setFetchLoader(false);
  };
  const handlePreviousPage = async (val: number) => {
    setFetchLoader(true);
    setPage(val - 1);
    setKey(listSellerInventory.page, val - 1);
    await fetchInventoryProduct(
      val - 1,
      pageLimit,
      expiry,
      productState.value,
      categories,
      removedVariant,
      search
    );
    setFetchLoader(false);
  };
  const handlePageLimit = async (event: any) => {
    setFetchLoader(true);
    setPage(1);
    await setPageLimit(parseInt(event.target.value));
    setKey(listSellerInventory.page, 1);
    setKey(listSellerInventory.limit, parseInt(event.target.value));
    await fetchInventoryProduct(
      1,
      event.target.value,
      expiry,
      productState.value,
      categories,
      removedVariant,
      search
    );
    setFetchLoader(false);
  };
  const handleSearch = async (value: string) => {
    setSearch(value);
    setKey(listSellerInventory.search, value, false);
    setFetchLoader(true);
    setPage(1);
    await fetchInventoryProduct(
      1,
      pageLimit,
      expiry,
      productState.value,
      categories,
      removedVariant,
      value
    );
    setFetchLoader(false);
  };
  return (
    <>
      {showPermissionModal && (
        <PermissionModal
          show={showPermissionModal}
          onHide={() => setShowPermissionModal(false)}
          moduleName={'product'}
        />
      )}
      <BatchExpiery
        show={expieryModal}
        onHide={() => setExpieryModal(false)}
      />
      <GoodsNote
        show={goodsNote}
        onHide={() => setGoodsNote(false)}
      />
      {/* SELLER INVENTORY */}
      <Col xs={12}>
        <Row className="align-items-center g-2">
          <Col
            sm
            className="fs-22 fw-bolder"
          >
            Seller Inventory
          </Col>
          <Col
            sm="auto"
            className="min-w-264px"
          >
            <div className="d-flex align-items-center position-relative">
              <KTSVG
                path="/media/icons/duotune/general/gen021.svg"
                className="svg-icon-3 position-absolute ms-3"
              />
              <input
                type="text"
                id="kt_filter_search"
                className="form-control form-control-custom min-h-50px ps-10"
                placeholder="Search by product name…"
                value={search}
                onChange={(event: any) => {
                  handleSearch(event.target.value.trimStart());
                }}
              />
            </div>
          </Col>
        </Row>
      </Col>
      <Col xs={12}>
        <Card className="bg-light border">
          <Card.Body className="px-7">
            <Row className="align-items-center g-5">
              <Col
                md={6}
                lg={4}
              >
                <CustomComponentSelect
                  isDisabled={loading}
                  hideSelectedOptions={false}
                  options={filterCategories}
                  onChange={(event: any) => {
                    handleCategoryFilter(event);
                  }}
                  defaultValue={categories}
                  isMulti={true}
                />
              </Col>
              <Col
                md={6}
                lg={4}
              >
                <CustomSelect
                  backgroundColor="white"
                  value={productState}
                  default={productState}
                  onChange={(event: any) => {
                    handleProductState(event);
                  }}
                  options={productStatusJSON}
                  isClearable={Object.keys(productState).length}
                />
              </Col>
              <Col
                md={6}
                lg={4}
              >
                <CustomSelect
                  backgroundColor="#ffff"
                  onChange={(event: any) => {
                    handleExpiry(event);
                  }}
                  default={expiryJSON.find(
                    (option) => option?.value === expiry
                  )}
                  options={expiryJSON}
                  isClearable={expiry ? true : false}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
      <Col xs={12}>
        <Card className="border border-r10px">
          <Card.Body className="p-0">
            <div className="table-responsive">
              <table className="table table-rounded table-row-bordered align-middle gs-7 gy-4 mb-0">
                <thead>
                  <tr className="fw-bold fs-16 fw-600 text-dark border-bottom h-70px align-middle">
                    <th className="min-w-300px">Product name</th>
                    <th className="min-w-200px">
                      {expiry === '-1' ? 'Expired stock' : 'Available stock'}
                    </th>
                    <th className="min-w-200px">
                      {expiry === '-1' ? (
                        'Expired before'
                      ) : (
                        <>
                          Expire stock <br className="br" /> notify before
                        </>
                      )}
                    </th>
                    <th className="min-w-135px text-end"></th>
                  </tr>
                </thead>
                <tbody>
                  {!fetchLoader ? (
                    <>
                      {products && products.length ? (
                        <>
                          {products.map((productVal: any, index: number) => {
                            return (
                              <>
                                <tr>
                                  <td>
                                    <div className="d-flex align-items-center">
                                      <div
                                        className={clsx(
                                          'symbol symbol-50px border me-5 ',
                                          productVal.variant.inventoryInfo[0]
                                            .quantityTypes.length
                                            ? productVal.expiryInfo.expiring &&
                                              productVal.expiryInfo.expiring
                                                .quantityTypes.length
                                              ? productVal.expiryInfo.expiring.quantityTypes.filter(
                                                  (x: any) =>
                                                    productVal.variant.inventoryInfo[0].quantityTypes.some(
                                                      (y: any) =>
                                                        y.type === x.type
                                                    )
                                                ).length
                                                ? 'position-relative'
                                                : ''
                                              : ''
                                            : ''
                                        )}
                                      >
                                        {productVal.variant.inventoryInfo[0]
                                          .quantityTypes.length ? (
                                          productVal.expiryInfo.expiring &&
                                          productVal.expiryInfo.expiring
                                            .quantityTypes.length ? (
                                            productVal.expiryInfo.expiring.quantityTypes.filter(
                                              (x: any) =>
                                                productVal.variant.inventoryInfo[0].quantityTypes.some(
                                                  (y: any) => y.type === x.type
                                                )
                                            ).length ? (
                                              <div className="position-absolute start-0 bottom-0 m-n2">
                                                <img
                                                  className="error-icon"
                                                  src={errorWarning}
                                                  alt=""
                                                />
                                              </div>
                                            ) : (
                                              ''
                                            )
                                          ) : (
                                            ''
                                          )
                                        ) : (
                                          ''
                                        )}
                                        <img
                                          src={productVal.variant.media[0].url}
                                          className="object-fit-contain"
                                          alt=""
                                        />
                                      </div>
                                      <div className="d-flex flex-column">
                                        <span className="fs-15 fw-600">
                                          {productVal.variant.title.replace(
                                            /\s*\)\s*/g,
                                            ')'
                                          )}
                                        </span>
                                        <span className="fs-14 fw-500">
                                          {
                                            productVal.variant.product.business
                                              .name
                                          }
                                        </span>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <>
                                      {productVal.variant.inventoryInfo[0]
                                        .quantityTypes.length ? (
                                        <>
                                          {expiry !== '-1' ? (
                                            <>
                                              {productVal.variant.inventoryInfo[0].quantityTypes.map(
                                                (
                                                  quantVal: any,
                                                  quantIndex: number
                                                ) => {
                                                  return (
                                                    <>
                                                      <span
                                                        className={clsx(
                                                          'fs-15 fw-600',
                                                          (productVal.expiryInfo
                                                            .expired &&
                                                            productVal
                                                              .expiryInfo
                                                              .expired
                                                              .quantityTypes
                                                              .length) ||
                                                            (productVal
                                                              .expiryInfo
                                                              .expiring &&
                                                              productVal
                                                                .expiryInfo
                                                                .expiring
                                                                .quantityTypes
                                                                .length)
                                                            ? productVal
                                                                .expiryInfo
                                                                .expired &&
                                                              productVal.expiryInfo.expired.quantityTypes.some(
                                                                (a: any) =>
                                                                  a.type ===
                                                                  quantVal.type
                                                              )
                                                              ? 'text-danger'
                                                              : productVal
                                                                  .expiryInfo
                                                                  .expiring &&
                                                                productVal.expiryInfo.expiring.quantityTypes.some(
                                                                  (a: any) =>
                                                                    a.type ===
                                                                    quantVal.type
                                                                )
                                                              ? 'text-warning'
                                                              : ''
                                                            : ''
                                                        )}
                                                      >
                                                        {' '}
                                                        {quantIndex !== 0
                                                          ? ', '
                                                          : ''}
                                                        {quantVal.stockCount}{' '}
                                                        {quantVal.type ===
                                                          CartonWithDozens ||
                                                        quantVal.type ===
                                                          CartonWithPieces
                                                          ? 'cartons'
                                                          : ''}
                                                        {quantVal.type === Dozen
                                                          ? 'dozens'
                                                          : ''}
                                                        {quantVal.type === Piece
                                                          ? 'pieces'
                                                          : ''}
                                                      </span>
                                                    </>
                                                  );
                                                }
                                              )}
                                            </>
                                          ) : (
                                            <>
                                              {productVal.variant.inventoryInfo[0].quantityTypes.map(
                                                (
                                                  quantVal: any,
                                                  quantIndex: number
                                                ) => {
                                                  return (
                                                    <>
                                                      <span
                                                        className={clsx(
                                                          'fs-15 fw-600'
                                                        )}
                                                      >
                                                        {quantIndex !== 0
                                                          ? ', '
                                                          : ''}
                                                        {quantVal.stockCount}{' '}
                                                        {quantVal.type ===
                                                          CartonWithDozens ||
                                                        quantVal.type ===
                                                          CartonWithPieces
                                                          ? 'cartons'
                                                          : ''}
                                                        {quantVal.type === Dozen
                                                          ? 'dozens'
                                                          : ''}
                                                        {quantVal.type === Piece
                                                          ? 'pieces'
                                                          : ''}
                                                      </span>
                                                    </>
                                                  );
                                                }
                                              )}
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        <span className="fs-20 fw-600">-</span>
                                      )}
                                    </>
                                  </td>
                                  <td>
                                    <>
                                      {expiry !== '-1' ? (
                                        <div className="mw-130px">
                                          <CustomSelectTable2
                                            minHieight={'40px'}
                                            backgroundColor={'#ffff'}
                                            onChange={(event: any) => {
                                              handleExpiryRemainder(
                                                event,
                                                productVal.variant['_id'],
                                                true,
                                                index,
                                                0
                                              );
                                            }}
                                            isDisabled={
                                              !productVal.variant
                                                .inventoryInfo[0].quantityTypes
                                                .length || hasViewPermission
                                            }
                                            options={expiryMonthsJSON}
                                            value={expiryMonthsJSON.filter(
                                              (expVal: any) =>
                                                productVal.variant
                                                  .inventoryInfo[0]
                                                  .quantityTypes.length
                                                  ? productVal.variant
                                                      .inventoryInfo[0]
                                                      .reference.reminder
                                                    ? productVal.variant
                                                        .inventoryInfo[0]
                                                        .reference.reminder
                                                        .days /
                                                        30 ===
                                                      expVal.value
                                                    : {}
                                                  : {}
                                            )}
                                          />
                                        </div>
                                      ) : (
                                        <span className="fs-16 fw-600">
                                          {Method.dayDifference(
                                            productVal.expiryInfo.expired
                                              .minDate,
                                            productVal.expiryInfo.expired
                                              .maxDate
                                          )}{' '}
                                          days
                                        </span>
                                      )}
                                    </>
                                  </td>
                                  <td>
                                    <>
                                      {Method.hasPermission(
                                        AllSeller,
                                        View,
                                        currentUser
                                      ) &&
                                      Method.hasPermission(
                                        All_Products,
                                        View,
                                        currentUser
                                      ) ? (
                                        <Button
                                          variant="primary"
                                          className="btn-active-light-primary fs-14 fw-600 min-h-40px me-5"
                                          onClick={() => {
                                            navigate(
                                              '/inventory/goods-in-warehouse/product-details',
                                              {
                                                state: {
                                                  id: productVal.variant['_id'],
                                                  moduleName: AllSeller,
                                                },
                                              }
                                            );
                                          }}
                                        >
                                          View details
                                        </Button>
                                      ) : (
                                        <></>
                                      )}
                                      {Method.hasPermission(
                                        AllSeller,
                                        View,
                                        currentUser
                                      ) &&
                                      !Method.hasPermission(
                                        All_Products,
                                        View,
                                        currentUser
                                      ) ? (
                                        <Button
                                          variant="primary"
                                          className="btn-active-light-primary fs-14 fw-600 min-h-40px me-5"
                                          onClick={() => {
                                            setShowPermissionModal(true);
                                          }}
                                        >
                                          View details
                                        </Button>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                        </>
                      ) : (
                        <>
                          {' '}
                          <tr>
                            <td colSpan={4}>
                              <div className="w-100 fs-15 fw-bold d-flex justify-content-center">
                                No Data found
                              </div>
                            </td>
                          </tr>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <tr>
                        <td colSpan={4}>
                          <div className="w-100 d-flex justify-content-center">
                            <Loader loading={fetchLoader} />
                          </div>
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </Card.Body>
        </Card>
      </Col>
      {!loading && products && products.length ? (
        <Pagination
          totalRecords={totalRecords}
          currentPage={page}
          handleCurrentPage={handleCurrentPage}
          handleNextPage={handleNextPage}
          handlePreviousPage={handlePreviousPage}
          handlePageLimit={handlePageLimit}
          pageLimit={pageLimit}
        />
      ) : (
        <></>
      )}
    </>
  );
};
export default SellerInventory;
