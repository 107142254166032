import { Modal, Button } from 'react-bootstrap';
import { useState } from 'react';
import APICallService from '../../api/apiCallService';
import { useNavigate } from 'react-router-dom';
import { success } from '../../Global/toast';
import { String } from '../../utils/string';
import CrossSvg from '../../_admin/assets/media/svg_uTrade/cross-rounded-gray.svg';
const CommanCancelModal = (props: any) => {
  const [loading, setLoading] = useState(false);
  const handleSave = async () => {
    setLoading(true);
    await props.onSave();
    setLoading(false);
  };
  return (
    <>
      <Modal
        {...props}
        show={props.show}
        onHide={props.onHide}
        dialogClassName="modal-dialog-centered min-w-lg-590px"
        className="border-r10px"
        centered
        backdrop="static"
      >
        <Modal.Header className="border-bottom-0 d-flex justify-content-end position-relative">
          <img
            className="position-absolute top-0 end-0 mt-n5 me-n5"
            width={40}
            height={40}
            src={CrossSvg}
            alt="closebutton"
            onClick={props.onHide}
          />
        </Modal.Header>
        <Modal.Body className="pt-0 pb-1">
          <Modal.Title className="fs-26 fw-bolder text-center pt-lg-3 pb-5">
            {props.title}
          </Modal.Title>
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="bg-light-danger text-center border-r10px p-3">
                <span className="fs-18 fw-500">
                  {props.error ? props.error : props.warnMessage}
                </span>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center mb-4 border-top-0">
          {!props.error && (
            <>
              <Button
                variant="danger"
                size="lg"
                onClick={() => {
                 handleSave()
                }}
                disabled={props.loading}
              >
                {!props.loading ? (
                  <span className="indicator-label fs-18 fw-bold">
                    {props.btnTitle}
                  </span>
                ) : (
                  <span
                    className="indicator-progress fs-18 fw-bold"
                    style={{ display: 'block' }}
                  >
                    {String.PleaseWait}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default CommanCancelModal;
