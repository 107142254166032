import { useState } from 'react';
import { Button } from 'react-bootstrap';
import AdvertisementDetails from '../../../modals/advertisement-details';
import Loader from '../../../../Global/loader';
import { AdvertisementRequest, Advertising, HomePage, View } from '../../../../utils/constants';
import Method from '../../../../utils/methods';
import Pagination from '../../../../Global/pagination';
import { useAuth } from '../../auth';
import { getKey } from '../../../../Global/history';
function NewRequest(props: any) {
  const { currentUser } = useAuth();
  const [showAdDetails, setShowAdDetails] = useState(false);
  const [advertisement, setAdvertisement] = useState({});
  return (
    <>
      {showAdDetails ? (
        <AdvertisementDetails
          show={showAdDetails}
          advertisement={advertisement}
          onHide={() => setShowAdDetails(false)}
        />
      ) : (
        <></>
      )}
      <div className="table-responsive">
        <table className="table table-rounded table-row-bordered align-middle gy-6 mb-0">
          <thead>
            <tr className="fs-16 fw-600">
              <th className="min-w-175px">Seller name</th>
              <th className="min-w-150px">Ad placement</th>
              <th className="min-w-200px">Start & End date</th>
              <th className="min-w-125px">Cost per day</th>
              <th className="w-130px"></th>
            </tr>
          </thead>
          <tbody>
            {props.fetchLoading ? (
              <>
                <tr>
                  <td colSpan={5}>
                    <div className="d-flex justify-content-center">
                      <Loader loading={props.fetchLoading} />
                    </div>
                  </td>{' '}
                </tr>
              </>
            ) : (
              <>
                {props.advertisement.length ? (
                  <>
                    {props.advertisement.map(
                      (advertiseVal: any, index: number) => {
                        return (
                          <>
                            <tr>
                              <td>
                                <span className="fs-15 fw-500">
                                  {advertiseVal.business.ownerName}
                                </span>
                              </td>
                              <td>
                                <span className="fs-15 fw-500">
                                  {' '}
                                  {advertiseVal.placement === HomePage
                                    ? 'Home page'
                                    : 'Category page'}
                                </span>
                              </td>
                              <td>
                                <span className="fs-15 fw-500">
                                  {Method.convertDateToDDMMYYYY(
                                    advertiseVal.startDate
                                  ) +
                                    ' - ' +
                                    Method.convertDateToDDMMYYYY(
                                      advertiseVal.endDate
                                    )}{' '}
                                </span>
                              </td>
                              <td>
                                <span className="fs-15 fw-500">
                                  TSh{' '}
                                  {Method.getGeneralizedAmount(
                                    advertiseVal.amount
                                  )}
                                </span>
                              </td>
                              <td className="text-end">
                              {Method.hasPermission(Advertising, View, currentUser) ? (
                                <Button
                                  onClick={() => {
                                    setShowAdDetails(true);
                                    setAdvertisement(advertiseVal);
                                  }}
                                  className="btn-table"
                                >
                                  View details
                                </Button>
                              ) : (
                                <></>
                              )}
                              </td>
                            </tr>
                          </>
                        );
                      }
                    )}
                  </>
                ) : (
                  <tr>
                    <td colSpan={5}>
                      <div className="d-flex justify-content-center">
                        <span className="fs-15 fw-500">No Data found</span>
                      </div>
                    </td>{' '}
                  </tr>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
      {props.fetchLoading ? (
        <></>
      ) : (
        <>
          {props.advertisement.length ? (
            <>
              <Pagination
                totalRecords={props.total}
                currentPage={props.currentPage}
                handleCurrentPage={(event: any) => {
                  props.handleCurrentPage(event, AdvertisementRequest);
                }}
                handleNextPage={(event: any) => {
                  props.handleNextPage(event, AdvertisementRequest);
                }}
                handlePreviousPage={(event: any) => {
                  props.handlePreviousPage(event, AdvertisementRequest);
                }}
                pageLimit={parseInt(props.pageLimit)}
                handlePageLimit={(event: any) => {
                  props.handlePageLimit(event, AdvertisementRequest);
                }}
              />
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
}
export default NewRequest;
