import { Col, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import CrossSvg from '../../../_admin/assets/media/close.png';
import { useEffect, useState } from 'react';
import { buyer, reports, seller } from '../../../api/apiEndPoints';
import Method from '../../../utils/methods';
import APICallService from '../../../api/apiCallService';
import { OrderCash, OrderTigoPesa, PAGE_LIMIT } from '../../../utils/constants';
import { CustomSelect } from '../../custom/Select/CustomSelect';
import CustomDatePicker from '../../custom/DateRange/DatePicker';
import { CustomReportSelect } from '../../custom/Select/CustomReportSelect';
import { error } from '../../../Global/toast';
import { reportJson } from '../../../api/apiJSON/reports';
import { getKey } from '../../../Global/history';
import { warehouse as warehouseStoreString} from '../../../utils/storeString';
const AllProductsReport = (props: any) => {
   let tempWarehouse: any = getKey(warehouseStoreString.warehouseRef);
   tempWarehouse = JSON.parse(tempWarehouse);
  const [fetchLoader, setFetchLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [validation, setValidation] = useState<any>(false);
  const [sellerData, setSellerData] = useState([]);
  const [totalSellers, setTotalSellers] = useState(0);
  const [selectedSeller, setSelectedSeller] = useState<any>([]);
  useEffect(() => {
    (async () => {
      setFetchLoader(true);
      await fetchSeller(1 , PAGE_LIMIT);
      setFetchLoader(false);
    })();
  }, []);
  const fetchSeller = async (page: number , limit: number , search: string = '') => {
    setFetchLoader(true);
    let params = {
      pageNo: page,
      limit: limit,
      searchTerm: search,
      sortKey: 'name',
      sortOrder: 1,
      ['status[0]']: 4,
      needCount: true,
    };
    let apiService = new APICallService(seller.listSeller, params,'','','','',props.moduleName,tempWarehouse?._id || null);
    let response = await apiService.callAPI();
    if(response.records){
      setTotalSellers(response.total);
      let data: any = (prevResults: any) => page === 1 ? response.records : [...prevResults, ...response.records]
      setSellerData(data);
      }
      setFetchLoader(false);
  };

  const onMenuScrollToBottom = async () => {
    if (!(sellerData.length === totalSellers)) {
      let tempPage = page;
      tempPage = tempPage + 1;
      setPage(tempPage);
      await fetchSeller(tempPage, PAGE_LIMIT, searchTerm);
    }
  };
  const onMenuClose = async () => {
    setSearchTerm('');
  };
  const onMenuOpen = async () => {
    setPage(1);
    await fetchSeller(1,PAGE_LIMIT, searchTerm);
  };
  const handleInputChange = async (inputValue: string) => {
    setPage(1);
    setSearchTerm(inputValue);
    await fetchSeller(1, PAGE_LIMIT, inputValue);
  };

  const handleSeller = async (event: any) => {
    setSelectedSeller(event);
    setValidation(false);
    if (!event.length) {
      setValidation(true);
    }
  };
  const handleSubmit = async () => {
    if (selectedSeller.length) {
      let sellers: any = [];
      selectedSeller.map((val: any) => {
        sellers.push(val.id);
      });
      let params: any = {
        businesses: sellers,
        utcOffset: new Date().getTimezoneOffset(),
      };
      setLoading(true);
      let apiService = new APICallService(
        reports.productsReport,
        reportJson.getAllProductReport(params),
        undefined,
        'blob',
        '','',props.moduleName,tempWarehouse?._id || null
      );
      let response = await apiService.callAPI();
      if (response) {
        const pdfBlob = new Blob([response], { type: 'application/pdf' });
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(pdfBlob);
        downloadLink.download = 'product_report' + '.pdf';
        downloadLink.click();
        props.onHide();
      } else {
        error('Selected seller has no product listed');
        props.onHide();
      }
    } else {
      setValidation(true);
    }
    setLoading(false);
  };
  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onHide}
        dialogClassName="modal-dialog-centered min-w-lg-690px"
        className="border-r10px"
        contentClassName="p-5"
        backdrop={loading ? 'static' : true}
      >
        <Modal.Header className="border-bottom-0 text-center pb-6 mx-auto">
          {!loading ? (
            <div className="symbol symbol-md-40px symbol-35px close-inner-top">
              <img
                width={40}
                height={40}
                src={CrossSvg}
                alt=""
                onClick={props.onHide}
              />
            </div>
          ) : (
            <></>
          )}
          <Modal.Title className="fs-30 fw-bolder mw-lg-350px">
            Download report
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0 pb-5">
          <Row className="align-items-center g-3">
            <Col md={12}>
              <div className="d-flex align-items-center mb-2">
                <div className="fs-18 fw-500">Download Report for </div>
              </div>
              <CustomReportSelect
                border={validation ? '#e55451' : '#e0e0df'}
                backgroundColor="#ffff"
                minHeight="60px"
                isLoading={fetchLoader}
                closeMenuOnSelect={false}
                isSearchable={true}
                options={
                  sellerData && sellerData.length
                    ? sellerData.map((val: any) => {
                        return {
                          label: (
                            <>
                              <div className="symbol symbol-30px border me-3">
                                <img
                                  src={val.image}
                                  className="object-fit-contain"
                                  alt=""
                                />
                              </div>
                              <span className="fs-16 fw-600 text-black mb-0">
                                {val.name}
                              </span>
                            </>
                          ),
                          value: val._id,
                          id: val._id,
                          title: val.name,
                        };
                      })
                    : []
                }
                text={'sellers selected'}
                hideSelectedOptions={false}
                onMenuScrollToBottom = {onMenuScrollToBottom}
                onMenuClose = {() => {
                  onMenuClose();
                }}
                onMenuOpen = {() => {
                  onMenuOpen();
                }}
                onInputChange={(newValue: any, { action }: any) => {
                  if (action === 'input-change') {
                    handleInputChange(newValue);
                  }
                }}
                onChange={(event: any) => {
                  handleSeller(event);
                }}
                // isClearable={selectedSeller ? true : false}
                isMulti={true}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="justify-content-center pt-0 border-top-0">
          <Button
            variant="primary"
            size="lg"
            onClick={() => {
              handleSubmit();
            }}
            disabled={loading}
          >
            {!loading && (
              <span className="indicator-label">Download report</span>
            )}
            {loading && (
              <span
                className="indicator-progress"
                style={{ display: 'block' }}
              >
                Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default AllProductsReport;
