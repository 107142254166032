import { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Nav,
  OverlayTrigger,
  Popover,
  Row,
  Tab,
} from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import a3 from '../../../../_admin/assets/media/avatars/300-15.jpg';
import a4 from '../../../../_admin/assets/media/avatars/300-18.jpg';
import a1 from '../../../../_admin/assets/media/brand/elven_agri.png';
import a2 from '../../../../_admin/assets/media/brand/super_meal.png';
import threeDots from '../../../../_admin/assets/media/svg_uTrade/three-dot.svg';
import { KTSVG } from '../../../../_admin/helpers';
import { CustomSelectWhite } from '../../../custom/Select/CustomSelectWhite';
import { CustomSelectTable2 } from '../../../custom/Select/custom-select-table';
import Notification from '../../../notification/notification';
import SalesReportChart from './sales-report-chart';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../../../custom/DateRange/dateRange.css';
import { getPageLimit } from '../../auth';
import Method from '../../../../utils/methods';
import { customerService } from '../../../../api/apiEndPoints';
import APICallService from '../../../../api/apiCallService';
import Loader from '../../../../Global/loader';
import ViewCustomersManager from '../../customers/view-customers-manager';
import MyTeam from '../../my-team/my-team';
import { CustomerServices, PAGE_LIMIT } from '../../../../utils/constants';
import { warehouse as warehouseStoreString } from '../../../../utils/storeString';
import { getKey } from '../../../../Global/history';

const DistricDetails = () => {
  let tempWarehouse: any = getKey(warehouseStoreString.warehouseRef);
  tempWarehouse = JSON.parse(tempWarehouse);
  const { state }: any = useLocation();
  const navigate = useNavigate();
  const [tabKey, setTabKey] = useState<any>(1);
  const [startDate, setStartDate] = useState<any>(new Date());
  const [teamMember, setTeamMember] = useState([
    {
      value: 'memberOne',
      name: 'memberOne',
      label: (
        <>
          <div className="d-flex flex-row">
            <div className="symbol symbol-24px symbol-circle me-2">
              <img
                src={a3}
                className="object-fit-contain"
                alt=""
              />
            </div>
            <label
              className="form-check-label fs-16 fw-600 text-dark"
              htmlFor="Ex1"
            >
              Bianca Ross
            </label>
          </div>
        </>
      ),
    },
  ]);
  const [date, setDate] = useState<any>(new Date());
  const [fetchLoading, setFetchLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(PAGE_LIMIT);
  const [users, setUser] = useState<any>([]);
  const [products, setProducts] = useState<any>([]);
  const [totalBuyers, setTotalBuyers] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [district, setDistrict] = useState<string>(state.districtId);
  const [team, setTeam] = useState<any>([]);
  useEffect(() => {
    (async () => {
      setFetchLoading(true);
      await fetchData(page, searchTerm, date, state.districtId);
      setFetchLoading(false);
    })();
  }, []);
  const fetchData = async (
    pageNo: number,
    search: string,
    dateFilter: string,
    districtId: string
  ) => {
    let params:any = {
      pageNo: pageNo,
      limit: 5,
      sortKey: 'name',
      sortOrder: 1,
      searchTerm: search,
      needCount: pageNo === 1,
      needStats: pageNo === 1,
      district: districtId,
      year: Method.getYear(dateFilter),
      month: parseInt(Method.getMonth(dateFilter)) - 1,
    };
if (tempWarehouse?._id) {
  params = {
    ...params,
    warehouse: tempWarehouse?._id,
  };
}
    let apiService = new APICallService(
      customerService.districtDetails,
      params,
      { districtId: districtId, id: state.userId },
      '','','',CustomerServices,tempWarehouse?._id || null
    );
    let response: any = await apiService.callAPI();
    if (response) {
      if (response.buyers && Object.keys(response.buyers).length) {
        setUser(response.buyers.records);
      }
      if (Object.keys(response.products).length) {
        setProducts(response.products.records);
      }
      if (response.teams && Object.keys(response.teams).length) {
        setTeam(response.teams.records);
      }
      setTotalBuyers(response.totalBuyers);
    }
  };
  const handleDateFilter = async (value: any) => {
    setFetchLoading(true);
    setDate(value);
    setUser([]);
    setTotalBuyers(0);
    setProducts([]);
    setTeam([]);
    await fetchData(page, searchTerm, value, district);
    setFetchLoading(false);
  };
  const handleDistricts = async (event: any) => {
    setFetchLoading(true);
    setDistrict(event.value);
    setUser([]);
    setTotalBuyers(0);
    setTeam([]);
    setProducts([]);
    await fetchData(page, searchTerm, date, event.value);
    setFetchLoading(false);
  };
  const popover = (
    <Popover id="popover-basic">
      <Popover.Body className="p-2 bg-black border-r10px text-white">
        <span>Under Development</span>
      </Popover.Body>
    </Popover>
  );
  return (
    <>
      {/* Notification */}
      {/* <Notification
        bgColor="bg-fff4d9"
        icon={false}
        text="You need to assign team members for the new 15 customers."
      /> */}
      <div className="p-9">
        <Row className="align-items-center g-5 mb-7">
          <Col
            xs={12}
            sm
          >
            <h1 className="fs-22 fw-bolder mb-0">{state.districtName}</h1>
          </Col>
          <Col xs="auto">
            <DatePicker
              className="form-control bg-light mh-50px fs-16 fw-bold text-dark mw-lg-174px"
              selected={startDate}
              onChange={(data) => setStartDate(data)}
              selectsStart
              startDate={startDate}
              dateFormat="MMMM yyyy"
              showMonthYearPicker
              fixedHeight
            />
          </Col>
        </Row>
        <div className="custom-tabContainer">
          <Tab.Container
            activeKey={tabKey}
            onSelect={(k) => setTabKey(k)}
          >
            <Row className="align-items-center variant-categories">
              <Col
                lg={'auto'}
                className="mb-7"
              >
                <div className="bg-light border border-r8px p-3 px-md-6">
                  <Nav variant="pills">
                    <Nav.Item>
                      <Nav.Link eventKey={1}>Dashboard</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey={2}>Customers</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey={3}>Customer service team</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
              </Col>
              <Col xs={12}>
                <Tab.Content>
                  {/* Dashboard */}
                  <Tab.Pane eventKey={1}>
                    {!fetchLoading ? (
                      <>
                        <Row className="align-items-center variant-categories">
                          <Col xs={12}>
                            <Row className="align-items-center g-6 mb-7">
                              <Col
                                xl={3}
                                md={6}
                                sm={4}
                              >
                                <div className="border-r8px bg-d4e1fc rounded py-4 px-5">
                                  <div className="d-flex align-items-center">
                                    <div className="fs-22 fw-bolder">TSh 0</div>
                                  </div>
                                  <div className="fw-500 fs-16">
                                    Average daily sales
                                  </div>
                                </div>
                              </Col>
                              <Col
                                xl={3}
                                md={6}
                                sm={4}
                              >
                                <div className="border-r8px bg-ccebfd rounded py-4 px-5">
                                  <div className="d-flex align-items-center">
                                    <div className="fs-22 fw-bolder">0</div>
                                  </div>
                                  <div className="fw-500 fs-16">
                                    Orders This Month
                                  </div>
                                </div>
                              </Col>
                              <Col
                                xl={3}
                                md={6}
                                sm={4}
                              >
                                <div className="border-r8px bg-ccf3f0 rounded py-4 px-5">
                                  <div className="d-flex align-items-center">
                                    <div className="fs-22 fw-bolder">
                                      {totalBuyers}
                                    </div>
                                  </div>
                                  <div className="fw-500 fs-16">
                                    New customers
                                  </div>
                                </div>
                              </Col>
                              <Col
                                xl={3}
                                md={6}
                                sm={4}
                              >
                                <div className="border-r8px bg-fbebbc rounded py-4 px-5">
                                  <div className="d-flex align-items-center">
                                    <div className="fs-22 fw-bolder">TSh 0</div>
                                  </div>
                                  <div className="fw-500 fs-16">
                                    Total sales
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            {/* <SalesReportChart /> */}
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <>
                        <div className="d-flex justify-content-center">
                          <Loader loading={fetchLoading} />
                        </div>
                      </>
                    )}
                  </Tab.Pane>
                  {/* Customers */}
                  <Tab.Pane eventKey={2}>
                    <ViewCustomersManager
                      districtId={state.districtId}
                      districtName={state.districtName}
                      userId={state.userId}
                      isAdmin={true}
                    />
                  </Tab.Pane>
                  {/* Customer service team */}
                  <Tab.Pane eventKey={3}>
                    {/* <Card className="border border-r10px">
                      <Card.Body className="p-0">
                        <div className="table-responsive">
                          <table className="table table-rounded table-row-bordered align-middle gs-7 gy-4 mb-0">
                            <thead>
                              <tr className="fs-16 fw-bold text-dark border-bottom h-70px align-middle">
                                <th className="min-w-225px">
                                  Service team member
                                </th>
                                <th className="min-w-150px">
                                  Onboarded <br /> customers
                                </th>
                                <th className="min-w-150px">Orders placed</th>
                                <th className="min-w-100px">Total sales</th>
                                <th className="min-w-200px text-end"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <div className="symbol symbol-50px border me-5">
                                      <img
                                        src={a3}
                                        alt=""
                                        className="object-fit-contain"
                                      />
                                    </div>
                                    <span className="fs-15 fw-600">
                                      Patrick Richards
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <span className="fs-15 fw-600">10</span>
                                </td>
                                <td>
                                  <span className="fs-15 fw-600">06</span>
                                </td>
                                <td>
                                  <span className="fs-15 fw-600">
                                    TSh 132,300
                                  </span>
                                </td>
                                <td className="text-end">
                                  <div className="d-flex flex-nowrap flex-row justify-content-end">
                                    <Button
                                      variant="primary"
                                      className="fs-14 fw-600"
                                      onClick={() =>
                                        navigate(
                                          "/customer-service/member-details"
                                        )
                                      }
                                    >
                                      View details
                                    </Button>
                                    <Button
                                      variant="link"
                                      className="btn-flush btn-sm ms-3"
                                    >
                                      <img
                                        src={threeDots}
                                        width={23}
                                        height={7}
                                        alt=""
                                      />
                                    </Button>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <div className="symbol symbol-50px border me-5">
                                      <img
                                        src={a4}
                                        className="object-fit-contain"
                                        alt=""
                                      />
                                    </div>
                                    <span className="fs-15 fw-600">
                                      Bianca Ross
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <span className="fs-15 fw-600">10</span>
                                </td>
                                <td>
                                  <span className="fs-15 fw-600">06</span>
                                </td>
                                <td>
                                  <span className="fs-15 fw-600">
                                    TSh 132,300
                                  </span>
                                </td>
                                <td className="text-end">
                                  <div className="d-flex flex-nowrap flex-row justify-content-end">
                                    <Button
                                      variant="primary"
                                      className="fs-14 fw-600"
                                      onClick={() =>
                                        navigate(
                                          "/customer-service/member-details"
                                        )
                                      }
                                    >
                                      View details
                                    </Button>
                                    <Button
                                      variant="link"
                                      className="btn-flush btn-sm ms-3"
                                    >
                                      <img
                                        src={threeDots}
                                        width={23}
                                        height={7}
                                        alt=""
                                      />
                                    </Button>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </Card.Body>
                    </Card> */}
                    <MyTeam
                      districtId={state.districtId}
                      districtName={state.districtName}
                      userId={state.userId}
                      isAdmin={true}
                    />
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </div>
    </>
  );
};
export default DistricDetails;
