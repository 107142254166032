import { Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { IMAGES } from '../../../../utils/dummyJSON';
import ViewRequestedGoodsProducts from '../../../modals/view-requested-goods-products';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Method from '../../../../utils/methods';
import { OrderCancelled, OrderCash, OrdersDelivery, RefundsToCustomer, View } from '../../../../utils/constants';
import clsx from 'clsx';
import APICallService from '../../../../api/apiCallService';
import { AUTH, buyer } from '../../../../api/apiEndPoints';
import { useAuth } from '../../auth';
import { warehouse as warehouseStoreString } from '../../../../utils/storeString';
import { getKey } from '../../../../Global/history';
const MakeRefund = () => {
  let tempWarehouse: any = getKey(warehouseStoreString.warehouseRef);
  tempWarehouse = JSON.parse(tempWarehouse);
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const { state }: any = useLocation();
  const [customerDetails, setCustomerDetails] = useState<any>(
    state.customerDetails
  );
  const [validation, setValidation] = useState(
    Array.from({ length: state.selectedData }, (_, index) => false)
  );
  const [password, setPassword] = useState('');
  const [valid, setValid] = useState(false);
  const [show, setShow] = useState(false);
  const [passwordLoading, setPasswordLoading] = useState(false);
  const [selectedOrder, setSelectedOrders] = useState<any>(state.selectedData);
  const [viewProducts, setViewProducts] = useState(false);
  useEffect(() => {
    (async () => {
      await setCustomerDetails(state.customerDetails);
      // let temp = [];
      // if (state.selectedData.length) {
      //   temp = state.selectedData.filter(
      //     (val: any) =>
      //       val.status === OrderDelivered || val.status === OrderCancelled
      //   );
      // }
      await setSelectedOrders(state.selectedData);
    })();
  }, []);
  const handleChange = (value: any, index: number) => {
    let temp = JSON.parse(JSON.stringify([...selectedOrder]));
    let tempValidation = [...validation];
    tempValidation[index] = false;
    if (!value.trimStart().length) {
      tempValidation[index] = true;
    }
    temp[index].transactionId = value.trimStart().toUpperCase();
    setSelectedOrders(temp);
    setValidation(tempValidation);
  };
  const markAsRefund = async () => {
    let temp = [...selectedOrder];
    let tempValidation = [...validation];
    temp.forEach((item: any, index): any => {
      // Check each transaction in the "transactions" array
      if (item.payment.transactions[0].paymentMethod === OrderCash) {
        tempValidation[index] = false;
      } else {
        if (item.transactionId && item.transactionId !== '') {
          tempValidation[index] = false;
        } else {
          tempValidation[index] = true;
        }
      }
    });
    let allTrue = tempValidation.some((el: any) => {
      return el === true;
    });
    if (!allTrue) {
      if (password.length) {
        setPasswordLoading(true);
        let apiService = new APICallService(AUTH.checkPassword, {
          password: password,
        },'','','','',RefundsToCustomer,tempWarehouse?._id || null);
        let response = await apiService.callAPI();
        if (response) {
          let temp: any = [];
          selectedOrder.map((val: any) => {
            if (val.payment.transactions[0].paymentMethod === OrderCash) {
              temp.push({
                orderId: val._id,
                paymentMode: val.payment.transactions[0].paymentMethod,
                amount:
                  val.status === OrderCancelled
                    ? val.payment.receivedCharge
                    : val.payment.adjustmentCharge,
              });
            } else {
              temp.push({
                orderId: val._id,
                paymentMode: val.payment.transactions[0].paymentMethod,
                amount:
                  val.status === OrderCancelled
                    ? val.payment.receivedCharge
                    : val.payment.adjustmentCharge,
                transactionId: val.transactionId,
                phoneCountry: val.payment.transactions[0].phoneCountry,
                phone: val.payment.transactions[0].phone,
              });
            }
          });
          let params = {
            refundData: temp,
            buyerId: customerDetails.refundVal._id,
          };
          let apiService = new APICallService(buyer.updateRefundStatus, params,'','','','',RefundsToCustomer,tempWarehouse?._id || null);
          let response = await apiService.callAPI();
          window.history.back();
        }
        setPasswordLoading(false);
      } else {
        setValid(false);
      }
    }
    setValidation(tempValidation);
  };
  return (
    <>
      <div className="p-9">
        <ViewRequestedGoodsProducts
          show={viewProducts}
          onHide={() => setViewProducts(false)}
        />
        <h1 className="fs-22 fw-bolder mb-7">
          Make a refund for {selectedOrder.length} orders to{' '}
          {customerDetails.refundVal.business.name}
        </h1>
        <Row>
          <Col lg={12}>
            <Card className="border border-r10px bg-f9f9f9">
              <Card.Body className="p-0">
                <div className="table-responsive">
                  <table className="table table-row-bordered datatable align-middle gs-7 gy-4 my-0">
                    <thead>
                      <tr className="fs-16 fw-600 border-bottom-1">
                        <th className="min-w-md-175px min-w-150px">
                          Order Date & ID
                        </th>
                        <th className="min-w-100px">
                          Total order <br /> value
                        </th>
                        <th className="min-w-120px">
                          Total refund <br /> value
                        </th>
                        <th className="min-w-120px">Refund reason</th>
                        <th className="min-w-120px">Payment Mode</th>
                        <th className="min-w-120px">Refund Details</th>
                        <th className="min-w-120px">Status</th>
                        <th className="min-w-150px text-end"></th>
                      </tr>
                    </thead>
                    <tbody className="fs-15 fw-600">
                      {selectedOrder.map((order: any, index: number) => {
                        return (
                          <tr>
                            <td className="fs-15 fw-500">
                              <div className="d-flex align-items-start flex-column">
                                <div className="d-flex flex-row">
                                  <span className="text-dark d-block">
                                    {Method.convertDateToDDMMYYYYHHMM(
                                      order._createdAt,
                                      '-'
                                    )}
                                  </span>
                                </div>
                                <span className="text-gray d-block">
                                  {order.refKey}
                                </span>
                              </div>
                            </td>
                            <td>
                              {order.status === OrderCancelled
                                ? 'TSh ' +
                                  Method.getGeneralizedAmount(
                                    order.payment.receivedCharge
                                  )
                                : 'TSh ' +
                                  Method.getGeneralizedAmount(
                                    order.payment.adjustmentCharge
                                  )}
                            </td>
                            <td>
                              {order.status === OrderCancelled
                                ? 'TSh ' +
                                  Method.getGeneralizedAmount(
                                    order.payment.receivedCharge
                                  )
                                : 'TSh ' +
                                  Method.getGeneralizedAmount(
                                    order.payment.adjustmentCharge
                                  )}
                            </td>
                            <td>
                              <span className="badge badge-light fs-15 fw-600 border-r23px text-dark p-3 px-4">
                                {order.status === OrderCancelled
                                  ? 'Order Cancelled'
                                  : 'Order Modified'}
                              </span>
                            </td>
                            <td>
                              <span className="badge bg-e5f6de text-dark fs-15 fw-600 p-3">
                                {order.payment.transactions[0].paymentMethod ===
                                OrderCash
                                  ? 'Cash'
                                  : 'Tigo-Pesa'}{' '}
                              </span>
                            </td>
                            {order.payment.transactions[0].paymentMethod ===
                            OrderCash ? (
                              <td>
                                <span className="badge bg-light text-dark fs-15 fw-600 p-3">
                                  -
                                </span>
                              </td>
                            ) : (
                              <td>
                                <span className="badge bg-light text-dark fs-15 fw-600 p-3">
                                  {order.payment.transactions[0].phoneCountry}{' '}
                                  {order.payment.transactions[0].phone}
                                </span>
                              </td>
                            )}
                            <td>
                              <Form.Control
                                className={clsx(
                                  ' bg-white w-100 border',
                                  validation[index] ? 'border-danger' : ''
                                )}
                                disabled={
                                  order.payment.transactions[0]
                                    .paymentMethod === OrderCash
                                }
                                value={
                                  order.transactionId ? order.transactionId : ''
                                }
                                onChange={(e) =>
                                  handleChange(e.target.value, index)
                                }
                              ></Form.Control>
                            </td>
                            <td className="text-end">
                            {(Method.hasPermission(RefundsToCustomer, View, currentUser) && Method.hasPermission(OrdersDelivery, View, currentUser)) ? (
                              <Button
                                className="fs-14 fw-600"
                                onClick={() =>
                                  navigate('/orders/order-details', {
                                    state: {id: order._id, moduleName: RefundsToCustomer},
                                  })
                                }
                              >
                                View details
                              </Button>
                            ) : (
                              <></>
                            )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4}>
            <Card className="card-stretch bg-light border border-r10px mt-7">
              <Card.Body>
                <span className="fs-22 fw-600">
                  Please enter your account password to mark as paid.
                </span>
                <InputGroup
                  className={clsx(
                    'border border-r8px mt-6',
                    !valid ? '' : 'border-danger'
                  )}
                >
                  <Form.Control
                    className="border-0 form-control-custom text-dark bg-white"
                    placeholder="Type here…"
                    type={show ? 'text' : 'password'}
                    value={password}
                    onChange={(event: any) => {
                      if (!event.target.value.trimStart().length) {
                        setValid(true);
                      } else setValid(false);
                      setPassword(event.target.value.trimStart());
                    }}
                  />
                  <InputGroup.Text className="border-0">
                    <Button
                      variant="link"
                      className="btn-flush"
                      onClick={() => {
                        setShow(!show);
                      }}
                    >
                      {show ? (
                        <img
                          width={25}
                          height={16}
                          src={IMAGES.eyeBlue}
                          alt=""
                        />
                      ) : (
                        <img
                          width={25}
                          height={16}
                          src={IMAGES.eyeGray}
                          alt=""
                        />
                      )}
                    </Button>
                  </InputGroup.Text>
                </InputGroup>
                <div className="d-block mt-4">
                  <Button
                    size="lg"
                    className="w-100 min-h-60px"
                    onClick={() => {
                      markAsRefund();
                    }}
                  >
                    {!passwordLoading && (
                      <span className="indicator-label">Mark as paid</span>
                    )}
                    {passwordLoading && (
                      <span
                        className="indicator-progress"
                        style={{ display: 'block' }}
                      >
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default MakeRefund;
