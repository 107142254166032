import { useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import DownArrow from "../../../_admin/assets/media/svg_uTrade/down-arrow.svg";
import UpArrow from "../../../_admin/assets/media/svg_uTrade/up-arrow.svg";
import clsx from "clsx";
import APICallService from "../../../api/apiCallService";
import { master, platformFees } from "../../../api/apiEndPoints";
import { platformFeesJSON } from "../../../api/apiJSON/platformFees";
import { success } from "../../../Global/toast";
import { platformFeesToast } from "../../../utils/toast";
import Loader from "../../../Global/loader";
import ConfirmChanges from "../../modals/confirm-changes";
import { Add, Delete, Edit, PlatformFees } from "../../../utils/constants";
import Method from "../../../utils/methods";
import { useAuth } from "../auth";
import { warehouse as warehouseStoreString } from "../../../utils/storeString";
import { getKey } from "../../../Global/history";
const ExampleTable = () => {
  let tempWarehouse: any = getKey(warehouseStoreString.warehouseRef);
  tempWarehouse = JSON.parse(tempWarehouse);
  const { currentUser } = useAuth();
  const [expandedRows, setExpandedRows] = useState<any>([]);
  const [commission, setCommission] = useState<any>([]);
  const [initialCommission, setInitialCommission] = useState<any>([]);
  const [expandState, setExpandState] = useState<any>({});
  const [subexpandedRows, setSubExpandedRows] = useState<any>([]);
  const [subexpandState, setSubExpandState] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [fetchLoader, setFetchLoader] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const hasViewPermission = !Method.hasPermission(
    PlatformFees,
    Edit,
    currentUser
  );
  useEffect(() => {
    (async () => {
      if (!Method.hasModulePermission(PlatformFees, currentUser)) {
        return window.history.back();
      }
      await fetchPlatformFees();
    })();
  }, []);
  const fetchPlatformFees = async () => {
    setFetchLoader(true);
    let params: any = {
      categoriesDepth: 3,
    };
    if (tempWarehouse?._id) {
      params.warehouse = tempWarehouse._id;
    }
    let apiService = new APICallService(
      master.categoryList,
      params,
      "",
      "",
      "",
      "",
      PlatformFees,
      tempWarehouse?._id
    );
    let response = await apiService.callAPI();
    if (response.records) {
      setCommission(response.records);
      setInitialCommission(response.records);
    }
    setFetchLoader(false);
  };
  const handleEpandRow = (event: any, userId: number) => {
    const currentExpandedRows: any = expandedRows;
    const isRowExpanded = currentExpandedRows.includes(userId);
    let obj: any = {};
    isRowExpanded ? (obj[userId] = false) : (obj[userId] = true);
    setExpandState(obj);
    // If the row is expanded, we are here to hide it. Hence remove
    // it from the state variable. Otherwise add to it.
    const newExpandedRows = isRowExpanded
      ? currentExpandedRows.filter((id: number) => id !== userId)
      : currentExpandedRows.concat(userId);
    setExpandedRows(newExpandedRows);
  };
  const handleSubEpandRow = (event: any, userId: number) => {
    const currentExpandedRows: any = subexpandedRows;
    const isRowExpanded = currentExpandedRows.includes(userId);
    let obj: any = {};
    isRowExpanded ? (obj[userId] = false) : (obj[userId] = true);
    setSubExpandState(obj);
    // If the row is expanded, we are here to hide it. Hence remove
    // it from the state variable. Otherwise add to it.
    const newExpandedRows = isRowExpanded
      ? currentExpandedRows.filter((id: number) => id !== userId)
      : currentExpandedRows.concat(userId);
    setSubExpandedRows(newExpandedRows);
  };
  const handlePrimaryComissionChange = async (
    value: string,
    primaryIndex: number
  ) => {
    let temp: any = JSON.parse(JSON.stringify([...commission]));
    if (value.includes("-")) {
      value = value.replace("-", "");
    }
    if (temp[primaryIndex].categories.length) {
      temp[primaryIndex].categories.map((subVal: any, subIndex: number) => {
        temp[primaryIndex].categories[subIndex]["commission"] = value;
        if (subVal.categories.length) {
          subVal.categories.map((groupVal: any, groupIndex: number) => {
            temp[primaryIndex].categories[subIndex]["categories"][groupIndex][
              "commission"
            ] = value;
          });
        }
      });
    }
    temp[primaryIndex]["commission"] = value;
    setCommission(temp);
    await checkValidation(temp);
  };
  const handleSubComissionChange = async (
    value: string,
    primaryIndex: number,
    subIndex: number
  ) => {
    let temp: any = [...commission];
    if (value.includes("-")) {
      value = value.replace("-", "");
    }
    temp[primaryIndex].categories[subIndex]["commission"] = value;
    if (temp[primaryIndex].categories[subIndex].categories.length) {
      temp[primaryIndex].categories[subIndex].categories.map(
        (groupVal: any, groupIndex: number) => {
          temp[primaryIndex].categories[subIndex]["categories"][groupIndex][
            "commission"
          ] = value;
        }
      );
    }
    setCommission(temp);
    await checkValidation(temp);
  };
  const handleGroupComissionChange = async (
    value: string,
    primaryIndex: number,
    subIndex: number,
    groupIndex: number
  ) => {
    let temp: any = [...commission];
    temp[primaryIndex].categories[subIndex]["categories"][groupIndex][
      "commission"
    ] = value;
    setCommission(temp);
    await checkValidation(temp);
  };
  const handleOnKeyPress = (event: any) => {
    var charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
      event.preventDefault();
      return false;
    }
    return true;
  };
  const checkValidation = async (commissionData: any) => {
    let category = [...commissionData];
    for (let i = 0; i < category.length; i++) {
      if (category[i].categories && category[i].categories.length) {
        let subTemp = [];
        for (let j = 0; j < category[i].categories.length; j++) {
          let groupTemp = [];
          subTemp.push(category[i].categories[j].commission.toString());
          if (j === category[i].categories.length - 1) {
            if (subTemp.length) {
              let result = await checkUniqueElement(subTemp);
              if (!result) {
                if (category[i].commission !== "0") {
                  category[i].commission = "-";
                }
              } else {
                category[i].commission = category[i].categories[j].commission;
              }
            }
          }
          if (
            category[i].categories[j].categories &&
            category[i].categories[j].categories.length
          ) {
            for (
              let k = 0;
              k < category[i].categories[j].categories.length;
              k++
            ) {
              groupTemp.push(
                category[i].categories[j].categories[k].commission.toString()
              );
              if (k === category[i].categories[j].categories.length - 1) {
                if (groupTemp.length) {
                  let result = await checkUniqueElement(groupTemp);
                  if (!result) {
                    if (category[i].categories[j].commission !== "0") {
                      category[i].categories[j].commission = "-";
                    }
                  } else {
                    let result2 = await checkUniqueElement(subTemp);
                    if (result2) {
                      category[i].commission =
                        category[i].categories[j].categories[k].commission;
                      category[i].categories[j].commission =
                        category[i].categories[j].categories[k].commission;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    setCommission(category);
  };
  const checkUniqueElement = (arrays: any) => {
    return arrays.every((val: any) => {
      return val === arrays[0];
    });
  };
  const handleSave = async () => {
    if (JSON.stringify(commission) !== JSON.stringify(initialCommission)) {
      setConfirmModal(true);
    }
  };
  const onSave = async () => {
    let tempCommission: any = [...commission];
    setLoading(true);
    let apiService = new APICallService(
      platformFees.updateCommissionFees,
      platformFeesJSON.addUpdatePlatformFees({ commission: tempCommission }),
      "",
      "",
      "",
      "",
      PlatformFees,
      tempWarehouse?._id
    );
    let response = await apiService.callAPI();
    if (response) {
      success(platformFeesToast.feesUpdated);
      setConfirmModal(false);
    }
    setLoading(false);
  };
  return (
    <>
      {confirmModal ? (
        <ConfirmChanges
          show={confirmModal}
          title={"Are you sure you want to save changes?"}
          message={""}
          onHide={() => {
            setCommission(initialCommission);
            setConfirmModal(false);
          }}
          commission={commission}
          onSave={onSave}
          loading={loading}
        />
      ) : (
        <></>
      )}
      <Row>
        <Col sm={12}>
          <Table
            className="border table-rounded table-row-bordered gs-7 align-middle fs-16 fw-600"
            responsive
          >
            {fetchLoader ? (
              <>
                <div className="d-flex justify-content-center h-50px align-items-center">
                  <Loader loading={fetchLoader} />
                </div>
              </>
            ) : (
              <>
                <thead>
                  <tr className="fw-bold fs-16 fw-600 text-dark border-bottom h-70px align-middle">
                    <th className="min-w-lg-600px min-w-300px">
                      All categories
                    </th>
                    <th className="min-w-250px">Add fees in percentage</th>
                    <th className="text-end"></th>
                  </tr>
                </thead>
                <tbody>
                  <>
                    {commission.length ? (
                      <>
                        {commission.map((feesVal: any, index: number) => {
                          return (
                            <>
                              <tr
                                className={clsx(
                                  feesVal.categories &&
                                    feesVal.categories.length
                                    ? "table-active"
                                    : ""
                                )}
                              >
                                <td>
                                  <div className="d-flex flex-row align-items-center">
                                    <div className="symbol symbol-40px border-r8px border">
                                      <img
                                        className="img-fluid border-r8px object-fit-contain"
                                        src={feesVal.image}
                                        alt=""
                                      />
                                    </div>
                                    <span className="fs-16 fw-600 ms-3">
                                      {feesVal.title}
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <InputGroup
                                    className={clsx(
                                      "min-h-40px mw-150px border border-r5px",
                                      feesVal.commission?.toString().length
                                        ? ""
                                        : "border-danger"
                                    )}
                                  >
                                    <Form.Control
                                      className={clsx(
                                        "border-0 fs-14 fw-600 text-dark"
                                      )}
                                      aria-label="Default"
                                      aria-describedby=""
                                      value={
                                        feesVal.commission === 0
                                          ? "-"
                                          : feesVal.commission?.toString()
                                      }
                                      onChange={(event: any) => {
                                        handlePrimaryComissionChange(
                                          event.target.value.trimStart(),
                                          index
                                        );
                                      }}
                                      onKeyPress={(event: any) => {
                                        handleOnKeyPress(event);
                                      }}
                                      disabled={
                                        loading || hasViewPermission
                                        //  ||
                                        // (Method.hasPermission(
                                        //   PlatformFees,
                                        //   Delete,
                                        //   currentUser
                                        // ) &&
                                        //   !Method.hasPermission(
                                        //     PlatformFees,
                                        //     Edit,
                                        //     currentUser
                                        //   )) ||
                                        // (Method.hasPermission(
                                        //   PlatformFees,
                                        //   Add,
                                        //   currentUser
                                        // ) &&
                                        //   !Method.hasPermission(
                                        //     PlatformFees,
                                        //     Edit,
                                        //     currentUser
                                        //   ))
                                      }
                                    />
                                    <InputGroup.Text
                                      className="bg-white border-0"
                                      id=""
                                    >
                                      %
                                    </InputGroup.Text>
                                  </InputGroup>
                                </td>
                                <td className="text-end">
                                  {feesVal.categories &&
                                  feesVal.categories.length ? (
                                    <Button
                                      variant="link"
                                      onClick={(event) =>
                                        handleEpandRow(event, index)
                                      }
                                    >
                                      {expandState[index] ? (
                                        <img
                                          className="img-fluid"
                                          src={UpArrow}
                                          alt=""
                                        />
                                      ) : (
                                        <img
                                          className="img-fluid"
                                          src={DownArrow}
                                          alt=""
                                        />
                                      )}
                                    </Button>
                                  ) : (
                                    <></>
                                  )}
                                </td>
                              </tr>
                              {expandedRows.includes(index) ? (
                                <>
                                  {feesVal.categories &&
                                  feesVal.categories.length ? (
                                    feesVal.categories.map(
                                      (subfeesVal: any, subIndex: number) => {
                                        return (
                                          <>
                                            <tr
                                              className={clsx(
                                                subfeesVal.categories.toString()
                                                  .length
                                                  ? "table-active"
                                                  : ""
                                              )}
                                            >
                                              <td>
                                                <div className="d-flex flex-row align-items-center ps-10">
                                                  <div className="symbol symbol-40px border-r8px border">
                                                    <img
                                                      className="img-fluid border-r8px object-fit-contain"
                                                      src={subfeesVal.image}
                                                      alt=""
                                                    />
                                                  </div>
                                                  <span className="fs-16 fw-600 ms-3">
                                                    {subfeesVal.title}
                                                  </span>
                                                </div>
                                              </td>
                                              <td>
                                                <InputGroup
                                                  className={clsx(
                                                    "min-h-40px mw-150px border border-r5px",
                                                    subfeesVal.commission.toString()
                                                      .length
                                                      ? ""
                                                      : "border-danger"
                                                  )}
                                                >
                                                  <Form.Control
                                                    className="border-0 fs-14 fw-600 text-dark"
                                                    aria-label="Default"
                                                    aria-describedby=""
                                                    value={
                                                      subfeesVal.commission ===
                                                      0
                                                        ? "-"
                                                        : subfeesVal.commission.toString()
                                                    }
                                                    onChange={(event: any) => {
                                                      handleSubComissionChange(
                                                        event.target.value.trimStart(),
                                                        index,
                                                        subIndex
                                                      );
                                                    }}
                                                    onKeyPress={(
                                                      event: any
                                                    ) => {
                                                      handleOnKeyPress(event);
                                                    }}
                                                    disabled={
                                                      loading ||
                                                      hasViewPermission
                                                    }
                                                  />
                                                  <InputGroup.Text
                                                    className="bg-white border-0"
                                                    id=""
                                                  >
                                                    %
                                                  </InputGroup.Text>
                                                </InputGroup>
                                              </td>
                                              <td className="text-end">
                                                {subfeesVal.categories
                                                  .length ? (
                                                  <Button
                                                    variant="link"
                                                    onClick={(event) =>
                                                      handleSubEpandRow(
                                                        event,
                                                        subIndex
                                                      )
                                                    }
                                                  >
                                                    {subexpandState[
                                                      subIndex
                                                    ] ? (
                                                      <img
                                                        className="img-fluid"
                                                        src={UpArrow}
                                                        alt=""
                                                      />
                                                    ) : (
                                                      <img
                                                        className="img-fluid"
                                                        src={DownArrow}
                                                        alt=""
                                                      />
                                                    )}
                                                  </Button>
                                                ) : (
                                                  <></>
                                                )}
                                              </td>
                                            </tr>
                                            {subexpandedRows.includes(
                                              subIndex
                                            ) ? (
                                              <>
                                                {subfeesVal.categories
                                                  .length ? (
                                                  subfeesVal.categories.map(
                                                    (
                                                      groupfeesVal: any,
                                                      groupIndex: number
                                                    ) => {
                                                      return (
                                                        <tr>
                                                          <td>
                                                            <div className="d-flex flex-row align-items-center ps-20">
                                                              <div className="symbol symbol-40px border-r8px">
                                                                <div className="symbol-label bg-body border fs-16 fw-600 text-gray me-3">
                                                                  {groupIndex +
                                                                    1}
                                                                </div>
                                                              </div>
                                                              <span className="fs-16 fw-600 ms-3">
                                                                {
                                                                  groupfeesVal.title
                                                                }
                                                              </span>
                                                            </div>
                                                          </td>
                                                          <td>
                                                            <InputGroup
                                                              className={clsx(
                                                                "min-h-40px mw-150px border border-r5px",
                                                                groupfeesVal.commission.toString()
                                                                  .length
                                                                  ? ""
                                                                  : "border-danger"
                                                              )}
                                                            >
                                                              <Form.Control
                                                                className="border-0 fs-14 fw-600 text-dark"
                                                                aria-label="Default"
                                                                aria-describedby=""
                                                                value={groupfeesVal.commission.toString()}
                                                                onChange={(
                                                                  event: any
                                                                ) => {
                                                                  handleGroupComissionChange(
                                                                    event.target.value.trimStart(),
                                                                    index,
                                                                    subIndex,
                                                                    groupIndex
                                                                  );
                                                                }}
                                                                onKeyPress={(
                                                                  event: any
                                                                ) => {
                                                                  handleOnKeyPress(
                                                                    event
                                                                  );
                                                                }}
                                                                disabled={
                                                                  loading ||
                                                                  hasViewPermission
                                                                }
                                                              />
                                                              <InputGroup.Text
                                                                className="bg-white border-0"
                                                                id=""
                                                              >
                                                                %
                                                              </InputGroup.Text>
                                                            </InputGroup>
                                                          </td>
                                                          <td className="text-end"></td>
                                                        </tr>
                                                      );
                                                    }
                                                  )
                                                ) : (
                                                  <></>
                                                )}
                                              </>
                                            ) : null}
                                          </>
                                        );
                                      }
                                    )
                                  ) : (
                                    <></>
                                  )}
                                </>
                              ) : null}
                            </>
                          );
                        })}
                      </>
                    ) : (
                      <>
                        <tr>
                          <td colSpan={3}>
                            <div className="w-100 fs-15 fw-bold d-flex justify-content-center">
                              No Data found
                            </div>
                          </td>
                        </tr>
                      </>
                    )}
                  </>
                </tbody>
              </>
            )}
          </Table>
        </Col>
        {!fetchLoader ? (
          commission.length ? (
            <Col xs={12}>
              <Row className="align-items-center g-3 ">
                <Col xs="auto">
                  <Button
                    variant="primary"
                    size="lg"
                    className="w-fit-content"
                    onClick={() => {
                      handleSave();
                    }}
                    disabled={loading || hasViewPermission}
                  >
                    {!loading && (
                      <span className="indicator-label fs-16 fw-bold">
                        Save details
                      </span>
                    )}
                    {loading && (
                      <span
                        className="indicator-progress fs-16 fw-bold"
                        style={{ display: "block" }}
                      >
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}{" "}
                  </Button>
                </Col>
              </Row>
            </Col>
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
      </Row>
    </>
  );
};
export default ExampleTable;
