import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Nav,
  OverlayTrigger,
  Popover,
  Row,
  Tab,
} from "react-bootstrap";
import { KTSVG } from "../../../../../_admin/helpers";
import { IMAGES } from "../../../../../utils/dummyJSON";
import BatchExpiry from "../../../../modals/batch-expiry";
import GoodsNote from "../../../../modals/goods-note";
import APICallService from "../../../../../api/apiCallService";
import { seller } from "../../../../../api/apiEndPoints";
import {
  AllSeller,
  CartonWithDozens,
  CartonWithPieces,
  Dozen,
  PAGE_LIMIT,
  Piece,
} from "../../../../../utils/constants";
import Pagination from "../../../../../Global/pagination";
import { getKey, setKey } from "../../../../../Global/history";
import {
  listSellerStockDetails,
  warehouse as warehouseStoreString,
} from "../../../../../utils/storeString";
const SellerStockDetails = (props: any) => {
  let tempWarehouse: any = getKey(warehouseStoreString.warehouseRef);
  tempWarehouse = JSON.parse(tempWarehouse);
  const [tabKey, setTabKey] = useState<any>(1);
  const [expiryModal, setExpiryModal] = useState(false);
  const [goodsNote, setGoodsNote] = useState(false);
  const [fetchLoader, setFetchLoader] = useState(false);
  const [stock, setStock] = useState<any>([]);
  const [page, setPage] = useState<any>(
    getKey(listSellerStockDetails.page) || 1
  );
  const [pageLimit, setPageLimit] = useState<any>(
    getKey(listSellerStockDetails.limit) || PAGE_LIMIT
  );
  const [search, setSearch] = useState<string>(
    getKey(listSellerStockDetails.search)?.toString() || ""
  );
  const [totalRecords, setTotalRecords] = useState(0);
  const [batches, setBatches] = useState([]);
  const [product, setProduct] = useState({});
  useEffect(() => {
    (async () => {
      setFetchLoader(true);
      await fetchDetails(page, pageLimit);
      setFetchLoader(false);
    })();
  }, []);
  const fetchDetails = async (
    pageNo: number,
    limit: number,
    searchTerm?: any
  ) => {
    let params: any = {
      pageNo: pageNo,
      limit: limit,
      sortKey: "_createdAt",
      sortOrder: -1,
      needCount: true,
      searchTerm: searchTerm,
      ["business[]"]: props.businessId,
    };

    if(tempWarehouse?._id){
      params.warehouse = tempWarehouse._id;
    }

    let apiService = new APICallService(
      seller.listStockDetails,
      params,
      {
        id: props.businessId,
      },
      "",
      "",
      "",
      AllSeller,
      tempWarehouse?._id
    );
    let response = await apiService.callAPI();
    if (response.records.length) {
      if (response.total) {
        setTotalRecords(response.total);
      }
    }
    setStock(response.records);
  };
  const handleCurrentPage = async (val: number) => {
    setKey(listSellerStockDetails.page, val);
    setPage(val);
    await fetchDetails(val, pageLimit);
  };
  const handleNextPage = async (val: number) => {
    setPage(val + 1);
    setKey(listSellerStockDetails.page, val + 1);
    await fetchDetails(val + 1, pageLimit);
  };
  const handlePreviousPage = async (val: number) => {
    setPage(val - 1);
    setKey(listSellerStockDetails.page, val - 1);
    await fetchDetails(val - 1, pageLimit);
  };
  const handlePageLimit = async (event: any) => {
    setPage(1);
    setPageLimit(event.target.value);
    setKey(listSellerStockDetails.page, 1);
    setKey(listSellerStockDetails.limit, parseInt(event.target.value));
    await fetchDetails(1, event.target.value);
  };
  const popover = (
    <Popover id="popover-basic">
      <Popover.Body className="p-2 bg-black border-r10px text-white">
        <span>Stock batches are not available</span>
      </Popover.Body>
    </Popover>
  );
  const renderStockCount = (inventoryInfo: any, type: any) => {
    const item = inventoryInfo.quantityTypes.find(
      (item: any) => item.type === type
    );
    return item ? (
      <span className="fs-15 fw-600 d-block">{item.stockCount}</span>
    ) : (
      <span className="fs-15 fw-600 d-block">-</span>
    );
  };
  const renderButtonOrOverlay = (
    inventoryInfo: any,
    setExpiryModal: any,
    popover: any,
    stock: any
  ) => {
    if (
      inventoryInfo.reference &&
      inventoryInfo.reference.batches &&
      inventoryInfo.reference.batches.length
    ) {
      return (
        <Button
          className="btn-table"
          onClick={() => {
            setBatches(inventoryInfo.reference.batches);
            setExpiryModal(true);
            setProduct(stock);
          }}
        >
          View batch & expiry
        </Button>
      );
    } else {
      return (
        <OverlayTrigger trigger="hover" placement="bottom" overlay={popover}>
          <Button className="btn-table">View batch & expiry</Button>
        </OverlayTrigger>
      );
    }
  };
  const handleSearch = async (value: string) => {
    setSearch(value);
    setKey(listSellerStockDetails.search, value, false);
    setFetchLoader(true);
    setPage(1);
    await fetchDetails(1, pageLimit, value);
    setFetchLoader(false);
  };
  return (
    <>
      {batches.length ? (
        <BatchExpiry
          show={expiryModal}
          onHide={() => setExpiryModal(false)}
          batches={batches}
          product={product}
        />
      ) : (
        <></>
      )}
      <GoodsNote show={goodsNote} onHide={() => setGoodsNote(false)} />
      {/* STOCK DETAILS */}
      <Col xs={12}>
        <Row className="align-items-center g-2">
          <Col sm className="fs-22 fw-bolder">
            Stock details
          </Col>
          <Col sm="auto" className="min-w-264px">
            <div className="d-flex align-items-center position-relative">
              <KTSVG
                path="/media/icons/duotune/general/gen021.svg"
                className="svg-icon-3 position-absolute ms-3"
              />
              <input
                type="text"
                id="kt_filter_search"
                className="form-control form-control-custom min-h-50px ps-10"
                placeholder="Search by product name…"
                value={search}
                onChange={(event: any) => {
                  handleSearch(event.target.value.trimStart());
                }}
              />
            </div>
          </Col>
        </Row>
        <div className="custom-tabContainer">
          <Tab.Container activeKey={tabKey} onSelect={(k) => setTabKey(k)}>
            <Row className="align-items-center variant-categories">
              <Col lg={"auto"} className="mb-6">
                <div className="bg-light border border-r8px p-3 px-md-6">
                  <Nav variant="pills">
                    <Nav.Item>
                      <Nav.Link eventKey={1}>All products</Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item>
                    <Nav.Link eventKey={2}>Discounted products</Nav.Link>
                  </Nav.Item> */}
                  </Nav>
                </div>
              </Col>
              <Col xs={12}>
                <Tab.Content>
                  <Tab.Pane eventKey={1}>
                    <Card className="border border-r10px">
                      <Card.Body className="p-0">
                        <div className="table-responsive">
                          <table className="table align-middle table-rounded table-row-bordered gs-7 gy-4 mb-0">
                            <thead>
                              <tr className="text-start fw-bold fs-16 gs-0 border-bottom h-65px align-middle">
                                <th className="min-w-150px">Product name</th>
                                <th className="min-w-50px">Carton</th>
                                <th className="min-w-50px">Dozen</th>
                                <th className="min-w-50px">Pieces</th>
                                <th className="min-w-160px text-end"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {stock && stock.length ? (
                                <>
                                  {" "}
                                  {stock.map((stockVal: any) => {
                                    return (
                                      <tr className="odd">
                                        <td className="fs-15 fw-600">
                                          <div className="d-flex align-items-center">
                                            <div className="symbol symbol-50px border me-3">
                                              <img
                                                src={stockVal.media[0].url}
                                                className="object-fit-contain"
                                                alt=""
                                              />
                                            </div>
                                            <span className="fs-15 fw-600">
                                              {stockVal.title.replace(
                                                /\s*\)\s*/g,
                                                ')'
                                              )}
                                            </span>
                                          </div>
                                        </td>
                                        <td>
                                          {renderStockCount(
                                            stockVal.inventoryInfo[0],
                                            CartonWithDozens
                                          ) ||
                                            renderStockCount(
                                              stockVal.inventoryInfo[0],
                                              CartonWithPieces
                                            )}
                                        </td>
                                        <td>
                                          {renderStockCount(
                                            stockVal.inventoryInfo[0],
                                            Dozen
                                          )}
                                        </td>
                                        <td>
                                          {renderStockCount(
                                            stockVal.inventoryInfo[0],
                                            Piece
                                          )}
                                        </td>
                                        <td className="text-end">
                                          {renderButtonOrOverlay(
                                            stockVal.inventoryInfo[0],
                                            setExpiryModal,
                                            popover,
                                            stockVal
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </>
                              ) : (
                                <tr>
                                  <td colSpan={4}>
                                    <div className="w-100 fs-15 fw-bold d-flex justify-content-center">
                                      No Data found
                                    </div>
                                  </td>
                                </tr>
                              )}{" "}
                            </tbody>
                          </table>
                        </div>
                      </Card.Body>
                    </Card>
                    {!fetchLoader && stock && stock.length ? (
                      <Pagination
                        totalRecords={totalRecords}
                        currentPage={page}
                        handleCurrentPage={handleCurrentPage}
                        handleNextPage={handleNextPage}
                        handlePreviousPage={handlePreviousPage}
                        handlePageLimit={handlePageLimit}
                        pageLimit={pageLimit}
                      />
                    ) : (
                      <></>
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey={2}>
                    <Card className="border border-r10px">
                      <Card.Body className="p-0">
                        <div className="table-responsive">
                          <table className="table align-middle table-rounded table-row-bordered gs-7 gy-4 mb-0">
                            <thead>
                              <tr className="text-start fw-bold fs-16 gs-0 border-bottom h-65px align-middle">
                                <th className="min-w-50px">
                                  Products <br /> added on
                                </th>
                                <th className="min-w-150px">Product name</th>
                                <th className="min-w-125px">Batch & Expiery</th>
                                <th>Unit price</th>
                                <th className="min-w-50px">Qty. & Type</th>
                                <th className="min-w-135px text-end"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <span className="fs-15 fw-500">10/07/23</span>
                                </td>
                                <td>
                                  <div className="d-flex align-items-center mw-175px">
                                    <div className="symbol symbol-50px border me-3">
                                      <img
                                        src={IMAGES.NutroWafarBiscuits}
                                        className="object-fit-contain"
                                        alt=""
                                      />
                                    </div>
                                    <span className="fs-15 fw-600">
                                      Nutro Wafer Biscuits
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <span className="fs-15 fw-500">
                                    Discounted Batch 4 <br />
                                    20/04/26
                                  </span>
                                </td>
                                <td>
                                  <InputGroup className="mw-146px border border-r5px text-black">
                                    <InputGroup.Text
                                      id="basic-addon2"
                                      className="border-0 fs-14 fw-600 text-dark"
                                    >
                                      TSh
                                    </InputGroup.Text>
                                    <Form.Control className="form-control bg-light border-0 ps-0 fs-14 fw-600 text-dark mh-45px" />
                                  </InputGroup>
                                </td>
                                <td>
                                  <span className="fs-15 fw-600">
                                    10 X Pieces
                                  </span>
                                </td>
                                <td className="text-end">
                                  <Button
                                    className="btn-table"
                                    onClick={() => setGoodsNote(true)}
                                  >
                                    View note
                                  </Button>
                                </td>
                              </tr>
                              <tr className="odd">
                                <td>
                                  <span className="fs-15 fw-500">10/07/23</span>
                                </td>
                                <td>
                                  <div className="d-flex align-items-center mw-175px">
                                    <div className="symbol symbol-50px border me-3">
                                      <img
                                        src={IMAGES.AmazonMonstaPops}
                                        className="object-fit-contain"
                                        alt=""
                                      />
                                    </div>
                                    <span className="fs-15 fw-600">
                                      Amazon Monsta Pops (Strawberry)
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <span className="fs-15 fw-500">
                                    Discounted Batch 4 <br />
                                    20/04/26
                                  </span>
                                </td>
                                <td>
                                  <InputGroup className="mw-146px border border-r5px text-black">
                                    <InputGroup.Text
                                      id="basic-addon2"
                                      className="border-0 fs-14 fw-600 text-dark"
                                    >
                                      TSh
                                    </InputGroup.Text>
                                    <Form.Control className="form-control bg-light border-0 ps-0 fs-14 fw-600 text-dark mh-45px" />
                                  </InputGroup>
                                </td>
                                <td>
                                  <span className="fs-15 fw-600">
                                    5 X Dozens
                                  </span>
                                </td>
                                <td className="text-end">
                                  <Button
                                    className="btn-table"
                                    onClick={() => setGoodsNote(true)}
                                  >
                                    View note
                                  </Button>
                                </td>
                              </tr>
                              <tr className="odd">
                                <td>
                                  <span className="fs-15 fw-500">10/07/23</span>
                                </td>
                                <td>
                                  <div className="d-flex align-items-center mw-175px">
                                    <div className="symbol symbol-50px border me-3">
                                      <img
                                        src={IMAGES.NutroWafarBiscuits}
                                        className="object-fit-contain"
                                        alt=""
                                      />
                                    </div>
                                    <span className="fs-15 fw-600">
                                      Nutro Wafer Biscuits
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <span className="fs-15 fw-500">
                                    Discounted Batch 4 <br />
                                    20/04/26
                                  </span>
                                </td>
                                <td>
                                  <InputGroup className="mw-146px border border-r5px text-black">
                                    <InputGroup.Text
                                      id="basic-addon2"
                                      className="border-0 fs-14 fw-600 text-dark"
                                    >
                                      TSh
                                    </InputGroup.Text>
                                    <Form.Control className="form-control bg-light border-0 ps-0 fs-14 fw-600 text-dark mh-45px" />
                                  </InputGroup>
                                </td>
                                <td>
                                  <span className="fs-15 fw-600 d-block">
                                    2 X Cartons
                                  </span>
                                </td>
                                <td className="text-end">
                                  <Button
                                    className="btn-table"
                                    onClick={() => setGoodsNote(true)}
                                  >
                                    View note
                                  </Button>
                                </td>
                              </tr>
                              <tr className="odd">
                                <td>
                                  <span className="fs-15 fw-500">10/07/23</span>
                                </td>
                                <td>
                                  <div className="d-flex align-items-center mw-175px">
                                    <div className="symbol symbol-50px border me-3">
                                      <img
                                        src={IMAGES.NutroWafarBiscuits}
                                        className="object-fit-contain"
                                        alt=""
                                      />
                                    </div>
                                    <span className="fs-15 fw-600">
                                      Nutro Wafer Biscuits
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <span className="fs-15 fw-500">
                                    Discounted Batch 4 <br />
                                    20/04/26
                                  </span>
                                </td>
                                <td>
                                  <InputGroup className="mw-146px border border-r5px text-black">
                                    <InputGroup.Text
                                      id="basic-addon2"
                                      className="border-0 fs-14 fw-600 text-dark"
                                    >
                                      TSh
                                    </InputGroup.Text>
                                    <Form.Control className="form-control bg-light border-0 ps-0 fs-14 fw-600 text-dark mh-45px" />
                                  </InputGroup>
                                </td>
                                <td>
                                  <span className="fs-15 fw-600">
                                    15 X Pieces
                                  </span>
                                </td>
                                <td className="text-end">
                                  <Button
                                    className="btn-table"
                                    onClick={() => setGoodsNote(true)}
                                  >
                                    View note
                                  </Button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </Card.Body>
                    </Card>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </Col>
    </>
  );
};
export default SellerStockDetails;
