export const interTransferJSON = {
  addTransferRequest: ({
    variants,
    discounts,
    warehouseRequest,
    sender,
    recevier,
  }: any) => {
    let temp: any = [];
    variants.map((val: any) => {
      temp.push({
        variant: val._id,
        quantityType: val.quantityTypes[0].type,
        stockCount: val.quantityTypes[0].stockCount,
      });
    });
    let returnData: any = {
      variants: temp,
      discounts: discounts,
      warehouseRequest: warehouseRequest,
    };
    if (sender) {
      returnData.sender = sender;
    }
    if (recevier) {
      returnData.recevier = recevier;
    }
    return returnData;
  },
  addWarehouseTransferRequest: ({
    variants,
    discounts,
    warehouseTransferRequest,
    warehouse,
    receiverWarehouse,
  }: any) => {
    let temp: any = [];
    variants.map((val: any) => {
      temp.push({
        variant: val._id,
        quantityType: val.quantityTypes[0].type,
        stockCount: val.quantityTypes[0].stockCount,
      });
    });
    let returnData: any = {
      variants: temp,
      discounts: discounts,
      warehouseTransferRequest: warehouseTransferRequest,
    };
    if (warehouse) {
      returnData.warehouse = warehouse;
    }
    if (receiverWarehouse) {
      returnData.receiverWarehouse = receiverWarehouse;
    }
    return returnData;
  },
};
