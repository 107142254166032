import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import TextEditor from './text-editor';
import Loader from '../../../Global/loader';
import { CustomSelect } from '../../custom/Select/CustomSelect';
import APICallService from '../../../api/apiCallService';
import { appSettings } from '../../../api/apiEndPoints';
import { success } from '../../../Global/toast';
import { appSettingsToast } from '../../../utils/toast';
import { Add, Delete, Edit, TermsConditions } from '../../../utils/constants';
import Method from '../../../utils/methods';
import { useAuth } from '../auth';
import { getKey } from '../../../Global/history';
import { warehouse as warehouseStoreString } from '../../../utils/storeString';
const TermsCondition = () => {
  let tempWarehouse: any = getKey(warehouseStoreString.warehouseRef);
  tempWarehouse = JSON.parse(tempWarehouse);
  const { currentUser } = useAuth();
  const [privacyData, setPrivacyData] = useState<any>('');
  const [loading, setLoading] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(true);
  const [selectedLanguage, setSelectedLanguage] = useState<any>({
    label: 'English',
    title: 'English',
    value: 'en',
  });
  const hasViewPermission = !Method.hasPermission(
    TermsConditions,
    Add,
    currentUser
  );
  const handleDataChange = (data: any) => {
    setPrivacyData(data);
  };
  const handleLanguageChange = async (selectedOption: any) => {
    setSelectedLanguage(selectedOption);
    setFetchLoading(true);
    const apiService = new APICallService(
      appSettings.termsCondition,
      null,
      null,
      null,
      selectedOption.value,
      '',
      TermsConditions,
      tempWarehouse?._id
    );
    const response = await apiService.callAPI();
    if (response) {
      setPrivacyData(response);
    }
    setFetchLoading(false);
  };
  useEffect(() => {
    (async () => {
      setFetchLoading(true);
      if (!Method.hasModulePermission(TermsConditions, currentUser)) {
        return window.history.back();
      }
      await fetchData();
      setFetchLoading(false);
    })();
  }, []);
  const fetchData = async () => {
    setLoading(true);
    const apiService = new APICallService(
      appSettings.termsCondition,
      '',
      '',
      '',
      '',
      '',
      TermsConditions,
      tempWarehouse?._id
    );
    const response = await apiService.callAPI();
    if (response) {
      setPrivacyData(response);
    }
    setLoading(false);
  };
  const handleSave = async (data: any) => {
    setLoading(true);
    const apiService = new APICallService(
      appSettings.addTermsCondition,
      {
        content: data,
      },
      null,
      null,
      selectedLanguage.value,
      '',
      TermsConditions,
      tempWarehouse?._id
    );
    const response = await apiService.callAPI();
    if (response) {
      success(appSettingsToast.addTermsCondition);
    }
    setLoading(false);
  };
  return (
    <div className="p-9">
      <Row className="align-items-center">
        <Col
          md
          className="align-self-center mb-5"
        >
          <Row className="align-items-center">
            <Col
              lg={8}
              md={6}
            >
              <h1 className="fs-22 fw-bolder">Add / Edit Terms & Conditions</h1>
            </Col>
            <Col
              lg={4}
              md={6}
            >
              <CustomSelect
                backgroundColor="white"
                placeholder="Select Language"
                onChange={handleLanguageChange}
                value={selectedLanguage}
                options={[
                  {
                    label: 'English',
                    title: 'English',
                    value: 'en',
                  },
                  {
                    label: 'Swahili',
                    title: 'Swahili',
                    value: 'sw',
                  },
                ]}
              />
            </Col>
          </Row>
        </Col>
        {fetchLoading ? (
          <div className="d-flex justify-content-center m-4">
            <Loader loading={fetchLoading} />
          </div>
        ) : (
          <>
            <TextEditor
              data={privacyData}
              handleDataChange={handleDataChange}
            />
            <Col
              xs="auto"
              className="pt-7"
            >
              <Button
                variant="primary"
                size="lg"
                onClick={() => handleSave(privacyData)}
                disabled={loading || hasViewPermission}
              >
                {!loading && (
                  <span className="indicator-label">Save Changes</span>
                )}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}
                  >
                    Please wait...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </Button>
            </Col>
          </>
        )}
      </Row>
    </div>
  );
};
export default TermsCondition;
