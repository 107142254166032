export const multipleWarehouseJSON = {
    addWarehouse: ( warehouseData:any) => {
      console.log('warehouseDataaasas', warehouseData)
      let tempData: any = {};
      if (warehouseData) {
        tempData.name = warehouseData.name
        tempData.email = warehouseData.email
        tempData.phone = warehouseData.phone
        tempData.phoneCountry = warehouseData.phoneCountry
        tempData.address = warehouseData.address
        tempData.lat = warehouseData.lat
        tempData.lng = warehouseData.lng
        tempData.district = warehouseData.district.map((val:any)=> val.value)
        tempData.manager = warehouseData.manager.map((val:any)=> val.value)
        tempData.discount = warehouseData.discount1.length ? warehouseData.discount1 : warehouseData.discount2
        tempData.discountType = warehouseData.discount1.length ? 1 : 2
        tempData.roundValue = warehouseData.roundValue
      }
      return tempData;
    },
  };
  