import {
  Button,
  Card,
  Col,
  OverlayTrigger,
  Popover,
  Row,
} from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import APICallService from '../../../api/apiCallService';
import { ordersDelivery, routePlanning } from '../../../api/apiEndPoints';
import Loader from '../../../Global/loader';
import editIcon from '../../../_admin/assets/media/svg_uTrade/edit-round-blue.svg';
import CrossIcon from '../../../_admin/assets/media/svg_uTrade/cross-rounded-blue.svg';
import DeleteIcon from '../../../_admin/assets/media/svg_uTrade/trash.svg';
import greenCheck from '../../../_admin/assets/media/svg_uTrade/green_check.svg';
import {
  Add,
  CartonWithDozens,
  CartonWithPieces,
  Delete,
  Dozen,
  Edit,
  OrderCancelled,
  OrderQueueFailed,
  OrderQueueProcessed,
  OrdersDelivery,
  Piece,
  RouteOrderCancelled,
  RouteOrderDelivered,
  RouteOrderLoaded,
  RouteOrderOutForDelivery,
  RouteOrderTigoPesa,
  TigoPesaPayment,
} from '../../../utils/constants';
import { routePlanningJSON } from '../../../api/apiJSON/routePlanning';
import CancelPlan from '../../modals/cancel-delivery-plan';
import Method from '../../../utils/methods';
import clsx from 'clsx';
import AddOrderPlan from '../../modals/add-order-to-plan';
import { error, success } from '../../../Global/toast';
import { CustomSelectTable } from '../../custom/Select/CustomSelectTable';
import { CustomSelect } from '../../custom/Select/CustomSelect';
import { getKey, setKey } from '../../../Global/history';
import { listOrdersDelivery } from '../../../utils/storeString';
import { orderDeliveryJSON } from '../../../api/apiJSON/order';
import ConfirmChanges from '../../modals/confirm-changes';
import { useAuth } from '../auth';
import { warehouse as warehouseStoreString } from '../../../utils/storeString';
import CustomDatePicker from '../../custom/DateRange/DatePicker';
const ReviewOrderList = () => {
  let tempWarehouse: any = getKey(warehouseStoreString.warehouseRef);
  tempWarehouse = JSON.parse(tempWarehouse);
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const { state }: any = useLocation();
  const [currentIndex, setCurrentIndex] = useState<any>(-1);
  const [outForDelivery, setoutForDelivery] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dateLoading, setDateLoading] = useState(false);
  const [fetchLoader, setFetchLoader] = useState(true);
  const [details, setDetails] = useState<any>();
  const [confirmModal, setConfirmModal] = useState(false);
  const [show, setShow] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [singleVehicle, setSingleVehicle] = useState(false);
  const [vehicleInfo, setVehicleInfo] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState<any>();
  const [planInfo, setPlanInfo] = useState<any>();
  const [selectedProduct, setSelectedProduct] = useState<any>([]);
  const [selectedProductValidation, setSelectedProductValidation] =
    useState<any>([]);
  const [quantityError, setQuantityError] = useState<boolean>(false);
  const [editingIndex, setEditingIndex] = useState<any>(null);
  const [originalDetails, setOriginalDetails] = useState<any>(null);
  const [editingVariantIndex, setEditingVariantIndex] = useState(null);
  const [confirm, setConfirm] = useState<any>(null);
  const [orderId, setOrderId] = useState<any>();
  const [isOrderPlaced, setIsOrderPlaced] = useState<any>(false);
  const [orderLoading, setOrderLoading] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [showDatePicker, setShowDatePicker] = useState<any>(false);

  useEffect(() => {
    (async () => {
      setFetchLoader(true);
      await fetchPlanInfo();
      setFetchLoader(false);
    })();
  }, []);
  const fetchPlanInfo = async () => {
    let apiService = new APICallService(
      routePlanning.planInfo,
      state.routeId,
      '',
      '',
      '',
      '',
      OrdersDelivery,
      tempWarehouse?._id || null
    );
    let response: any = await apiService.callAPI();
    setPlanInfo(response);
    setStartDate(new Date(response.record.date))
    setSingleVehicle(response.vehicles.length == 1);
    setVehicleInfo(response.vehicles);
    let vehicle: any = getKey(listOrdersDelivery.selectedVehicle);
    if (vehicle) {
      const filteredRecords = response.vehicles.filter(
        (record: any) => record.vehicle.reference === vehicle
      );
      if (filteredRecords.length) {
        setSelectedDriver(filteredRecords[0]);
      } else {
        setSelectedDriver(response.vehicles[0].vehicle.reference);
        setKey(
          listOrdersDelivery.selectedVehicle,
          response.vehicles[0].vehicle.reference,
          false
        );
      }
    } else {
      vehicle = response.vehicles[0].vehicle.reference;
      setKey(
        listOrdersDelivery.selectedVehicle,
        response.vehicles[0].vehicle.reference,
        false
      );
      setSelectedDriver(response.vehicles[0]);
    }
    await fetchDeliveryPlans(state.routeId, vehicle);
  };
  const fetchDeliveryPlans = async (routeId: string, vehicleId: string) => {
    const params: any = { forReview: true };
    if (tempWarehouse?._id) {
      params.warehouse = tempWarehouse?._id;
    }
    let apiService = new APICallService(
      routePlanning.vehicleInfo,
      params,
      { routeId: routeId, vehicleId: vehicleId },
      '',
      '',
      '',
      OrdersDelivery,
      tempWarehouse?._id || null
    );
    let response: any = await apiService.callAPI();
    let temp = JSON.parse(JSON.stringify({ ...response }));
    temp.orders = temp.orders.reverse();
    setDetails(JSON.parse(JSON.stringify({ ...temp })));
    setOriginalDetails(JSON.parse(JSON.stringify({ ...temp })));
  };
  const handleAddOrderToList = async (data: any) => {
    setLoading(true);
    let params = {
      order: data,
    };
    let apiService = new APICallService(
      routePlanning.addOrder,
      routePlanningJSON.addOrder(params),
      {
        routeId: planInfo.record._id,
        vehicleId: selectedDriver.vehicle.reference,
      },
      '',
      '',
      '',
      OrdersDelivery,
      tempWarehouse?._id || null
    );
    let response: any = await apiService.callAPI();
    if (response) {
      navigate('/orders/review-order-list', {
        state: {
          routeId: planInfo.record._id,
          vehicleId: selectedDriver.vehicle.reference,
        },
      });
    }
    setLoading(false);
  };
  const handleCancel = async () => {
    setCancelLoading(true);
    let params = {
      status: RouteOrderCancelled,
    };
    let apiService = new APICallService(
      routePlanning.vehicleRouteStatus,
      routePlanningJSON.vehicleRouteStatusUpdate(params),
      {
        routeId: planInfo.record._id,
        vehicleId: selectedDriver.vehicle.reference,
      },
      '',
      '',
      '',
      OrdersDelivery,
      tempWarehouse?._id || null
    );
    let response: any = await apiService.callAPI();
    if (response) {
      navigate('/orders/route-details', {
        state: {
          routeId: planInfo.record._id,
          vehicleId: selectedDriver.vehicle.reference,
        },
      });
    }
    setCancelLoading(false);
    setConfirmModal(false);
  };
  const handleRemoveOrderFromList = async (data: any, index: number) => {
    setLoading(true);
    if (details.orders.length === 1) {
      error('At least one order is required');
    } else {
      setCurrentIndex(index);
      let params = {
        order: data,
      };
      let apiService = new APICallService(
        routePlanning.removeOrder,
        params,
        {
          routeId: planInfo.record._id,
          vehicleId: selectedDriver.vehicle.reference,
        },
        '',
        '',
        '',
        OrdersDelivery,
        tempWarehouse?._id || null
      );
      let response: any = await apiService.callAPI();
      if (response) {
        setEditingVariantIndex(null);
        setEditingIndex(null);
        await fetchDeliveryPlans(
          planInfo.record._id,
          selectedDriver.vehicle.reference
        );
      }
      setCurrentIndex(-1);
    }
    setLoading(false);
  };
  const completeReview = async () => {
    setLoading(true);
    let apiService = new APICallService(
      routePlanning.completeReview,
      {},
      {
        id: planInfo.record._id,
      },
      '',
      '',
      '',
      OrdersDelivery,
      tempWarehouse?._id || null
    );
    let response: any = await apiService.callAPI();
    if (response) {
      navigate('/orders/goods-loading-details', {
        state: {
          vehicleId: selectedDriver.vehicle.reference,
          routeId: planInfo.record._id,
        },
      });
    }
    setLoading(false);
  };

  const changePlanDate = async () => {
    setShowDatePicker(!showDatePicker);
    setDateLoading(true);
    let apiService = new APICallService(
      routePlanning.updatePlanDate,
      {
        date:Method.convertDateToFormat(startDate,"YYYY-MM-DD")
      },
      {
        id: planInfo.record._id,
      },
      '',
      '',
      '',
      OrdersDelivery,
      tempWarehouse?._id || null
    );
    let response: any = await apiService.callAPI();
    if (response) {
      await fetchDeliveryPlans(
        planInfo.record._id,
        selectedDriver.vehicle.reference
      );
    }
    setDateLoading(false);
  };
  const openMenuOnClick = async () => {
    setShow(true);
  };
  const onMenuClose = async () => {
    setShow(false);
  };
  const onMenuOpen = async () => {
    setShow(true);
  };
  const handleOption = async (event: any) => {
    if (event.value === 1) {
      setConfirmModal(true);
    }
  };
  const handleChange = async (event: any) => {
    setFetchLoader(true);
    setSelectedDriver(event);
    setKey(listOrdersDelivery.selectedVehicle, event.vehicle.reference, false);
    await fetchDeliveryPlans(state.routeId, event.vehicle.reference);
    setFetchLoader(false);
  };
  function calculateTotalCostWithoutTax(stockCount: any, amount: any) {
    const price = stockCount * amount;
    return price;
  }
  const handleEditClick = (index: any, vIndex: any) => {
    setDetails(originalDetails);
    setEditingVariantIndex(vIndex);
    setEditingIndex(index);
  };
  const handleSaveClick = async (index: any) => {
    await fetchDeliveryPlans(state.routeId, selectedDriver.vehicle.reference);
    setEditingVariantIndex(null);
    setEditingIndex(null);
  };
  const handleRemoveProduct = async (
    isRemove: boolean,
    quantIndex: any,
    index: any,
    product: any
  ) => {
    setCancelLoading(true);
    if (isRemove) {
      const temp = { ...product };
      temp.stockCount = 0;
      setQuantityError(false);
      setSelectedProduct(temp);
      const newDetails = { ...details };
      if (newDetails.orders && newDetails.orders[index]) {
        const updatedOrder = { ...newDetails.orders[index] };
        updatedOrder.variants[quantIndex] = temp;
        newDetails.orders[index] = {
          ...newDetails.orders[index],
          updatedOrder,
        };
        newDetails.orders[index].routeVehicle[quantIndex] = {
          ...newDetails.orders[index].routeVehicle[quantIndex],
          temp,
        };
      }
      let variants: any = [];
      newDetails.orders[index].variants.map((val: any) => {
        variants.push({
          variant: val.variant,
          stockCount: val.stockCount,
          quantityType: val.quantityType,
          discounts: val.clonedQuantityType.discountAmtEnabled
            ? val.clonedQuantityType
            : [],
        });
      });
      let data: any = {
        variants: variants,
        record: newDetails.orders[index]._id,
        buyer: newDetails.orders[index].customer.reference,
        address: newDetails.orders[index].address.reference,
      };
      let apiService = new APICallService(
        routePlanning.updateOrder,
        data,
        {
          routeId: planInfo.record._id,
          vehicleId: selectedDriver.vehicle.reference,
        },
        '',
        '',
        '',
        OrdersDelivery,
        tempWarehouse?._id || null
      );
      let response: any = await apiService.callAPI();
      if (response) {
        await fetchDeliveryPlans(
          planInfo.record._id,
          selectedDriver.vehicle.reference
        );
      }
    } else {
      if (details.orders.length === 1) {
        error('At least one order is required');
      } else {
        setCurrentIndex(index);
        let params = {
          order: product,
        };
        let apiService = new APICallService(
          routePlanning.removeOrder,
          routePlanningJSON.addOrder(params),
          {
            routeId: planInfo.record._id,
            vehicleId: selectedDriver.vehicle.reference,
          },
          '',
          '',
          '',
          OrdersDelivery,
          tempWarehouse?._id || null
        );
        let response: any = await apiService.callAPI();
        if (response) {
          let apiService = new APICallService(
            ordersDelivery.status,
            orderDeliveryJSON.updateOrderStatus({ status: OrderCancelled }),
            { id: product },
            '',
            '',
            '',
            OrdersDelivery,
            tempWarehouse?._id || null
          );
          let response = await apiService.callAPI();
          if (response) {
            await fetchDeliveryPlans(
              planInfo.record._id,
              selectedDriver.vehicle.reference
            );
          }
        }
        setCurrentIndex(-1);
      }
    }
    setConfirm(null);
    setConfirmModal(false);
    setCancelLoading(false);
  };
  const handleQuantityChange = async (
    value: any,
    index: number,
    product: any,
    quantIndex: number
  ) => {
    const temp = { ...product };
    temp.stockCount = value;
    setQuantityError(false);
    if (value == 0 || !value) {
      temp.stockCount = 1;
    }
    setSelectedProduct(temp);
    const newDetails = { ...details };
    if (newDetails.orders && newDetails.orders[index]) {
      const updatedOrder = { ...newDetails.orders[index] };
      // newDetails.orders[index].variants[quantIndex].stockCount = value;
      updatedOrder.variants[quantIndex] = temp;
      newDetails.orders[index] = updatedOrder;
      newDetails.orders[index].routeVehicle[quantIndex] = {
        ...newDetails.orders[index].routeVehicle[quantIndex],
        temp,
      };
    }
    setDetails(newDetails);
  };
  const handleSave = async (
    value: any,
    index: number,
    product: any,
    quantIndex: number
  ) => {
    const temp = { ...product };
    temp.stockCount = value;
    setQuantityError(false);
    if (value == 0 || !value) {
      setQuantityError(true);
    }
    setSelectedProduct(temp);
    const newDetails = { ...details };
    if (newDetails.orders && newDetails.orders[index]) {
      const updatedOrder = { ...newDetails.orders[index] };
      updatedOrder.variants[quantIndex] = temp;
      newDetails.orders[index] = { ...newDetails.orders[index], updatedOrder };
      newDetails.orders[index].routeVehicle[quantIndex] = {
        ...newDetails.orders[index].routeVehicle[quantIndex],
        temp,
      };
    }
    let variants: any = [];
    newDetails.orders[index].variants.map((val: any) => {
      variants.push({
        variant: val.variant,
        stockCount: val.stockCount,
        quantityType: val.quantityType,
        discounts: val.clonedQuantityType.discountAmtEnabled
          ? val.clonedQuantityType
          : [],
      });
    });
    let data: any = {
      variants: variants,
      record: newDetails.orders[index]._id,
      buyer: newDetails.orders[index].customer.reference,
      address: newDetails.orders[index].address.reference,
    };
    setOrderLoading(true);
    let apiService = new APICallService(
      routePlanning.updateOrder,
      data,
      {
        routeId: planInfo.record._id,
        vehicleId: selectedDriver.vehicle.reference,
      },
      '',
      '',
      '',
      OrdersDelivery,
      tempWarehouse?._id || null
    );
    let response: any = await apiService.callAPI();
    if (response) {
      setOrderId(response.queueId);
      setIsOrderPlaced(true);
    } else {
      setOrderLoading(false);
    }
  };
  const handleOnKeyPress = (event: any) => {
    var charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
      event.preventDefault();
      return false;
    }
    return true;
  };
  function ProductPriceWithoutTax({ productVal }: any) {
    const { clonedQuantityType } = productVal;
    const stockCount = productVal.stockCount;
    const amount = clonedQuantityType.amount;
    const priceData = getVariantUnitAndTotalPriceByQuantityType(
      clonedQuantityType,
      stockCount
    );
    const priceContent = (
      <>
        TSh{' '}
        {Method.getGeneralizedAmount(
          calculateTotalCostWithoutTax(stockCount, priceData.unitPrice)
        )}{' '}
        {priceData.unitPrice !== priceData.originalPrice ? (
          <span className="text-decoration-line-through text-gray ms-1">
            TSh{' '}
            {Method.getGeneralizedAmount(
              calculateTotalCostWithoutTax(stockCount, priceData.originalPrice)
            )}
          </span>
        ) : (
          <></>
        )}
      </>
    );
    return <span className="fs-16 fw-600">{priceContent}</span>;
  }
  function getVariantUnitAndTotalPriceByQuantityType(
    quantityTypeObj: any,
    quantity: any = 0
  ) {
    let unitPrice = quantityTypeObj['amount'] || 0;
    let originalPrice = quantityTypeObj['amount'] || 0;
    if (quantityTypeObj['discountAmtEnabled']) {
      unitPrice = quantityTypeObj['discountAmt'] || 0;
    } else if (quantityTypeObj['discountByQuantitiesEnabled']) {
      let availableDiscountRanges =
        quantityTypeObj['discountsByQuantities'] || [];
      if (availableDiscountRanges.length > 0) {
        availableDiscountRanges.sort((a: any, b: any) => a['min'] - b['min']);
        if (quantity < availableDiscountRanges[0]['min']) {
          //consider original price as it is
        } else {
          let { matchedRange, maxRange } = availableDiscountRanges.reduce(
            (p: any, c: any) => {
              if (quantity >= c['min'] && quantity <= c['max']) {
                p.matchedRange = c;
              }
              if (quantity > c['min']) {
                p.maxRange = c;
              }
              return p;
            },
            {
              matchedRange: undefined,
              maxRange: undefined,
            }
          );
          if (matchedRange) {
            unitPrice = matchedRange['discountAmt'] || 0;
          } else {
            unitPrice = maxRange['discountAmt'] || 0;
          }
        }
      } else {
        //consider original price as it is
      }
    }
    return {
      originalPrice,
      unitPrice,
      totalPrice: quantity * unitPrice,
    };
  }
  useEffect(() => {
    const checkOrderStatus = async () => {
      if (isOrderPlaced) {
        let apiService = new APICallService(
          ordersDelivery.checkStatus,
          orderId,
          '',
          '',
          '',
          '',
          OrdersDelivery,
          tempWarehouse?._id || null
        );
        let response = await apiService.callAPI();
        if (response) {
          if (response.orderQueueRecord.status === OrderQueueFailed) {
            setIsOrderPlaced(false);
            error(response.error);
            setOrderLoading(false);
          } else if (response.orderQueueRecord.status === OrderQueueProcessed) {
            setIsOrderPlaced(false);
            success('Order edited successfully');
            setEditingVariantIndex(null);
            setEditingIndex(null);
            await fetchDeliveryPlans(
              planInfo.record._id,
              selectedDriver.vehicle.reference
            );
            setOrderLoading(false);
          } else {
            setTimeout(checkOrderStatus, 1000); // Call again after 1 second
          }
        } else {
          error(response.error);
          setIsOrderPlaced(false);
          setOrderLoading(false);
        }
      }
    };
    if (isOrderPlaced == true) {
      checkOrderStatus();
    }
  }, [isOrderPlaced]);
  const handleDateChange = async (newStartDate: any) => {
    setStartDate(newStartDate);
  };
  const popover = (
    <Popover id="popover-basic">
      <Popover.Body className="p-2 bg-black border-r10px text-white">
        <span>Partial Order cannot be edited</span>
      </Popover.Body>
    </Popover>
  );
  const popoverTigo = (
    <Popover id="popover-basic">
      <Popover.Body className="p-2 bg-black border-r10px text-white">
        <span>Tigo Pesa order cannot be edited</span>
      </Popover.Body>
    </Popover>
  );
  return (
    <>
      <div className="p-9">
        {outForDelivery ? (
          <AddOrderPlan
            show={outForDelivery}
            onHide={async (data: any) => {
              if (data.length) {
                setoutForDelivery(false);
                setFetchLoader(true);
                await fetchDeliveryPlans(
                  planInfo.record._id,
                  selectedDriver.vehicle.reference
                );
                setFetchLoader(false);
              } else {
                setoutForDelivery(false);
              }
            }}
            handleAddOrderToList={handleAddOrderToList}
            loading={loading}
            routeId={planInfo.record._id}
            vehicleId={selectedDriver.vehicle.reference}
            moduleName={OrdersDelivery}
          />
        ) : (
          <></>
        )}
        {confirmModal ? (
          <ConfirmChanges
            show={confirmModal}
            title={'Are you sure you want to remove this item?'}
            save={'Yes'}
            message={''}
            onHide={() => {
              setConfirm(null);
              setConfirmModal(false);
            }}
            removeMsg={
              'This is the last item in the order, removing this item will cancel the order'
            }
            isRemove={!confirm.isRemove && details.orders.length > 1}
            onSave={() => {
              handleRemoveProduct(
                confirm.isRemove,
                confirm.vIndex,
                confirm.index,
                confirm.product
              );
            }}
            loading={cancelLoading}
          />
        ) : (
          <></>
        )}
        <Row className="align-items-center g-md-8 g-6 mb-5">
          <Col xs={12}>
            <Row className="align-items-center g-3">
              <Col xs>
                <h1 className="fs-22 mb-0 fw-bolder">Order List</h1>
              </Col>
              {!fetchLoader && planInfo.record.reviewRemain ? (
                <Col
                  xs={6}
                  className="d-flex justify-content-end align-items-center"
                >
                  {singleVehicle ? (
                    <></>
                  ) : (
                    <>
                      {' '}
                      <div className="min-w-lg-150px me-4">
                        <CustomSelect
                          backgroundColor="white"
                          value={{
                            value: selectedDriver.vehicle.reference,
                            label:
                              selectedDriver.vehicle.name.length > 25
                                ? selectedDriver.vehicle.name.substring(0, 25) +
                                  '...'
                                : selectedDriver.vehicle.name +
                                  '-' +
                                  selectedDriver.vehicle.number,
                            _id: selectedDriver.vehicle.reference,
                            image: selectedDriver.vehicle.image,
                            title: selectedDriver.vehicle.name,
                          }}
                          isDisabled={loading}
                          options={
                            vehicleInfo.length
                              ? vehicleInfo.map((catval: any) => {
                                  return {
                                    value: catval.vehicle.reference,
                                    label:
                                      catval.vehicle.name +
                                      '-' +
                                      catval.vehicle.number,
                                    _id: catval.vehicle.reference,
                                    image: catval.vehicle.image,
                                    title: catval.vehicle.name,
                                    ...catval,
                                  };
                                })
                              : []
                          }
                          onChange={(event: any) => {
                            handleChange(event);
                          }}
                          isMulti={false}
                        />
                      </div>
                    </>
                  )}
                
                  {Method.hasPermission(OrdersDelivery, Edit, currentUser) ? (
                  <>  
                  {                     Method.checkSameDate(Method.getTodayDate("YYYY-MM-DD"),Method.convertDateToFormat(planInfo.
                      record
                      .date,"YYYY-MM-DD"))?<>
                      </>:<>
                      {!showDatePicker? <>
                      <Button
                      variant="primary"
                      className="btn-lg me-2 "
                      onClick={() => {
                        setShowDatePicker(!showDatePicker);
                      }}
                      disabled={orderLoading||dateLoading||
                     Method.checkSameDate(Method.getTodayDate("YYYY-MM-DD"),Method.convertDateToFormat(planInfo.
                      record
                      .date,"YYYY-MM-DD"))
                      }
                    >
                      {dateLoading && (
                        <span
                          className="indicator-progress"
                          style={{
                            display: 'block',
                          }}
                        >
                          Please wait...
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      )}
                      {!dateLoading && <> Change Route Plan Schedule Date</>}
                    </Button>
                    </>:<><div >
        
                    
        <CustomDatePicker
                         className="bg-white min-h-50px text-dark min-w-md-150px min-w-175px w-50px me-2"
                         selected={
                         startDate
                         }
                         onChange={(e: any) =>
                           handleDateChange(e)
                         }
                       
                         startDate={
                           new Date()
                         }
                         minDate={new Date()}
                         dateFormat="dd/MM/yyyy"
                         showFullMonthYearPicker
                         inputTextBG="bg-white"
                       />


                           </div>
                           <Button
                      variant="primary"
                      className="btn-lg me-2 "
                      onClick={() => {
                        changePlanDate()
                      }}
                      disabled={orderLoading||dateLoading||
                     Method.checkSameDate(Method.getTodayDate("YYYY-MM-DD"),Method.convertDateToFormat(planInfo.
                      record
                      .date,"YYYY-MM-DD"))
                      }
                    >
                      {dateLoading && (
                        <span
                          className="indicator-progress"
                          style={{
                            display: 'block',
                          }}
                        >
                          Please wait...
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      )}
                      {!dateLoading && <> Save Changes</>}
                    </Button>
                           </>
                           }</>}
                 
                  <Button
                      variant="primary"
                      className="btn-lg "
                      onClick={() => {
                        completeReview();
                      }}
                      disabled={orderLoading||
                     !Method.checkSameDate(Method.getTodayDate("YYYY-MM-DD"),Method.convertDateToFormat(planInfo.
                      record
                      .date,"YYYY-MM-DD"))
                      }
                    >
                      {loading && (
                        <span
                          className="indicator-progress"
                          style={{
                            display: 'block',
                          }}
                        >
                          Please wait...
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      )}
                      {!loading && <> Complete Review</>}
                    </Button>

                 


                   </>
                  ) : (
                    <></>
                  )}
            
                </Col>
              ) : (
                <></>
              )}
              {details && Object.keys(details).length && (
                <>
                  <Col xs="auto">
                    {Method.hasPermission(OrdersDelivery, Edit, currentUser) ? (
                      <Button
                        variant="primary"
                        className="btn-lg"
                        onClick={() => {
                          setEditingVariantIndex(null);
                          setEditingIndex(null);
                          setoutForDelivery(true);
                        }}
                        disabled={  !Method.checkSameDate(Method.getTodayDate("YYYY-MM-DD"),Method.convertDateToFormat(planInfo.
                          record
                          .date,"YYYY-MM-DD"))}
                      >
                        Add Orders
                      </Button>
                    ) : (
                      <></>
                    )}
                  </Col>
                </>
              )}
            </Row>
          </Col>
        </Row>
        {fetchLoader ? (
          <>
            <div className="d-flex justify-content-center">
              <Loader loading={fetchLoader} />
            </div>
          </>
        ) : (
          <>
            {details && Object.keys(details).length && (
              <Row className="g-7">
                <Col
                  xs={12}
                  className="mb-8"
                >
                  <Row className="g-6">
                    <Col
                      xl={3}
                      md={6}
                      sm={4}
                    >
                      <div className="border-r8px bg-d4e1fc rounded py-4 px-5">
                        <div className="d-flex align-items-center">
                          <div className="fs-22 fw-bolder">
                            {details.record.vehicle.number}
                          </div>
                        </div>
                        <div className="fw-500 fs-16">Vehicle number</div>
                      </div>
                    </Col>
                    <Col
                      xl={3}
                      md={6}
                      sm={4}
                    >
                      {details.record.driver ? (
                        <div className="border-r8px bg-ccebfd rounded py-4 px-5">
                          <div className="d-flex align-items-center">
                            <div className="fs-22 fw-bolder">
                              {details.record.driver.name}
                            </div>
                          </div>
                          <div className="fw-500 fs-16">Driver name</div>
                        </div>
                      ) : (
                        <div className="border-r8px bg-light-danger rounded py-4 px-5">
                          <div className="d-flex align-items-center">
                            <div className="fs-22 fw-bolder  text-danger">
                              Driver not allocated.
                            </div>
                          </div>
                          <div className="fw-500 fs-16">Driver name</div>
                        </div>
                      )}{' '}
                    </Col>
                    <Col
                      xl={3}
                      md={6}
                      sm={4}
                    >
                      <div className="border-r8px bg-ccf3f0 rounded py-4 px-5">
                        <div className="d-flex align-items-center">
                          <div className="fs-22 fw-bolder">
                            {details.orders.length} orders
                          </div>
                        </div>
                        <div className="fw-500 fs-16">Total orders</div>
                      </div>
                    </Col>
                    <Col
                      xl={3}
                      md={6}
                      sm={4}
                    >
                      {/* <div className="border-r8px bg-fbebbc rounded py-4 px-5">
                    <div className="d-flex align-items-center">
                      <div className="fs-22 fw-bolder">
                        TSh {details.record.totalCashCollection}
                      </div>
                    </div>
                    <div className="fw-500 fs-16">
                      Total cash collection
                    </div>
                  </div> */}
                      <div
                        className={clsx(
                          'border-r8px  rounded py-4 px-5',
                          details.record.totalCashCollection
                            ? 'bg-fbebbc'
                            : 'bg-efefef'
                        )}
                      >
                        <div className="d-flex align-items-center">
                          <div className="fs-22 fw-bolder">
                            TSh{' '}
                            {details.record.totalCashCollection
                              ? Method.getGeneralizedAmount(
                                  details.record.totalCashCollection
                                )
                              : Method.getGeneralizedAmount(
                                  details.record.pendingCashCollection
                                )}
                          </div>
                        </div>
                        <div className="fw-500 fs-16">
                          {details.record.totalCashCollection
                            ? 'Total cash collection'
                            : 'Total cash collected to be later'}{' '}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
                {details.orders.length ? (
                  <>
                    {details.orders.map((val: any, index: number) => {
                      return (
                        <Col xs={12}>
                          <Card className="border border-r10px px-0">
                            <Card.Header className="bg-light align-items-center min-h-80px">
                              <Row className="w-100">
                                <Col
                                  lg={12}
                                  className="d-flex"
                                >
                                  <Col lg={6}>
                                    <div className="symbol symbol-50px symbol-circle me-3">
                                      <div className="symbol-label bg-efefef fs-22 fw-bolder text-dark">
                                        {details.orders.length - index}
                                      </div>
                                    </div>
                                    <span className="fs-22 fw-bolder">
                                      {val.refKey} / {val.customer.name}
                                    </span>
                                  </Col>
                                  <Col
                                    lg={6}
                                    className="d-flex justify-content-end"
                                  >
                                    {Method.hasPermission(
                                      OrdersDelivery,
                                      Edit,
                                      currentUser
                                    ) ||
                                    Method.hasPermission(
                                      OrdersDelivery,
                                      Delete,
                                      currentUser
                                    ) ? (
                                      <>{!val.routeVehicle.scheduled?<Button
                                        className="fs-14 fw-500 btn-sm"
                                        variant="danger"
                                        onClick={() => {
                                          setEditingVariantIndex(null);
                                          setEditingIndex(null);
                                          handleRemoveOrderFromList(
                                            val._id,
                                            index
                                          );
                                        }}
                                      >
                                        {loading && index == currentIndex && (
                                          <span
                                            className="indicator-progress"
                                            style={{
                                              display: 'block',
                                            }}
                                          >
                                            Please wait...
                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                          </span>
                                        )}
                                        {(!loading ||
                                          index !== currentIndex) && (
                                          <>Remove</>
                                        )}
                                      </Button>:<></>}</>
                                    ) : (
                                      <></>
                                    )}
                                  </Col>
                                </Col>
                              </Row>
                            </Card.Header>
                            <Card.Body className="py-0">
                              <div className="table-responsive">
                                <table className="table table-rounded table-row-bordered align-middle gs-3 gy-5 mb-0">
                                  <thead>
                                    <tr className="fw-bold fs-16 fw-600 text-dark border-bottom align-middle">
                                      <th className="min-w-300px w-400px">
                                        Product name
                                      </th>
                                      <th className="min-w-125px">
                                        Unit Price
                                      </th>
                                      <th className="min-w-125px">Quantity</th>
                                      <th className="min-w-125px">
                                        Total Amount
                                      </th>
                                      <th className="min-w-125px">Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <>
                                      {val.variants.map(
                                        (variantVal: any, vIndex: number) => {
                                          return (
                                            <>
                                              {variantVal.stockCount > 0 ? (
                                                <>
                                                  <tr>
                                                    <>
                                                      {console.log(
                                                        val.routeVehicle
                                                          .variants[vIndex]
                                                      )}
                                                    </>
                                                    <td>
                                                      <div className="d-flex align-items-center">
                                                        <div className="symbol symbol-65px border me-4">
                                                          <img
                                                            src={
                                                              val.routeVehicle.variants.find(
                                                                (val: any) =>
                                                                  val.variant
                                                                    ._id ==
                                                                  variantVal.variant
                                                              )?.variant
                                                                .media[0].url
                                                            }
                                                            className="object-fit-containe"
                                                            alt=""
                                                          />
                                                        </div>
                                                        <div className="d-flex flex-column ms-5">
                                                          <span className="text-dark fw-600 fs-15 d-block">
                                                            {val.routeVehicle.variants
                                                              .find(
                                                                (val: any) =>
                                                                  val.variant
                                                                    ._id ==
                                                                  variantVal.variant
                                                              )
                                                              ?.variant.title.replace(
                                                                /\s*\)\s*/g,
                                                                ')'
                                                              )}
                                                          </span>
                                                          <span className="text-dark fw-500 fs-15 d-block">
                                                            {
                                                              val.routeVehicle.variants.find(
                                                                (val: any) =>
                                                                  val.variant
                                                                    ._id ==
                                                                  variantVal.variant
                                                              )?.business.name
                                                            }
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </td>
                                                    <td>
                                                      <span className="fs-15 fw-500">
                                                        {variantVal
                                                          .clonedQuantityType
                                                          .discountAmtEnabled ? (
                                                          <>
                                                            {'TSh ' +
                                                              Method.getGeneralizedAmount(
                                                                getVariantUnitAndTotalPriceByQuantityType(
                                                                  variantVal.clonedQuantityType,
                                                                  variantVal
                                                                    .clonedQuantityType
                                                                    .stockCount
                                                                ).unitPrice
                                                              )}{' '}
                                                            <span className="text-decoration-line-through text-gray ms-1">
                                                              TSh{' '}
                                                              {Method.getGeneralizedAmount(
                                                                getVariantUnitAndTotalPriceByQuantityType(
                                                                  variantVal.clonedQuantityType,
                                                                  variantVal
                                                                    .clonedQuantityType
                                                                    .stockCount
                                                                ).originalPrice
                                                              )}
                                                            </span>
                                                          </>
                                                        ) : variantVal
                                                            .clonedQuantityType
                                                            .discountByQuantitiesEnabled ? (
                                                          <>
                                                            {getVariantUnitAndTotalPriceByQuantityType(
                                                              variantVal.clonedQuantityType,
                                                              variantVal.stockCount
                                                            ).unitPrice !==
                                                            getVariantUnitAndTotalPriceByQuantityType(
                                                              variantVal.clonedQuantityType,
                                                              variantVal.stockCount
                                                            ).originalPrice ? (
                                                              <>
                                                                {'TSh ' +
                                                                  Method.getGeneralizedAmount(
                                                                    getVariantUnitAndTotalPriceByQuantityType(
                                                                      variantVal.clonedQuantityType,
                                                                      variantVal.stockCount
                                                                    ).unitPrice
                                                                  )}{' '}
                                                                <span className="text-decoration-line-through text-gray ms-1">
                                                                  TSh{' '}
                                                                  {Method.getGeneralizedAmount(
                                                                    getVariantUnitAndTotalPriceByQuantityType(
                                                                      variantVal.clonedQuantityType,
                                                                      variantVal.stockCount
                                                                    )
                                                                      .originalPrice
                                                                  )}
                                                                </span>
                                                              </>
                                                            ) : (
                                                              'TSh ' +
                                                              Method.getGeneralizedAmount(
                                                                getVariantUnitAndTotalPriceByQuantityType(
                                                                  variantVal.clonedQuantityType,
                                                                  variantVal.stockCount
                                                                ).unitPrice
                                                              )
                                                            )}
                                                          </>
                                                        ) : (
                                                          'TSh ' +
                                                          Method.getGeneralizedAmount(
                                                            getVariantUnitAndTotalPriceByQuantityType(
                                                              variantVal.clonedQuantityType,
                                                              variantVal.stockCount
                                                            ).unitPrice
                                                          )
                                                        )}
                                                      </span>
                                                    </td>
                                                    <td>
                                                      <span className="fs-18 fw-600">
                                                        {variantVal.stockCount}{' '}
                                                        {variantVal.quantityType ===
                                                          CartonWithDozens ||
                                                        variantVal.quantityType ===
                                                          CartonWithPieces
                                                          ? variantVal.stockCount >
                                                            1
                                                            ? 'cartons'
                                                            : 'cartoon'
                                                          : ''}
                                                        {variantVal.quantityType ===
                                                        Dozen
                                                          ? variantVal.stockCount >
                                                            1
                                                            ? 'dozens'
                                                            : 'dozen'
                                                          : ''}
                                                        {variantVal.quantityType ===
                                                        Piece
                                                          ? variantVal.stockCount >
                                                            1
                                                            ? 'pieces'
                                                            : 'piece'
                                                          : ''}
                                                      </span>
                                                    </td>
                                                    <td className="text-left">
                                                      {ProductPriceWithoutTax({
                                                        productVal: variantVal,
                                                      })}
                                                    </td>
                                                    <td>
                                                    {!val.routeVehicle.scheduled?<>
                                                      {editingVariantIndex ==
                                                        vIndex &&
                                                      editingIndex == index ? (
                                                        <>
                                                          <div className="stepperInput d-flex align-items-center">
                                                      
                                                            <Button
                                                              size="sm"
                                                              className="button button--addOnLeft"
                                                              onClick={(
                                                                event
                                                              ) => {
                                                                const currentStockCount =
                                                                  parseInt(
                                                                    variantVal.stockCount ||
                                                                      0
                                                                  );
                                                                if (
                                                                  currentStockCount >
                                                                    0 &&
                                                                  currentStockCount -
                                                                    1 !==
                                                                    0
                                                                ) {
                                                                  handleQuantityChange(
                                                                    currentStockCount -
                                                                      1,
                                                                    index,
                                                                    variantVal,
                                                                    vIndex
                                                                  );
                                                                }
                                                              }}
                                                              disabled={
                                                                orderLoading
                                                              }
                                                            >
                                                              -
                                                            </Button>
                                                            <input
                                                              type="text"
                                                              value={
                                                                variantVal.stockCount ||
                                                                1
                                                              }
                                                              className="input stepperInput__input form-control "
                                                              onChange={(
                                                                event: any
                                                              ) => {
                                                                const currentStockCount =
                                                                  parseInt(
                                                                    event.target
                                                                      .value
                                                                  );
                                                                handleQuantityChange(
                                                                  currentStockCount,
                                                                  index,
                                                                  variantVal,
                                                                  vIndex
                                                                );
                                                              }}
                                                              onKeyPress={(
                                                                event: any
                                                              ) => {
                                                                handleOnKeyPress(
                                                                  event
                                                                );
                                                              }}
                                                              disabled={
                                                                orderLoading
                                                              }
                                                            />
                                                            <Button
                                                              size="sm"
                                                              className="button button--addOnRight"
                                                              onClick={() => {
                                                                const currentStockCount =
                                                                  parseInt(
                                                                    variantVal.stockCount ||
                                                                      0
                                                                  );
                                                                if (
                                                                  currentStockCount >=
                                                                  0
                                                                  //&& currentStockCount + 1 <=
                                                                  //   details.orders[index].variants[0].stockCount
                                                                ) {
                                                                  handleQuantityChange(
                                                                    currentStockCount +
                                                                      1,
                                                                    index,
                                                                    variantVal,
                                                                    vIndex
                                                                  );
                                                                }
                                                              }}
                                                              disabled={
                                                                orderLoading
                                                              }
                                                            >
                                                              +
                                                            </Button>
                                                            <Button
                                                              variant="link"
                                                              className="btn-flush btn-sm mx-2"
                                                              onClick={() => {
                                                                handleSave(
                                                                  variantVal.stockCount,
                                                                  index,
                                                                  variantVal,
                                                                  vIndex
                                                                );
                                                              }}
                                                              disabled={
                                                                orderLoading
                                                              }
                                                            >
                                                              <img
                                                                src={greenCheck}
                                                                alt=""
                                                                height={35}
                                                                width={35}
                                                              />
                                                            </Button>
                                                            <Button
                                                              variant=""
                                                              className="btn-flush btn-sm mx-2"
                                                              onClick={() =>
                                                                handleSaveClick(
                                                                  index
                                                                )
                                                              }
                                                              disabled={
                                                                orderLoading
                                                              }
                                                            >
                                                              <img
                                                                src={CrossIcon}
                                                                alt=""
                                                              />
                                                            </Button>
                                                            <Button
                                                              variant="link"
                                                              className="btn-flush"
                                                              disabled={
                                                                orderLoading
                                                              }
                                                              onClick={() => {
                                                                setConfirm({
                                                                  isRemove:
                                                                    val.variants.filter(
                                                                      (
                                                                        val: any
                                                                      ) =>
                                                                        val.stockCount >
                                                                        0
                                                                    ).length >
                                                                    1,
                                                                  vIndex:
                                                                    vIndex,
                                                                  index: index,
                                                                  product:
                                                                    val.variants.filter(
                                                                      (
                                                                        val: any
                                                                      ) =>
                                                                        val.stockCount >
                                                                        0
                                                                    ).length ==
                                                                    1
                                                                      ? val._id
                                                                      : variantVal,
                                                                });
                                                                setConfirmModal(
                                                                  true
                                                                );
                                                              }}
                                                            >
                                                              <img
                                                                src={DeleteIcon}
                                                                alt=""
                                                              />
                                                            </Button>
                                                          </div>
                                                        </>
                                                      ) : (
                                                        <>
                                                          {val.routeVehicle
                                                            .partialDelivery ||
                                                          val.payment
                                                            .paymentMode ===
                                                            RouteOrderTigoPesa ? (
                                                            <>
                                                              {Method.hasPermission(
                                                                OrdersDelivery,
                                                                Edit,
                                                                currentUser
                                                              ) ? (
                                                                <OverlayTrigger
                                                                  trigger="hover"
                                                                  placement="bottom"
                                                                  overlay={
                                                                    val.payment
                                                                      .paymentMode ===
                                                                    RouteOrderTigoPesa
                                                                      ? popoverTigo
                                                                      : popover
                                                                  }
                                                                >
                                                                  <Button
                                                                    variant="link"
                                                                    className="btn-flush me-2"
                                                                  >
                                                                    <img
                                                                      src={
                                                                        editIcon
                                                                      }
                                                                      alt="Edit"
                                                                    />
                                                                  </Button>
                                                                </OverlayTrigger>
                                                              ) : (
                                                                <></>
                                                              )}
                                                              {Method.hasPermission(
                                                                OrdersDelivery,
                                                                Delete,
                                                                currentUser
                                                              ) ? (
                                                                <OverlayTrigger
                                                                  trigger="hover"
                                                                  placement="bottom"
                                                                  overlay={
                                                                    val.payment
                                                                      .paymentMode ===
                                                                    RouteOrderTigoPesa
                                                                      ? popoverTigo
                                                                      : popover
                                                                  }
                                                                >
                                                                  <Button
                                                                    variant="link"
                                                                    className="btn-flush me-2"
                                                                  >
                                                                    <img
                                                                      src={
                                                                        DeleteIcon
                                                                      }
                                                                      alt=""
                                                                    />
                                                                  </Button>
                                                                </OverlayTrigger>
                                                              ) : (
                                                                <></>
                                                              )}
                                                            </>
                                                          ) : (
                                                            <>
                                                              {Method.hasPermission(
                                                                OrdersDelivery,
                                                                Edit,
                                                                currentUser
                                                              ) ? (
                                                                <Button
                                                                  variant="link"
                                                                  className="btn-flush me-2"
                                                                  onClick={() =>
                                                                    handleEditClick(
                                                                      index,
                                                                      vIndex
                                                                    )
                                                                  }
                                                                  disabled={
                                                                    orderLoading
                                                                  }
                                                                >
                                                                  <img
                                                                    src={
                                                                      editIcon
                                                                    }
                                                                    alt="Edit"
                                                                  />
                                                                </Button>
                                                              ) : (
                                                                <></>
                                                              )}
                                                              {Method.hasPermission(
                                                                OrdersDelivery,
                                                                Delete,
                                                                currentUser
                                                              ) ? (
                                                                <Button
                                                                  variant="link"
                                                                  className="btn-flush"
                                                                  onClick={() => {
                                                                    setConfirm({
                                                                      isRemove:
                                                                        val.variants.filter(
                                                                          (
                                                                            val: any
                                                                          ) =>
                                                                            val.stockCount >
                                                                            0
                                                                        )
                                                                          .length >
                                                                        1,
                                                                      vIndex:
                                                                        vIndex,
                                                                      index:
                                                                        index,
                                                                      product:
                                                                        val.variants.filter(
                                                                          (
                                                                            val: any
                                                                          ) =>
                                                                            val.stockCount >
                                                                            0
                                                                        )
                                                                          .length ==
                                                                        1
                                                                          ? val._id
                                                                          : variantVal,
                                                                    });
                                                                    setConfirmModal(
                                                                      true
                                                                    );
                                                                  }}
                                                                  disabled={
                                                                    orderLoading
                                                                  }
                                                                >
                                                                  <img
                                                                    src={
                                                                      DeleteIcon
                                                                    }
                                                                    alt=""
                                                                  />
                                                                </Button>
                                                              ) : (
                                                                <>
                                                                  </>
                                                              )}
                                                            </>
                                                          )}{' '}
                                                        </>
                                                      )}</>:<> <span className="fs-18 fw-600 text-warning">Scheduled Orders are not editable</span></>}
                                                    </td>
                                                  </tr>
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                            </>
                                          );
                                        }
                                      )}
                                    </>
                                  </tbody>
                                </table>
                              </div>
                            </Card.Body>
                          </Card>
                        </Col>
                      );
                    })}
                  </>
                ) : (
                  <></>
                )}
              </Row>
            )}
          </>
        )}
      </div>
    </>
  );
};
export default ReviewOrderList;
