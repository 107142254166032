import { IListInquires } from "../../types";
export const inquiresJSON = {
  listInquires: ({
    pageNo,
    limit,
    sortKey,
    sortOrder,
    searchTerm,
    warehouse
  }: IListInquires) => {
    let params:any = {
      pageNo: pageNo,
      limit: limit,
      sortKey: sortKey,
      sortOrder: sortOrder,
      needCount: true,
      searchTerm: searchTerm,
    };
    if (warehouse) {
      params = {
        ...params,
        warehouse: warehouse,
      };
    }
  },
};
