/**
 * Calculate the final price after applying discounts and rounding for multiple variants
 * @param {Array} variants - Array of variant objects containing quantity types and discount information
 * @param {Object} params - Parameters for discount calculation
 * @param {number} params.discount - Discount percentage to apply
 * @param {number} params.discountType - Type of discount (1: increase by %, 2: decrease by %)
 * @param {number} params.roundValue - Value to round the final price to
 * @returns {Array} Modified variants with updated prices
 */
export const calculateDiscountedPrice = (
  variants: any,
  { discount, discountType, roundValue }: any
) => {
  // Deep clone the variants array to avoid modifying the original objects
  const modifiedVariants = JSON.parse(JSON.stringify(variants));
  return modifiedVariants.map((variant: any) => {
    if (!variant.quantityTypes) return variant;
    variant.quantityTypes = variant.quantityTypes.map((qType: any) => {
      // Helper function to apply discount and round
      const applyDiscountAndRound = (price: any) => {
        let modifiedPrice = price;
        // Apply discount based on type
        if (discountType === 1) {
          modifiedPrice *= 1 + discount / 100;
        } else if (discountType === 2) {
          modifiedPrice *= 1 - discount / 100;
        }
        // Round to the nearest multiple of roundValue
        if (roundValue > 0) {
          modifiedPrice = Math.round(modifiedPrice / roundValue) * roundValue;
        }
        return modifiedPrice;
      };
      // Process main amount
      qType.amount = applyDiscountAndRound(qType.amount);
      // Handle quantity-based discounts if enabled
      if (qType.discountByQuantitiesEnabled && qType.discountsByQuantities) {
        qType.discountsByQuantities = qType.discountsByQuantities.map(
          (discount: any) => ({
            ...discount,
            discountAmt: applyDiscountAndRound(discount.discountAmt),
          })
        );
      }
      // Handle fixed discount if enabled
      else if (qType.discountAmtEnabled) {
        qType.discountAmt = applyDiscountAndRound(qType.discountAmt);
      }
      return qType;
    });
    return variant;
  });
};
export const calculateDiscountedPriceV2 = (
  variants: any,
  { discount, discountType, roundValue }: any
) => {
  // Deep clone the variants array to avoid modifying the original objects
  const modifiedVariants = JSON.parse(JSON.stringify(variants));
  return modifiedVariants.map((variant: any) => {
    if (!variant.quantityTypes) return variant;
    variant.quantityTypes = variant.quantityTypes.map((qType: any) => {
      // Helper function to apply discount and round
      const applyDiscountAndRound = (price: any) => {
        let modifiedPrice = price;
        // Apply discount based on type
        if (discountType === 1) {
          modifiedPrice *= 1 + discount / 100;
        } else if (discountType === 2) {
          modifiedPrice *= 1 - discount / 100;
        }
        // Round to the nearest multiple of roundValue
        if (roundValue > 0) {
          modifiedPrice = Math.round(modifiedPrice / roundValue) * roundValue;
        }
        return modifiedPrice;
      };
      // Process main amount
      qType.amount = applyDiscountAndRound(qType.amount);
      // Handle quantity-based discounts if enabled
      if (qType.discountByQuantitiesEnabled && qType.discountsByQuantities) {
        qType.discountsByQuantities = qType.discountsByQuantities.map(
          (discount: any) => ({
            ...discount,
            discountAmt: applyDiscountAndRound(discount.discountAmt),
          })
        );
      }
      // Handle fixed discount if enabled
      else if (qType.discountAmtEnabled) {
        qType.discountAmt = applyDiscountAndRound(qType.discountAmt);
      }
      return qType;
    });
    return variant;
  });
};
export const calculateDiscountedPriceV3 = (
  variants: any,
  { discount, discountType, roundValue }: any
) => {
  // Deep clone the variants array to avoid modifying the original objects
  const modifiedVariants = JSON.parse(JSON.stringify(variants));
  return modifiedVariants.map((variant: any) => {
    if (!variant.variant.quantityTypes) return variant;
    variant.variant.quantityTypes = variant.variant.quantityTypes.map(
      (qType: any) => {
        // Helper function to apply discount and round
        const applyDiscountAndRound = (price: any) => {
          let modifiedPrice = price;
          // Apply discount based on type
          if (discountType === 1) {
            modifiedPrice *= 1 + discount / 100;
          } else if (discountType === 2) {
            modifiedPrice *= 1 - discount / 100;
          }
          // Round to the nearest multiple of roundValue
          if (roundValue > 0) {
            modifiedPrice = Math.round(modifiedPrice / roundValue) * roundValue;
          }
          return modifiedPrice;
        };
        // Process main amount
        qType.amount = applyDiscountAndRound(qType.amount);
        // Handle quantity-based discounts if enabled
        if (qType.discountByQuantitiesEnabled && qType.discountsByQuantities) {
          qType.discountsByQuantities = qType.discountsByQuantities.map(
            (discount: any) => ({
              ...discount,
              discountAmt: applyDiscountAndRound(discount.discountAmt),
            })
          );
        }
        // Handle fixed discount if enabled
        else if (qType.discountAmtEnabled) {
          qType.discountAmt = applyDiscountAndRound(qType.discountAmt);
        }
        return qType;
      }
    );
    return variant;
  });
};
