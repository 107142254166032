import { useEffect, useState } from "react";
import { Button, Card, Col, Nav, Row, Tab } from "react-bootstrap";
import { KTSVG } from "../../../_admin/helpers";
import gray from "../../../_admin/assets/media/svg_uTrade/gray_dot.svg";
import green from "../../../_admin/assets/media/svg_uTrade/green_dot.svg";
import ProductRejected from "../../modals/product-rejected";
import {
  Actived,
  AllProduct,
  ApprovedProduct,
  All_Products,
  Deactivated,
  Delete,
  Edit,
  FinanceManager,
  LoadingBayManager,
  PAGE_LIMIT,
  PendingProduct,
  RejectedProduct,
  View,
} from "../../../utils/constants";
import APICallService from "../../../api/apiCallService";
import { manageProduct, master, product } from "../../../api/apiEndPoints";
import Loader from "../../../Global/loader";
import { useNavigate } from "react-router-dom";
import Method from "../../../utils/methods";
import { productJSON } from "../../../api/apiJSON/product";
import { CustomComponentSelect } from "../../custom/Select/CustomComponentSelect";
import Pagination from "../../../Global/pagination";
import { useAuth } from "../auth";
import { CustomSelect } from "../../custom/Select/CustomSelect";
import { listAllProducts, listProductDetails } from "../../../utils/storeString";
import { getKey, removeAllKeys, setKey } from "../../../Global/history";
import AllProductsReport from "../../modals/report/all-products-report";
import { CustomSelectTable } from "../../custom/Select/CustomSelectTable";
import ThreeDotMenu from "../../../_admin/assets/media/svg_uTrade/three-dot.svg";
import { success } from "../../../Global/toast";
import { catalogue, masterToast } from "../../../utils/toast";
import DeleteModal from "../../modals/delete";

import { ElementAnimateUtil } from '../../../_admin/assets/ts/_utils';
import ProductPriceListReport from '../../modals/report/all-products-price-list-report';
import { warehouse as warehouseStoreString } from '../../../utils/storeString';
const AllProducts = () => {
  let tempWarehouse: any = getKey(warehouseStoreString.warehouseRef);
  tempWarehouse = JSON.parse(tempWarehouse);
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [fetchLoader, setFetchLoader] = useState(false);
  const [fetchCategoryLoader, setFetchCategoryLoader] = useState(false);
  const [id, setId] = useState("");
  const [show, setShow] = useState(false);
  const [defaultValue, setDefaultValue] = useState<any>();

  const [dataFetched, setDataFetched] = useState(false);

  const [active, setActive] = useState<any>(
    getKey(listAllProducts.currentTab)
      ? () => {
          let temp: any = getKey(listAllProducts.currentTab);
          return parseInt(temp);
        }
      : currentUser?.role.some(
          (obj: any) =>
            obj.type === FinanceManager || obj.type === LoadingBayManager
        )
      ? ApprovedProduct
      : PendingProduct
  );
  const [filterProducts, setFilterProducts] = useState([
    // {
    //   value: 'Food',
    //   name: 'Food',
    //   label: (
    //     <>
    //       <div className="form-check form-check-custom form-check-solid form-check-lg">
    //         <input
    //           className="form-check-input me-4"
    //           type="checkbox"
    //           value=""
    //           id="Ex1"
    //         />
    //         <div className="symbol symbol-40px symbol-circle border me-2">
    //           <img
    //             src={p1}
    //             className="object-fit-contain"
    //             alt=""
    //           />
    //         </div>
    //         <label
    //           className="form-check-label fs-16 fw-600 text-dark"
    //           htmlFor="Ex1"
    //         >
    //           Food
    //         </label>
    //       </div>
    //     </>
    //   ),
    // },
    // {
    //   value: 'Food',
    //   name: 'Food',
    //   label: (
    //     <>
    //       <div className="form-check form-check-custom form-check-solid form-check-lg">
    //         <input
    //           className="form-check-input me-4"
    //           type="checkbox"
    //           value=""
    //           id="Ex2"
    //         />
    //         <div className="symbol symbol-40px symbol-circle border me-2">
    //           <img
    //             src={p2}
    //             className="object-fit-contain"
    //             alt=""
    //           />
    //         </div>
    //         <label
    //           className="form-check-label fs-16 fw-600 text-dark"
    //           htmlFor="Ex2"
    //         >
    //           Grocery & Gourmet Food
    //         </label>
    //       </div>
    //     </>
    //   ),
    // },
  ]);
  const [products, setProducts] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState<any>(getKey(listAllProducts.page) || 1);
  const [pageLimit, setPageLimit] = useState<any>(
    getKey(listAllProducts.limit) || PAGE_LIMIT
  );
  const [categories, setCategories] = useState<any>(getKey(listAllProducts.filterCategories) || []);
  const [totalCategory, setTotalCategory] = useState<number>(0);
  const [categoryPage, setCategoryPage] = useState(1);
  const [categorySearch, setCategorySearch] = useState('');
  const [rejectedProduct, setRejectedProduct] = useState<any>({});
  const [productState, setProductState] = useState<any>(getKey(listAllProducts.filterState) || AllProduct);
  const [search, setSearch] = useState<string>(getKey(listAllProducts.search)?.toString() || '');
  const productOptions = [
    {
      value: AllProduct,
      name: "All products",
      label: (
        <>
          <span className="fs-16 fw-600 text-black mb-0">All products</span>
        </>
      ),
      title: "All products",
    },
    {
      value: Actived,
      name: "Active",
      label: (
        <>
          <img src={green} width={12} height={12} className="me-3" alt="" />
          <span className="fs-16 fw-600 text-black ">Active</span>
        </>
      ),
      title: "Active",
    },
    {
      value: Deactivated,
      name: "Deactivated",
      label: (
        <>
          <img src={gray} width={12} height={12} className="me-3" alt="" />
          <span className="fs-16 fw-600 text-black">Deactivated</span>
        </>
      ),
      title: "Deactivated",
    },
  ];
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [showDownloadPriceListModal, setShowDownloadPriceListModal] = useState(false);
  const [deleteProductModalOpen, setDeleteProductModalOpen] = useState(false);
  const [error, setError] = useState("");
  const [selectedProduct, setSelectedProduct] = useState<any>();

  const [deleteVariantModalOpen, setDeleteVariantModalOpen] = useState(false);
  const [selectedVariant, setSelectedVariant] = useState<any>();
  const [productId, setProductId] = useState("");
  const [deleteLoader, setDeleteLoader] = useState(false);


  useEffect(() => {
    (async () => {
      setFetchLoader(true);
      if (!Method.hasModulePermission(All_Products, currentUser)) {
        return window.history.back();
      }
      console.log('hii',categories);
      if (getKey(listAllProducts.currentTab)) {
        let activeTemp: any = getKey(listAllProducts.currentTab);
        await fetchProducts(
          page,
          pageLimit,
          productState,
          categories,
          search,
          parseInt(activeTemp)
        );
      } else {
        setActive(
          currentUser?.role.some(
            (obj: any) =>
              obj.type === FinanceManager || obj.type === LoadingBayManager
          )
            ? ApprovedProduct
            : PendingProduct
        );
        await fetchProducts(
          page,
          pageLimit,
          productState,
          categories,
          search,
          active
        );
      }
      setDataFetched(true);
      setFetchLoader(false);
      await fetchCategory(page, PAGE_LIMIT);
      setDefaultValue({
        label: (
          <img
            className="img-fluid"
            width={24}
            height={5}
            src={ThreeDotMenu}
            alt=""
          />
        ),
        value: (
          <img
            className="img-fluid"
            width={24}
            height={5}
            src={ThreeDotMenu}
            alt=""
          />
        ),
      });
    })();
  }, []);

  useEffect(() => {
    if (dataFetched) {
      let initialScrollPosition: any = getKey(listAllProducts.scrollPosition);
      if (initialScrollPosition) {
        ElementAnimateUtil.scrollTo(parseInt(initialScrollPosition));
        setDataFetched(false);
        setKey(listAllProducts.search, '', false);
        setKey(listAllProducts.filterState, AllProduct, false);
        setKey(listAllProducts.filterCategories, [], false);
      }
      removeAllKeys(listProductDetails);
    }
  }, [dataFetched]);


  const fetchCategory = async (page: number, limit: number, search: string = '') => {
    setFetchCategoryLoader(true);
    let params = {
      pageNo: page,
      limit: limit,
      searchTerm: search,
      sortKey: 'title',
      sortOrder: 1,
      needCount: true,
      state: 1,
      categoriesDepth: 1,
    };
    let apiService = new APICallService(master.categoryList,
      params,
      '','','','',All_Products
    );
    let response = await apiService.callAPI();
    if (response.records) {
      response.records = await response.records.map((val: any) => {
        return {
          value: val.title,
          name: val.title,
          id: val._id,
          label: (
            <>
              <div className="symbol symbol-40px symbol-circle border me-2">
                <img src={val.image} className="object-fit-contain" alt="" />
              </div>
              <label
                className="form-check-label fs-16 fw-600 text-dark"
                htmlFor="Ex2"
              >
                {val.title}
              </label>
            </>
          ),
          title: val.title,
        }
      });
      setTotalCategory(response.total);
      let data: any = (prevResults: any) => page === 1 ? response.records : [...prevResults, ...response.records]
      setFilterProducts(data);
    }
    setFetchCategoryLoader(false);
  };
  const onMenuCategoryScrollToBottom = async () => {
    if (!(filterProducts.length === totalCategory)) {
      let tempPage = categoryPage;
      tempPage = tempPage + 1;
      setCategoryPage(tempPage);
      await fetchCategory(tempPage, PAGE_LIMIT, categorySearch);
    }
  };
  const onMenuCategoryClose = async () => {
    setCategorySearch('');
  };
  const onMenuCategoryOpen = async () => {
    setCategoryPage(1);
    await fetchCategory(1,PAGE_LIMIT, categorySearch);
  };
  const handleInputCategoryChange = async (newValue: string) => {
    setCategoryPage(1);
    setCategorySearch(newValue);
    await fetchCategory(1, 0, newValue);
  };
  const fetchProducts = async (
    pageNo: number,
    limit: number,
    state: number,
    categories: any,
    search: string,
    status?: number
  ) => {
    setLoading(true);
    let data:any = {
      pageNo: pageNo,
      limit: limit,
      sortKey: "_createdAt",
      sortOrder: -1,
      searchTerm: search,
      state: state,
      needCount: true,
      status: status,
    };
    if (tempWarehouse?._id) {
      data = {
        ...data,
        warehouse: tempWarehouse?._id || null,
      };
    }
    if (categories?.length > 0) {
      categories.map((val: any, index: number) => {
        data = { ...data, ["categories[" + index + "]"]: val.id };
      });
    }
    let apiService = new APICallService(manageProduct.listProduct, data,'','','','',All_Products,tempWarehouse?._id || null);
    let response = await apiService.callAPI();
    if (response.records) {
      setTotalRecords(response.total);
      setProducts(response.records);
    } else {
      setProducts([]);
    }
    setLoading(false);
  };
  const handleActive = async (event: any) => {
    setActive(event);
    setKey(listAllProducts.currentTab, parseInt(event));
    setKey(listAllProducts.page, 1);
    setPage(1);
    setTotalRecords(0);
    await fetchProducts(1, pageLimit, productState, categories, search, event);
  };
  const handleVariationShow = (variantionData: any, index: number) => {
    let temp: any = [...products];
    temp[index].showVariant = !temp[index].showVariant;
    setProducts(temp);
  };
  const updateProductState = async (
    state: boolean,
    id: string,
    isProduct: boolean,
    productIndex: number,
    variantIndex: number
  ) => {
    let temp = [...products];
    let apiService = new APICallService(
      product.updateProductState,
      productJSON.updateProductState({ activate: !state }),
      {
        id: id,
      },
      '','','',All_Products,tempWarehouse?._id || null
    );
    let response = await apiService.callAPI();
    if (response) {
      temp[productIndex].variants[variantIndex].variant.active = !state;
    }
    if (productState !== AllProduct) {
      temp.splice(productIndex, 1);
    }
    setProducts(temp);
  };
  const handleCategoryFilter = async (event: any) => {
    setCategories(event);
    setKey(listAllProducts.page, 1);
    setPage(1);
    await fetchProducts(1, pageLimit, productState, event, search, active);
  };
  const handleProductStateChange = async (event: any) => {
    setKey(listAllProducts.page, 1);
    setPage(1);
    await fetchProducts(1, pageLimit, event.value, categories, search, active);
    setProductState(event.value);
  };
  const handleSearch = async (value: string) => {
    setSearch(value);
    setKey(listAllProducts.page, 1);
    setPage(1);
    await fetchProducts(1, pageLimit, productState, categories, value, active);
  };
  const rejectModal = (value: any) => {
    setRejectedProduct(value);
    setShowRejectModal(true);
  };
  const handleCurrentPage = async (val: number) => {
    setPage(val);
    setKey(listAllProducts.page, val);
    await fetchProducts(
      val,
      pageLimit,
      productState,
      categories,
      search,
      active
    );
  };
  const handleNextPage = async (val: number) => {
    setPage(val + 1);
    setKey(listAllProducts.page, val + 1);
    await fetchProducts(
      val + 1,
      pageLimit,
      productState,
      categories,
      search,
      active
    );
  };
  const handlePreviousPage = async (val: number) => {
    setPage(val - 1);
    setKey(listAllProducts.page, val - 1);
    await fetchProducts(
      val - 1,
      pageLimit,
      productState,
      categories,
      search,
      active
    );
  };
  const handlePageLimit = async (event: any) => {
    setPage(1);
    await setPageLimit(parseInt(event.target.value));
    setKey(listAllProducts.page, 1);
    setKey(listAllProducts.limit, parseInt(event.target.value));
    await fetchProducts(
      1,
      event.target.value,
      productState,
      categories,
      search,
      active
    );
  };
  const handleViewDetails = async (currentId: string) => {
    let ids: any = [];
    let currentScrollY = window.scrollY;
    setKey(listAllProducts.scrollPosition, currentScrollY);
    setKey(listAllProducts.search, search, false);
    setKey(listAllProducts.filterState, productState, false);
    setKey(listAllProducts.filterCategories, categories, false);
    if (active === PendingProduct) {
      if (products.length) {
        products.map((productVal: any) => {
          productVal.variants.map((variantVal: any) => {
            ids.push(variantVal.variant._id);
          });
        });
      }
      // Check if currentId exists in ids array
      const currentIdIndex = ids.indexOf(currentId);
      if (currentIdIndex !== -1) {
        // Remove currentId from its current position
        ids.splice(currentIdIndex, 1);
        // Add currentId to the start of the array
        ids.unshift(currentId);
      }
      navigate("/all-products/product-details", {
        state: { ids: ids, status: active, moduleName: All_Products},
      });
    } else {
      navigate("/all-products/product-details", {
        state: {id: currentId, moduleName: All_Products},
      });
    }
  };

  const openMenuOnClick = async (id: any) => {
    setId(id);
    setShow(true);
    // navigate('/master/edit-business-certificates', { state: data })
  };
  const onMenuClose = async () => {
    setId("");
    setShow(false);
  };
  const onMenuOpen = async (id: any) => {
    setId(id);
    setShow(true);
  };
  const handleOption = async (event: any, data: any, index: number) => {
    let currentScrollY = window.scrollY;
    setKey(listAllProducts.scrollPosition, currentScrollY);
    setKey(listAllProducts.search, search, false);
    setKey(listAllProducts.filterState, productState, false);
    setKey(listAllProducts.filterCategories, categories, false);
    if (event.value === 1) {
      navigate("/products/edit-product", {
        state: {
          _id: data._id,
          isMaster: false,
          moduleName: All_Products
        },
      });
    } else if (event.value === 2) {
      setDeleteProductModalOpen(true);
      setSelectedProduct(data);
    } else if (event.value === 0) {
    }
  };
  const handleVariantOption = async (event: any, productId: any, data: any, index: number) => {
    let currentScrollY = window.scrollY;
    setKey(listAllProducts.scrollPosition, currentScrollY);
    setKey(listAllProducts.search, search, false);
    setKey(listAllProducts.filterState, productState, false);
    setKey(listAllProducts.filterCategories, categories, false);
    if (event.value === 1) {
      navigate("/products/edit-product-variant", {
        state: {
          _id: data._id,
          isMaster: false,
          moduleName: All_Products
        },
      });
    } else if (event.value === 2) {
      setDeleteVariantModalOpen(true);
      setSelectedVariant(data);
      setProductId(productId);
    } else if (event.value === 0) {
    }
  };

  const handleProductDelete = async () => {
    setDeleteLoader(true);
    let apiService = new APICallService(
      product.deleteProduct,
      selectedProduct?._id,
      "",
      "",
      "",
      true,
      All_Products,
      tempWarehouse?._id || null
    );
    let response = await apiService.callAPI();
    if (response.error) {
      setError(response.error);
    } else {
      setPage(1);
      setKey(listAllProducts.page,1);
      fetchProducts(1, pageLimit, productState, categories, search, active);
      success(catalogue.deleted);
      setDeleteProductModalOpen(false);
    }
    setDeleteLoader(false);
  };

  const handleProductModalHide = () => {
    setDeleteProductModalOpen(false);
    setError("");
  };

  const handleVariantDelete = async () => {
    let variantId = selectedVariant?._id
    setDeleteLoader(true);
    let apiService = new APICallService(
      product.deleteProductVariant,
      "",
      {productId, variantId},
      "",
      "",
      true,
      All_Products,
      tempWarehouse?._id || null
    );
    let response = await apiService.callAPI();
    if (response.error) {
      setError(response.error);
    } else {
      setPage(1);
      setKey(listAllProducts.page,1);
      fetchProducts(1, pageLimit, productState, categories, search, active);
      success(masterToast.deleteVariant);
      setDeleteVariantModalOpen(false);
    }
    setDeleteLoader(false);
  };

  const handleVariantModalHide = () => {
    setDeleteVariantModalOpen(false);
    setError("");
  };

  return (
    <div className="p-9">
      {showRejectModal ? (
        <ProductRejected
          show={showRejectModal}
          onHide={() => setShowRejectModal(false)}
          rejectedProduct={rejectedProduct}
        />
      ) : (
        <></>
      )}
      {showDownloadModal ? (
        <AllProductsReport
          show={showDownloadModal}
          onHide={() => setShowDownloadModal(false)}
          moduleName = {All_Products}
        />
      ) : (
        <></>
      )}
      {showDownloadPriceListModal ? (
        <ProductPriceListReport
          show={showDownloadPriceListModal}
          onHide={() => setShowDownloadPriceListModal(false)}
          moduleName = {All_Products}
        />
      ) : (
        <></>
      )}
      {deleteProductModalOpen && (
        <DeleteModal
          show={deleteProductModalOpen}
          onHide={handleProductModalHide}
          service={selectedProduct}
          handleDelete={handleProductDelete}
          error={error}
          loading={deleteLoader}
          serviceName="product"
          message={`Are you sure you want to delete ${selectedProduct.title} product?`}
          warnMessage={`By continuing, the ${selectedProduct.title} product will
          be removed permanently`}
        />
      )}
      {deleteVariantModalOpen && (
        <DeleteModal
          show={deleteVariantModalOpen}
          onHide={handleVariantModalHide}
          service={selectedVariant}
          handleDelete={handleVariantDelete}
          error={error}
          loading={deleteLoader}
          serviceName="variant"
          message={`Are you sure you want to delete ${selectedVariant.title} variant?`}
          warnMessage={`By continuing, the ${selectedVariant.title} variant will
          be removed permanently`}
        />
      )}
      <Row className="align-items-center">
        <Col
          xs
          className="mb-5"
        >
          <h1 className="fs-22 fw-bolder">All products</h1>
        </Col>
        {currentUser?.role.length === 0 ||
        currentUser?.role.some((obj: any) => obj.type === FinanceManager) ? (
          <Col xs="auto">
            <Button
              size="lg"
              className="mb-5"
              onClick={() => setShowDownloadPriceListModal(true)}
            >
              Download Product Price List
            </Button>
          </Col>
        ) : (
          <></>
        )}
        {currentUser?.role.length === 0 ||
        currentUser?.role.some((obj: any) => obj.type === FinanceManager) ? (
          <Col xs="auto">
            <Button
              size="lg"
              className="mb-5"
              onClick={() => setShowDownloadModal(true)}
            >
              Download report
            </Button>
          </Col>
        ) : (
          <></>
        )}
      
        <Col xs={12}>
          <Card className="bg-light border mb-7">
            <Card.Body className="px-7">
              <Row className="align-items-center">
                <Col xl={4} lg={12} className="mb-xl-0 mb-5">
                  <div className="d-flex align-items-center position-relative me-lg-4">
                    <KTSVG
                      path="/media/icons/duotune/general/gen021.svg"
                      className="svg-icon-3 position-absolute ms-3"
                    />
                    <input
                      type="text"
                      id="kt_filter_search"
                      className="form-control form-control-white min-h-60px form-control-lg ps-10"
                      placeholder="Search by product name…"
                      onChange={(event: any) => {
                        handleSearch(event.target.value.trimStart());
                      }}
                      value={search}
                    />
                  </div>
                </Col>
                <Col xl={8}>
                  <Row className="align-items-center justify-content-xl-end">
                    <Col md="auto" className="mb-md-0 mb-5">
                      <Row className="align-items-center">
                        <Col xs="auto">
                          <label htmlFor="" className="fs-16 fw-500">
                            Filter products
                          </label>
                        </Col>
                        <Col xs>
                          <div className="min-w-lg-250px">
                            <CustomComponentSelect
                              // onChange={(options: any) => {
                              //   if (Array.isArray(options)) {
                              //   }
                              // }}
                              //isDisabled={loading}
                              value = {categories}
                              defaultValue = {categories}
                              hideSelectedOptions={false}
                              isLoading = {fetchCategoryLoader}
                              options={filterProducts}
                              onMenuClose = {() => {
                                onMenuCategoryClose();
                              }}
                              onMenuOpen = {() => {
                                onMenuCategoryOpen();
                              }}
                              onMenuScrollToBottom={onMenuCategoryScrollToBottom}
                              onInputChange={(newValue: any, { action }: any) => {
                                if (action === 'input-change') {
                                  handleInputCategoryChange(newValue);
                                }
                              }}
                              onChange={(event: any) => {
                                handleCategoryFilter(event);
                              }}
                              isMulti={true}
                            />
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    {active === ApprovedProduct ? (
                      <Col md="auto">
                        <div className="min-w-lg-150px">
                          <CustomSelect
                            backgroundColor="white"
                            // default={[
                            //   {
                            //     value: AllProduct,
                            //     name: "All products",
                            //     label: (
                            //       <>
                            //         <span className="fs-16 fw-600 text-black mb-0">
                            //           All products
                            //         </span>
                            //       </>
                            //     ),
                            //     title: "All products",
                            //   },
                            // ]}
                            isDisabled={loading}
                            options={productOptions}
                            default={productOptions.find(option => option?.value === productState) || productOptions[0]}
                            onChange={(event: any) => {
                              handleProductStateChange(event);
                            }}
                            isMulti={false}
                          />
                        </div>
                      </Col>
                    ) : (
                      <></>
                    )}
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      
        {loading ? (
            <div className='w-100 d-flex justify-content-center'>
              <Loader loading={loading} />
            </div>
            ) : (
        <Col xs={12}>
          <div className="custom-tabContainer">
            <Tab.Container defaultActiveKey={active} 
            onSelect={ async ()=>{
              setCategories([]);
              setSearch('');
              setProductState(AllProduct);
            }}>
              <Row className="variant-categories">
                {currentUser?.role.some(
                  (obj: any) =>
                    obj.type === FinanceManager ||
                    obj.type === LoadingBayManager
                ) ? (
                  <></>
                ) : (
                  <Col lg={"auto"} className="mb-6">
                    <div className="bg-light border border-r8px p-3 px-md-6">
                      <Nav variant="pills">
                        <Nav.Item>
                          <Nav.Link
                            eventKey={PendingProduct}
                            onClick={() => {
                              handleActive(PendingProduct);
                            }}
                            active={active === PendingProduct}
                          >
                            Pending{" "}
                            {active === PendingProduct
                              ? totalRecords > 0
                                ? "(" + totalRecords + ")"
                                : ""
                              : ""}
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey={ApprovedProduct}
                            onClick={() => {
                              handleActive(ApprovedProduct);
                            }}
                            active={active === ApprovedProduct}
                          >
                            Approved{" "}
                            {active === ApprovedProduct
                              ? totalRecords > 0
                                ? "(" + totalRecords + ")"
                                : ""
                              : ""}
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey={RejectedProduct}
                            onClick={() => {
                              handleActive(RejectedProduct);
                            }}
                            active={active === RejectedProduct}
                          >
                            Rejected{" "}
                            {active === RejectedProduct
                              ? totalRecords > 0
                                ? "(" + totalRecords + ")"
                                : ""
                              : ""}
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </div>
                  </Col>
                )}
                <Col xs={12}>
                  <Tab.Content>
                    <>
                      <Tab.Pane active={true}>
                        <Card className="border border-r10px">
                          <Card.Body className="p-0">
                            <div className="table-responsive">
                              <table className="table table-rounded table-row-bordered align-middle gs-7 gy-4 mb-0">
                                <thead>
                                  <tr className="fw-bold fs-16 fw-600 text-dark border-bottom h-70px align-middle">
                                    <th className="w-250px">Product name</th>
                                    <th className="min-w-175px">
                                      Business name
                                    </th>
                                    <th className="min-w-175px">
                                      {active === PendingProduct
                                        ? "Added on"
                                        : ""}
                                      {active === ApprovedProduct
                                        ? "Approved on"
                                        : ""}
                                      {active === RejectedProduct
                                        ? "Rejected on"
                                        : ""}
                                    </th>
                                    {active === ApprovedProduct ? (
                                      <th className="min-w-50px ">
                                        Activate / <br /> Deactivate
                                      </th>
                                    ) : null}
                                    <th className="w-225px text-end"></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {loading ? (
                                    <tr>
                                      <td colSpan={4}>
                                        <div className="w-100 d-flex justify-content-center">
                                          <Loader loading={loading} />
                                        </div>
                                      </td>
                                    </tr>
                                  ) : products.length ? (
                                    products.map(
                                      (productVal: any, index: number) => {
                                        return (
                                          <>
                                            <tr>
                                              <td>
                                                <></>
                                                <div className="d-flex align-items-center">
                                                  <div className="symbol symbol-50px border me-5">
                                                    <img
                                                      src={
                                                        productVal.variants[0]
                                                          .variant.media[0].url
                                                      }
                                                      className="object-fit-contain"
                                                      alt=""
                                                    />
                                                  </div>
                                                  <div className="fs-15 fw-600 mw-lg-175px">
                                                    {productVal.title.replace(
                                                      /\s*\)\s*/g,
                                                      ")"
                                                    )}
                                                  </div>
                                                </div>
                                              </td>
                                              <td>
                                                {productVal.variants.length &&
                                                productVal.variants[0].variant[
                                                  "variantType"
                                                ][0] != undefined ? (
                                                  <span className="fs-20 fw-600">
                                                    -
                                                  </span>
                                                ) : (
                                                  <>
                                                    <span className="fs-15 fw-600">
                                                      {
                                                        productVal.variants[0]
                                                          .variant.product
                                                          .business.name
                                                      }
                                                    </span>
                                                  </>
                                                )}
                                              </td>
                                              <td>
                                                {productVal.variants.length &&
                                                productVal.variants[0].variant[
                                                  "variantType"
                                                ][0] != undefined ? (
                                                  <span className="d-block fs-15 fw-600 text-dark">
                                                    -
                                                  </span>
                                                ) : (
                                                  <>
                                                    {active ===
                                                    ApprovedProduct ? (
                                                      <span className="d-block fs-15 fw-600 text-dark">
                                                        {Method.convertDateToDDMMYYYYHOURS(
                                                          productVal.variants[0]
                                                            .variant
                                                            .approvalInfo.date
                                                        )}
                                                      </span>
                                                    ) : (
                                                      <></>
                                                    )}
                                                    {active ===
                                                    PendingProduct ? (
                                                      <span className="d-block fs-15 fw-600 text-dark">
                                                        {Method.convertDateToDDMMYYYYHOURS(
                                                          productVal.variants[0]
                                                            .variant._createdAt
                                                        )}
                                                      </span>
                                                    ) : (
                                                      <></>
                                                    )}
                                                    {active ===
                                                    RejectedProduct ? (
                                                      <span className="d-block fs-15 fw-600 text-dark">
                                                        {Method.convertDateToDDMMYYYYHOURS(
                                                          productVal.variants[0]
                                                            .variant
                                                            .rejectionInfo.date
                                                        )}
                                                      </span>
                                                    ) : (
                                                      <></>
                                                    )}
                                                  </>
                                                )}
                                              </td>
                                              {active === ApprovedProduct ? (
                                                <td>
                                                  {productVal.variants.length &&
                                                  productVal.variants[0]
                                                    .variant[
                                                    "variantType"
                                                  ][0] != undefined ? (
                                                    <span className="fs-20 fw-600">
                                                      -
                                                    </span>
                                                  ) : (
                                                    <label className="form-check form-switch form-check-custom form-check-solid ">
                                                      <input
                                                        className="form-check-input form-check-success w-50px h-30px"
                                                        type="checkbox"
                                                        name="notifications"
                                                        value={
                                                          productVal.variants[0]
                                                            .variant["_id"]
                                                        }
                                                        disabled={currentUser?.role.some(
                                                          (obj: any) =>
                                                            obj.type ===
                                                            LoadingBayManager
                                                        )}
                                                        checked={
                                                          productVal.variants[0]
                                                            .variant.active
                                                        }
                                                        onChange={() => {
                                                          updateProductState(
                                                            productVal
                                                              .variants[0]
                                                              .variant.active,
                                                            productVal
                                                              .variants[0]
                                                              .variant["_id"],
                                                            true,
                                                            index,
                                                            0
                                                          );
                                                        }}
                                                      />
                                                    </label>
                                                  )}
                                                </td>
                                              ) : (
                                                <></>
                                              )}
                                              <td className="text-end">
                                                <div className="d-flex flex-nowrap justify-content-end align-items-center">
                                                  {productVal.variants.length &&
                                                  productVal.variants[0]
                                                    .variant[
                                                    "variantType"
                                                  ][0] != undefined ? (
                                                    <span
                                                      className="fs-16 fw-600 text-gray text-hover-primary cursor-pointer me-3"
                                                      onClick={() => {
                                                        handleVariationShow(
                                                          products.variants,
                                                          index
                                                        );
                                                      }}
                                                    >
                                                      {productVal.showVariant
                                                        ? "Hide"
                                                        : "View"}{" "}
                                                      {
                                                        productVal.variants
                                                          .length
                                                      }{" "}
                                                      variations
                                                    </span>
                                                  ) : (
                                                    <>
                                                      <div className="d-inline-flex flex-nowrap">
                                                      {Method.hasPermission(All_Products, View, currentUser) ? (
                                                        <>
                                                        <Button
                                                          variant="primary"
                                                          className="btn-active-light-primary fs-14 fw-600 me-5"
                                                          onClick={() => {
                                                            // navigate(
                                                            //   '/all-products/product-details',
                                                            //   {
                                                            //     state:
                                                            //       productVal
                                                            //         .variants[0]
                                                            //         .variant[
                                                            //         '_id'
                                                            //       ],
                                                            //   }
                                                            // );
                                                            handleViewDetails(
                                                              productVal
                                                                .variants[0]
                                                                .variant["_id"]
                                                            );
                                                          }}
                                                        >
                                                          View details
                                                        </Button>
                                                          {active ===
                                                          RejectedProduct ? (
                                                            <Button
                                                              variant="light-primary"
                                                              className="me-3 btn-active-light-primary fs-14 fw-600"
                                                              onClick={() => {
                                                                rejectModal(
                                                                  productVal
                                                                    .variants[0]
                                                                );
                                                              }}
                                                            >
                                                              View Reason
                                                            </Button>
                                                          ) : (
                                                            <></>
                                                          )}
                                                        </>
                                                        ) : (
                                                          <></>
                                                        )}
                                                      </div>
                                                    </>
                                                  )}
                                                  {Method.hasPermission(
                                                    All_Products,
                                                    Edit,
                                                    currentUser
                                                  )  ||
                                                  Method.hasPermission(
                                                    All_Products,
                                                    Delete,
                                                    currentUser
                                                  ) ? (
                                                  <CustomSelectTable
                                                    // backgroundColor="#e0e0df"
                                                    marginLeft={'-90px'}
                                                    placeholder={
                                                      <img
                                                        className="me-3"
                                                        width={18}
                                                        height={5}
                                                        src={ThreeDotMenu}
                                                        alt=""
                                                      />
                                                    }
                                                    backgroundColor="white"
                                                    default={defaultValue}
                                                    options={[
                                                      ...(Method.hasPermission(All_Products, Edit, currentUser) 
                                                      ? [{
                                                          label: (
                                                            <button className="btn btn-link fs-14 fw-500 text-black ms-3 p-4">
                                                              Edit details
                                                            </button>
                                                          ),
                                                          value: 1,
                                                        }]
                                                      : []),
                                                      ...(Method.hasPermission(All_Products, Delete, currentUser)
                                                      ? [{
                                                          label: (
                                                            <button className="btn btn-link fs-14 fw-500 text-danger ms-3 p-4">
                                                              Delete details
                                                            </button>
                                                          ),
                                                          value: 2,
                                                        }]
                                                      : []),
                                                    ]}
                                                    show={
                                                      show &&
                                                      productVal._id === id
                                                    }
                                                    onMenuClose={() => {
                                                      onMenuClose();
                                                    }}
                                                    openMenuOnClick={() => {
                                                      openMenuOnClick(
                                                        productVal._id
                                                      );
                                                    }}
                                                    onMenuOpen={() => {
                                                      onMenuOpen(
                                                        productVal._id
                                                      );
                                                    }}
                                                    onChange={(event: any) => {
                                                      handleOption(
                                                        event,
                                                        productVal,
                                                        index
                                                      );
                                                    }}
                                                    isOptionDisabled={(
                                                      option: any
                                                    ) => option.value === 3}
                                                  />
                                                ) : (
                                                  <></>
                                                )}
                                                </div>
                                              </td>
                                            </tr>
                                            {productVal.showVariant ? (
                                              productVal.variants.map(
                                                (
                                                  variantVal: any,
                                                  variantIndex: number
                                                ) => {
                                                  return (
                                                    <>
                                                      <tr>
                                                        <td>
                                                          <div className="d-flex align-items-center">
                                                            <div className="symbol symbol-50px border me-5">
                                                              <img
                                                                src={
                                                                  variantVal
                                                                    .variant
                                                                    .media[0]
                                                                    .url
                                                                }
                                                                className="object-fit-contain"
                                                                alt=""
                                                              />
                                                            </div>
                                                            <div className="fs-15 fw-600 mw-lg-175px">
                                                              {variantVal.variant.title.replace(
                                                                /\s*\)\s*/g,
                                                                ")"
                                                              )}
                                                            </div>
                                                          </div>
                                                        </td>
                                                        <td>
                                                          <span className="fs-15 fw-600">
                                                            {
                                                              variantVal.variant
                                                                .product
                                                                .business.name
                                                            }
                                                          </span>
                                                        </td>
                                                        {active ===
                                                        ApprovedProduct ? (
                                                          <td>
                                                            <span className="d-block fs-15 fw-600 text-dark">
                                                              {Method.convertDateToDDMMYYYYHOURS(
                                                                variantVal
                                                                  .variant
                                                                  .approvalInfo
                                                                  .date
                                                              )}
                                                            </span>
                                                          </td>
                                                        ) : (
                                                          <></>
                                                        )}
                                                        {active ===
                                                        PendingProduct ? (
                                                          <td>
                                                            <span className="d-block fs-15 fw-600 text-dark">
                                                              {Method.convertDateToDDMMYYYYHOURS(
                                                                variantVal
                                                                  .variant
                                                                  ._createdAt
                                                              )}
                                                            </span>
                                                          </td>
                                                        ) : (
                                                          <></>
                                                        )}
                                                        {active ===
                                                        RejectedProduct ? (
                                                          <td>
                                                            <span className="d-block fs-15 fw-600 text-dark">
                                                              {Method.convertDateToDDMMYYYYHOURS(
                                                                variantVal
                                                                  .variant
                                                                  .rejectionInfo
                                                                  .date
                                                              )}
                                                            </span>
                                                          </td>
                                                        ) : (
                                                          <></>
                                                        )}
                                                        {active ===
                                                        ApprovedProduct ? (
                                                          <>
                                                            <td>
                                                              <label className="form-check form-switch form-check-custom form-check-solid ">
                                                                <input
                                                                  className="form-check-input form-check-success w-50px h-30px"
                                                                  type="checkbox"
                                                                  name="notifications"
                                                                  value={
                                                                    variantVal
                                                                      .variant
                                                                      ._id
                                                                  }
                                                                  checked={
                                                                    variantVal
                                                                      .variant
                                                                      .active
                                                                  }
                                                                  disabled={currentUser?.role.some(
                                                                    (
                                                                      obj: any
                                                                    ) =>
                                                                      obj.type ===
                                                                      LoadingBayManager
                                                                  )}
                                                                  onChange={() => {
                                                                    updateProductState(
                                                                      variantVal
                                                                        .variant
                                                                        .active,
                                                                      variantVal
                                                                        .variant
                                                                        ._id,
                                                                      false,
                                                                      index,
                                                                      variantIndex
                                                                    );
                                                                  }}
                                                                />
                                                              </label>
                                                            </td>
                                                          </>
                                                        ) : (
                                                          <></>
                                                        )}
                                                        <td className="text-end">
                                                          <div className="d-flex flex-nowrap justify-content-end align-items-center">
                                                          {Method.hasPermission(All_Products, View, currentUser) ? (
                                                            <>
                                                              <Button
                                                                variant="primary"
                                                                className="me-3 btn-active-light-primary fs-14 fw-600"
                                                                onClick={() => {
                                                                  // navigate(
                                                                  //   '/all-products/product-details',
                                                                  //   {
                                                                  //     state:
                                                                  //       variantVal
                                                                  //         .variant
                                                                  //         ._id,
                                                                  //   }
                                                                  // );
                                                                  handleViewDetails(
                                                                    variantVal
                                                                      .variant[
                                                                      "_id"
                                                                    ]
                                                                  );
                                                                }}
                                                              >
                                                                View details
                                                              </Button>
                                                              {active ===
                                                              RejectedProduct ? (
                                                                <Button
                                                                  variant="light-primary"
                                                                  className="me-3 btn-active-light-primary fs-14 fw-600"
                                                                  onClick={() => {
                                                                    rejectModal(
                                                                      variantVal
                                                                    );
                                                                  }}
                                                                >
                                                                  View Reason
                                                                </Button>
                                                              ) : (
                                                                <></>
                                                              )}
                                                            </>
                                                            ) : (
                                                              <></>
                                                            )}
                                                            {Method.hasPermission(
                                                              All_Products,
                                                              Edit,
                                                              currentUser
                                                            )  ||
                                                            Method.hasPermission(
                                                              All_Products,
                                                              Delete,
                                                              currentUser
                                                            ) ? (
                                                              <CustomSelectTable
                                                                // backgroundColor="#e0e0df"
                                                                marginLeft={'-90px'}
                                                                placeholder={
                                                                  <img
                                                                    className="me-3"
                                                                    width={18}
                                                                    height={5}
                                                                    src={
                                                                      ThreeDotMenu
                                                                    }
                                                                    alt=""
                                                                  />
                                                                }
                                                                backgroundColor="white"
                                                                default={
                                                                  defaultValue
                                                                }
                                                                options={[
                                                                  ...(Method.hasPermission(All_Products, Edit, currentUser) 
                                                                  ? [{
                                                                      label: (
                                                                        <button className="btn btn-link fs-14 fw-500 text-black ms-3 p-4">
                                                                          Edit details
                                                                        </button>
                                                                      ),
                                                                      value: 1,
                                                                    }]
                                                                  : []),
                                                                  ...(Method.hasPermission(All_Products, Delete, currentUser)
                                                                  ? [{
                                                                      label: (
                                                                        <button className="btn btn-link fs-14 fw-500 text-danger ms-3 p-4">
                                                                          Delete details
                                                                        </button>
                                                                      ),
                                                                      value: 2,
                                                                    }]
                                                                  : []),
                                                                ]}
                                                                show={
                                                                  show &&
                                                                  variantVal
                                                                    .variant
                                                                    ._id === id
                                                                }
                                                                onMenuClose={() => {
                                                                  onMenuClose();
                                                                }}
                                                                openMenuOnClick={() => {
                                                                  openMenuOnClick(
                                                                    variantVal
                                                                      .variant._id
                                                                  );
                                                                }}
                                                                onMenuOpen={() => {
                                                                  onMenuOpen(
                                                                    variantVal
                                                                      .variant._id
                                                                  );
                                                                }}
                                                                onChange={(
                                                                  event: any
                                                                ) => {
                                                                  handleVariantOption(
                                                                    event,
                                                                    productVal._id,
                                                                    variantVal.variant,
                                                                    index,
                                                                  );
                                                                }}
                                                                isOptionDisabled={(
                                                                  option: any
                                                                ) =>
                                                                  option.value ===
                                                                  3
                                                                }
                                                              />
                                                            ) : (
                                                              <></>
                                                            )}
                                                          </div>
                                                        </td>
                                                      </tr>
                                                    </>
                                                  );
                                                }
                                              )
                                            ) : (
                                              <></>
                                            )}
                                          </>
                                        );
                                      }
                                    )
                                  ) : (
                                    <tr>
                                      <td colSpan={4}>
                                        <div className="w-100 fs-15 fw-bold d-flex justify-content-center">
                                          No Data found
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </Card.Body>
                        </Card>
                        {!loading ? (
                          <>
                            {products.length ? (
                              <Pagination
                                totalRecords={totalRecords}
                                currentPage={page}
                                handleCurrentPage={(event: any) => {
                                  handleCurrentPage(event);
                                }}
                                handleNextPage={(event: any) => {
                                  handleNextPage(event);
                                }}
                                handlePreviousPage={(event: any) => {
                                  handlePreviousPage(event);
                                }}
                                pageLimit={pageLimit}
                                handlePageLimit={(event: any) => {
                                  handlePageLimit(event);
                                }}
                              />
                            ) : (
                              <></>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </Tab.Pane>
                    </>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </Col>
            )}
      </Row>
    </div>
  );
};
export default AllProducts;
