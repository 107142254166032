import { Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { IMAGES } from '../../../utils/dummyJSON';
import ViewRequestedGoodsProducts from '../../modals/view-requested-goods-products';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../auth';
import Method from '../../../utils/methods';
import Loader from '../../../Global/loader';
import blueCheck from '../../../_admin/assets/media/svg_uTrade/blue-check.svg';
import unChecked from '../../../_admin/assets/media/svg_uTrade/unchecked.svg';
import {
  AirtelMoney,
  AllCustomers,
  BankPayment,
  CartonWithDozens,
  CartonWithPieces,
  CRDBBank,
  Dozen,
  DTBBank,
  HaloPesa,
  NMBBank,
  OrderCash,
  OrderMPesa,
  OrderTigoPesa,
  Piece,
  ReceivableReports,
  RouteOrderCash,
  RouteOrderTigoPesa,
  StanbicBank,
  TigoPesaPayment,
} from '../../../utils/constants';
import clsx from 'clsx';
import { buyer, seller } from '../../../api/apiEndPoints';
import { sellerJSON } from '../../../api/apiJSON/seller';
import APICallService from '../../../api/apiCallService';
import Validations from '../../../utils/validations';
import { warehouse as warehouseStoreString } from '../../../utils/storeString';
import { getKey } from '../../../Global/history';

const CollectPayment = () => {
  let tempWarehouse: any = getKey(warehouseStoreString.warehouseRef);
  tempWarehouse = JSON.parse(tempWarehouse);
  const { state }: any = useLocation();
  const { currentUser } = useAuth();
  const [show, setShow] = useState(false);
  const [viewProducts, setViewProducts] = useState(false);
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState<any>();
  const [password, setPassword] = useState('');
  const [valid, setValid] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [goodsDetails, setGoodDetails] = useState<any>([]);
  const [totalAmount, setTotalAmount] = useState<any>([]);
  const [paymentMethod, setPaymentMethod] = useState(BankPayment);
  const [paymentRef, setPaymentRef] = useState('');
  const [validation, setValidation] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  useEffect(() => {
    (async () => {
      setFetchLoading(true);
      let temp = state.data;
      let validation: any = [];
      if (temp.length) {
        temp = temp.map((val: any) => {
          validation.push(false);
          return { ...val, collectedCharge: val.payment.remainingCharge };
        });
      }
      state.data = temp;
      setValidation(validation);
      await setDetails(state);
      setFetchLoading(false);
    })();
  }, []);
  useEffect(() => {
    let temp = { ...details };
    if (temp.data && temp.data.length) {
      // Use reduce to calculate the sum of stockCount by quantityType
      const sumByQuantityType = temp.data.reduce((result: any, item: any) => {
        item.totalQuantities.forEach((quantityItem: any) => {
          const quantityType = quantityItem.quantityType;
          const stockCount = quantityItem.stockCount;
          // If the quantityType doesn't exist in the result, initialize it with stockCount, otherwise add to the existing value.
          result[quantityType] = (result[quantityType] || 0) + stockCount;
        });
        return result;
      }, {});
      const totalAmountTemp = temp.data.reduce((p: any, c: any) => {
        return p + parseInt(c.payment.totalCharge);
      }, 0);
      const sumArray = Object.keys(sumByQuantityType).map((quantityType) => ({
        quantityType: parseInt(quantityType),
        stockCount: sumByQuantityType[quantityType],
      }));
      setTotalAmount(totalAmountTemp);
      setGoodDetails(sumArray);
    }
  }, [details]);
  const handleCollectPayment = async () => {
    let allFalse = Object.values(validation).every((el) => el === false);
    if (allFalse) {
      setLoading(true);
      let params: any = {
        paymentMode: paymentMethod,
      };
      let orders: any = [];
      let temp = { ...details };
      temp.data.map((val: any) => {
        orders.push({ _id: val._id, amount: val.collectedCharge });
      });
      params = {
        ...params,
        orders: orders,
      };
      let apiService = new APICallService(buyer.completePayouts, params,'','','','',ReceivableReports,tempWarehouse?._id || null);
      let response = await apiService.callAPI();
      if (response) {
        window.history.back();
      }
      setLoading(false);
    }
  };
  const handleChange = (event: any, index: number) => {
    let temp = { ...details };
    let tempValidation: any = [...validation];
    if (event.target.value && parseInt(event.target.value) !== 0) {
      tempValidation[index] = false;
      if (event.target.value > temp.data[index].payment.remainingCharge) {
        tempValidation[index] = true;
      }
    } else {
      tempValidation[index] = true;
    }
    temp.data[index].collectedCharge = event.target.value;
    setValidation(tempValidation);
    setDetails(temp);
  };
  const handleOnKeyPress = async (event: any) => {
    // Allow only numbers
    const key = event.key;
    let validations = await Validations.allowOnlyNumbers(key);
    if (!validations) {
      event.preventDefault();
      return false;
    }
    return true;
  };
  return (
    <>
      <div className="p-9">
        {selectedProducts.length && viewProducts ? (
          <ViewRequestedGoodsProducts
            show={viewProducts}
            onHide={() => {
              setSelectedProducts([]);
              setViewProducts(false);
            }}
            products={selectedProducts}
          />
        ) : (
          <></>
        )}
        {fetchLoading ? (
          <>
            <Loader loading={fetchLoading} />
          </>
        ) : (
          <>
            {details && Object.keys(details).length ? (
              <>
                <>
                  <Row className="d-flex justify-content-between">
                    <Col
                      md="auto"
                      className="ms-2 "
                    >
                      <h1 className="fs-22 fw-bolder mb-7">
                        Collect a payment of {details.data.length} orders
                      </h1>
                    </Col>
                    <Col
                      md="auto"
                      className="ms-2 "
                    >
                      <h1 className="fs-22 fw-bolder mb-7">
                        Total Receiving amount: TSh{' '}
                        {Method.getGeneralizedAmount(
                          details.data.reduce((p: any, c: any) => {
                            return p + c.payment.remainingCharge;
                          }, 0)
                        )}
                      </h1>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <Card className="border border-r10px bg-f9f9f9">
                        <Card.Body className="p-0">
                          <div className="table-responsive">
                            <table className="table table-row-bordered datatable align-middle gs-7 gy-4 my-0">
                              <thead>
                                <tr className="fs-16 fw-600 border-bottom-1">
                                  <th className="min-w-200px">
                                    Order Date & ID
                                  </th>
                                  <th className="min-w-150px">Customer Name</th>
                                  <th className="w-150px">Total Amount</th>
                                  <th className="w-250px">Paid Amount</th>
                                  <th className="w-350px">Remaining Amount</th>
                                  <th className="w-350px">Receiving Amount</th>
                                </tr>
                              </thead>
                              <tbody className="fs-15 fw-600">
                                {details.data.map(
                                  (paymentVal: any, index: number) => {
                                    return (
                                      <tr>
                                        <td>
                                          <span className="text-dark d-block">
                                            {Method.convertDateToDDMMYYYYHHMM(
                                              paymentVal._createdAt,
                                              '-'
                                            )}
                                          </span>
                                          <span className="text-gray d-block">
                                            {paymentVal.refKey}
                                          </span>
                                        </td>
                                        <td>{paymentVal.customer.name} </td>
                                        <td>
                                          {'TSh ' +
                                            Method.getGeneralizedAmount(
                                              paymentVal.payment.totalCharge
                                            )}
                                        </td>
                                        <td>
                                          {'TSh ' +
                                            Method.getGeneralizedAmount(
                                              paymentVal.payment.receivedCharge
                                            )}
                                        </td>
                                        <td>
                                          {'TSh ' +
                                            Method.getGeneralizedAmount(
                                              paymentVal.payment.remainingCharge
                                            )}
                                        </td>
                                        <td>
                                          <Form.Control
                                            className={clsx(
                                              'form-control-custom bg-white',
                                              validation[index]
                                                ? 'border-danger'
                                                : ''
                                            )}
                                            value={paymentVal.collectedCharge}
                                            onChange={(event: any) => {
                                              handleChange(event, index);
                                            }}
                                            onKeyPress={(event: any) => {
                                              handleOnKeyPress(event);
                                            }}
                                          ></Form.Control>
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                              </tbody>
                            </table>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col
                      lg={4}
                      className="mt-4"
                    >
                      <Card className="card-stretch bg-light border border-r10px">
                        <Card.Header className="border-bottom-0">
                          <Card.Title className="fs-22 fw-bolder">
                            Payment Mode
                          </Card.Title>
                        </Card.Header>
                        <Card.Body className="pt-0 pb-5 border-bottom">
                          <Row className="g-4">
                            <Col xs={12}>
                              <div className="d-flex justify-content align-items-center">
                                <span className="d-flex justify-content-end align-items-center">
                                  <span className="symbol symbol-circle symbol-40px me-2">
                                    <img
                                      src={
                                        paymentMethod === OrderCash
                                          ? blueCheck
                                          : unChecked
                                      }
                                      alt=""
                                      onClick={() => {
                                        setPaymentMethod(OrderCash);
                                      }}
                                    />{' '}
                                  </span>
                                </span>
                                <span className="fs-20 fw-600">
                                  Paid via cash
                                </span>
                              </div>
                            </Col>
                            <Col xs={12}>
                              <div className="d-flex justify-content align-items-center">
                                <span className="d-flex justify-content-end align-items-center">
                                  <span className="symbol symbol-circle symbol-40px me-2">
                                    <img
                                      src={
                                        paymentMethod === OrderTigoPesa
                                          ? blueCheck
                                          : unChecked
                                      }
                                      alt=""
                                      onClick={() => {
                                        setPaymentMethod(OrderTigoPesa);
                                      }}
                                    />{' '}
                                  </span>
                                </span>
                                <span className="fs-20 fw-600">
                                  Paid via Tigo-Pesa
                                </span>
                              </div>
                            </Col>
                            <Col xs={12}>
                              <div className="d-flex justify-content align-items-center">
                                <span className="d-flex justify-content-end align-items-center">
                                  <span className="symbol symbol-circle symbol-40px me-2">
                                    <img
                                      src={
                                        paymentMethod === OrderMPesa
                                          ? blueCheck
                                          : unChecked
                                      }
                                      alt=""
                                      onClick={() => {
                                        setPaymentMethod(OrderMPesa);
                                      }}
                                    />{' '}
                                  </span>
                                </span>
                                <span className="fs-20 fw-600">
                                  Paid via MPesa
                                </span>
                              </div>
                            </Col>
                            <Col xs={12}>
                              <div className="d-flex justify-content align-items-center">
                                <span className="d-flex justify-content-end align-items-center">
                                  <span className="symbol symbol-circle symbol-40px me-2">
                                    <img
                                      src={
                                        paymentMethod === DTBBank
                                          ? blueCheck
                                          : unChecked
                                      }
                                      alt=""
                                      onClick={() => {
                                        setPaymentMethod(DTBBank);
                                      }}
                                    />{' '}
                                  </span>
                                </span>
                                <span className="fs-20 fw-600">
                                  Paid via DTB Bank
                                </span>
                              </div>
                            </Col>
                            <Col xs={12}>
                              <div className="d-flex justify-content align-items-center">
                                <span className="d-flex justify-content-end align-items-center">
                                  <span className="symbol symbol-circle symbol-40px me-2">
                                    <img
                                      src={
                                        paymentMethod === CRDBBank
                                          ? blueCheck
                                          : unChecked
                                      }
                                      alt=""
                                      onClick={() => {
                                        setPaymentMethod(CRDBBank);
                                      }}
                                    />{' '}
                                  </span>
                                </span>
                                <span className="fs-20 fw-600">
                                  Paid via CRDB Bank
                                </span>
                              </div>
                            </Col>
                            <Col xs={12}>
                              <div className="d-flex justify-content align-items-center">
                                <span className="d-flex justify-content-end align-items-center">
                                  <span className="symbol symbol-circle symbol-40px me-2">
                                    <img
                                      src={
                                        paymentMethod === NMBBank
                                          ? blueCheck
                                          : unChecked
                                      }
                                      alt=""
                                      onClick={() => {
                                        setPaymentMethod(NMBBank);
                                      }}
                                    />{' '}
                                  </span>
                                </span>
                                <span className="fs-20 fw-600">
                                  Paid via NMB Bank
                                </span>
                              </div>
                            </Col>
                            <Col xs={12}>
                              <div className="d-flex justify-content align-items-center">
                                <span className="d-flex justify-content-end align-items-center">
                                  <span className="symbol symbol-circle symbol-40px me-2">
                                    <img
                                      src={
                                        paymentMethod === StanbicBank
                                          ? blueCheck
                                          : unChecked
                                      }
                                      alt=""
                                      onClick={() => {
                                        setPaymentMethod(StanbicBank);
                                      }}
                                    />{' '}
                                  </span>
                                </span>
                                <span className="fs-20 fw-600">
                                  Paid via Stanbic Bank
                                </span>
                              </div>
                            </Col>
                            <Col xs={12}>
                              <div className="d-flex justify-content align-items-center">
                                <span className="d-flex justify-content-end align-items-center">
                                  <span className="symbol symbol-circle symbol-40px me-2">
                                    <img
                                      src={
                                        paymentMethod === AirtelMoney
                                          ? blueCheck
                                          : unChecked
                                      }
                                      alt=""
                                      onClick={() => {
                                        setPaymentMethod(AirtelMoney);
                                      }}
                                    />{' '}
                                  </span>
                                </span>
                                <span className="fs-20 fw-600">
                                  Paid via Airtel Money
                                </span>
                              </div>
                            </Col>{' '}
                            <Col xs={12}>
                              <div className="d-flex justify-content align-items-center">
                                <span className="d-flex justify-content-end align-items-center">
                                  <span className="symbol symbol-circle symbol-40px me-2">
                                    <img
                                      src={
                                        paymentMethod === HaloPesa
                                          ? blueCheck
                                          : unChecked
                                      }
                                      alt=""
                                      onClick={() => {
                                        setPaymentMethod(HaloPesa);
                                      }}
                                    />{' '}
                                  </span>
                                </span>
                                <span className="fs-20 fw-600">
                                  Paid via Halo Pesa
                                </span>
                              </div>
                            </Col>{' '}
                          </Row>
                        </Card.Body>
                        <Card.Body className="align-items-center">
                          <div className="d-block">
                            <Button
                              size="lg"
                              className="w-100 min-h-60px"
                              disabled={loading}
                              onClick={() => {
                                handleCollectPayment();
                              }}
                            >
                              {!loading && (
                                <span className="indicator-label">
                                  Mark as paid
                                </span>
                              )}
                              {loading && (
                                <span
                                  className="indicator-progress"
                                  style={{ display: 'block' }}
                                >
                                  Please wait...
                                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                              )}
                            </Button>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </>
              </>
            ) : (
              <></>
            )}
          </>
        )}
      </div>
    </>
  );
};
export default CollectPayment;
