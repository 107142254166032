import {
  IListOrders,
  IAddNewOrder,
  IUpdateOrderStatus,
  IVariantOrders,
  IReturnInform,
  IReturnList,
  IReturnStockAdjust,
} from "../../types";
export const orderDeliveryJSON = {
  addNewOrder: ({
    buyer,
    variants,
    address,
    discounts,
    paymentMode,
    instantOrder,
  }: IAddNewOrder) => {
    let temp: any = [];
    variants.map((val: any) => {
      temp.push({
        variant: val._id,
        quantityType: val.quantityTypes[0].type,
        stockCount: val.quantityTypes[0].stockCount,
      });
    });
    let returnData: any = {
      buyer: buyer,
      variants: temp,
      address: address,
      discounts: discounts,
      instantOrder: instantOrder,
    };
    if (instantOrder) {
      returnData = {
        ...returnData,
        paymentMode: paymentMode,
      };
    }
    return returnData;
  },
  editOrder: ({
    buyer,
    variants,
    address,
    discounts,
    record,
  }: IAddNewOrder) => {
    let temp: any = [];
    variants.map((val: any) => {
      temp.push({
        variant: val.variant._id,
        quantityType: val.quantityType,
        stockCount: val.stockCount,
      });
    });
    return {
      buyer: buyer,
      variants: temp,
      address: address,
      discounts: discounts,
      record: record ? record : "",
    };
  },
  updateOrderStatus: ({ status }: IUpdateOrderStatus) => {
    return {
      status: status,
    };
  },
  listInventoryTransaction: ({
    limit,
    sortKey,
    sortOrder,
    fromDate,
    toDate,
    pageNo,
    status,
  }: IListOrders) => {
    let data: any = {
      limit: limit,
      sortKey: sortKey,
      sortOrder: sortOrder,
      needCount: pageNo === 1,
      pageNo: pageNo,
    };
    if (fromDate && toDate) {
      data = { ...data, fromDate: fromDate, toDate: toDate };
    }
    if (status) {
      data = { ...data, status: status };
    }
    return data;
  },
  variantOrders: ({ variant }: IVariantOrders) => {
    let data: any = {};
    if (variant) {
      data = { ...data, variant: variant };
    }
    return data;
  },
  returnInform: ({ variants, route, vehicle }: IReturnInform) => {
    return {
      variants: variants,
      route: route,
      vehicle: vehicle,
    };
  },
  returnList: ({ route, vehicle, warehouse }: IReturnList) => {
    let params: any = {
      route: route,
      vehicle: vehicle,
    };
    if (warehouse) {
      params.warehouse = warehouse;
    }
    return params;
  },
  returnAdjust: ({ variants, route, vehicle }: IReturnStockAdjust) => {
    return {
      variants: variants,
      route: route,
      vehicle: vehicle,
    };
  },
};
