import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import verify from "../../../../_admin/assets/media/svg_uTrade/verified.svg";
import { KTSVG } from "../../../../_admin/helpers";
import Loader from "../../../../Global/loader";
import Method from "../../../../utils/methods";
import {
  Add,
  AdvertisementCompleted,
  AdvertisementPaid,
  AdvertisementUnpaid,
  Advertising,
  Cash,
  Edit,
  View,
} from "../../../../utils/constants";
import { useEffect, useState } from "react";
import { advertisements } from "../../../../api/apiEndPoints";
import APICallService from "../../../../api/apiCallService";
import { advertisementJSON } from "../../../../api/apiJSON/advertisement";
import Pagination from "../../../../Global/pagination";
import { getKey, setKey } from "../../../../Global/history";
import { listAdvertisement } from "../../../../utils/storeString";
import { useAuth } from '../../auth';
import { warehouse as warehouseStoreString } from '../../../../utils/storeString';
const Completed = (props: any) => {
  let tempWarehouse: any = getKey(warehouseStoreString.warehouseRef);
  tempWarehouse = JSON.parse(tempWarehouse);
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [advertisment, setAdvertisement] = useState(props.advertisement);
  const handlePay = async (advertisementId: string, index: number) => {
    setLoading(true);
    let temp = [...advertisment];
    let apiService = new APICallService(
      advertisements.payment,
      advertisementJSON.payAdvertisement({ paymentMethod: Cash }),
      {
        id: advertisementId,
      },
      '',
      '',
      '',
      Advertising,
      tempWarehouse?._id || null
    );
    let response: any = await apiService.callAPI();
    if (response) {
      temp[index].payment = {
        status: AdvertisementPaid,
        paymentMethod: Cash,
        completedByAdmin: true,
      };
    }
    setAdvertisement(temp);
    setLoading(false);
  };
  useEffect(() => {
    setAdvertisement(props.advertisement);
  }, [props.advertisement]);

  return (
    <>
      {/* begin::Search */}
      {/* <div className="position-relative my-1">
        <KTSVG
          path="/media/icons/duotune/general/gen021.svg"
          className="svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ps-13"
        />
        <input
          type="text"
          className="form-control form-control-custom borde-r8px bg-light w-375px ps-11"
          name="Search Team"
          value={props.search}
          onChange={(event: any) => {
            props.handleSearch(event, AdvertisementCompleted);
          }}
          placeholder="Search by seller name…"
        />
      </div> */}
      {/* end::Search */}
      <div className="table-responsive">
        <table className="table table-rounded table-row-bordered align-middle gy-4 mb-0">
          <thead>
            <tr className="fs-16 fw-600 text-dark h-70px align-middle">
              <th className="w-115px">Banner</th>
              <th className="min-w-150px">Seller</th>
              <th className="min-w-150px">Ended on</th>
              <th className="min-w-75px">Impression</th>
              <th className="min-w-75px">Clicks</th>
              <th className="min-w-150px">Payment status</th>
              <th className="w-135px"></th>
            </tr>
          </thead>
          <tbody>
            {props.fetchLoading ? (
              <>
                <tr>
                  <td colSpan={7}>
                    <div className="d-flex justify-content-center">
                      <Loader loading={props.fetchLoading} />
                    </div>
                  </td>{" "}
                </tr>
              </>
            ) : (
              <>
                {advertisment.length ? (
                  <>
                    {advertisment.map((advertiseVal: any, index: number) => {
                      return (
                        <>
                          <tr>
                            <td>
                              <img
                                src={advertiseVal.image}
                                className="img-fluid mw-87px mh-50px border-r6px object-fit-contain"
                                alt=""
                              />
                            </td>
                            <td>
                              <span className="fs-15 fw-500">
                                {advertiseVal.business.ownerName}
                              </span>
                            </td>
                            <td>
                              <span className="fs-15 fw-500">
                                {" "}
                                {Method.convertDateToDDMMYYYY(
                                  advertiseVal.endDate
                                )}
                              </span>
                            </td>
                            <td>
                              <span className="fs-20 fw-600">
                                {advertiseVal.impressions > 0
                                  ? advertiseVal.impressions
                                  : "-"}
                              </span>
                            </td>
                            <td>
                              <span className="fs-20 fw-600">
                                {" "}
                                {advertiseVal.clicks > 0
                                  ? advertiseVal.clicks
                                  : "-"}
                              </span>
                            </td>
                            <td>
                              {advertiseVal.payment.status ===
                              AdvertisementUnpaid ? (
                                <>
                                {Method.hasPermission(Advertising, Add, currentUser) ? (
                                <Button
                                  type="button"
                                  variant="light-primary"
                                    className="btn-table"
                                  onClick={() => {
                                    handlePay(advertiseVal._id, index);
                                  }}
                                >
                                  {!loading && (
                                    <span className="indicator-label fs-16 fw-bolder">
                                      Mark as paid
                                    </span>
                                  )}
                                  {loading && (
                                    <span
                                      className="indicator-progress indicator-label fs-16 fw-600"
                                      style={{ display: "block" }}
                                    >
                                      Please wait...{" "}
                                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                  )}{" "}
                                </Button>
                                ) : (
                                  <></>
                                )}
                                </>
                              ) : advertiseVal.payment.paymentMethod ===
                                Cash ? (
                                <Button
                                  variant="white"
                                  className="btn-flush fs-15 fw-600"
                                >
                                  Paid cash{" "}
                                  <img
                                    className="ms-1"
                                    width={18}
                                    height={18}
                                    src={verify}
                                    alt=""
                                  />
                                </Button>
                              ) : (
                                <Button
                                  variant="white"
                                  className="btn-flush fs-15 fw-600"
                                >
                                  Paid online{" "}
                                  <img
                                    className="ms-1"
                                    width={18}
                                    height={18}
                                    src={verify}
                                    alt=""
                                  />
                                </Button>
                              )}
                              {/* <span className="fs-20 fw-600">-</span> */}
                            </td>
                            <td className="text-end">
                            {Method.hasPermission(Advertising, View, currentUser) ? (
                              <Button
                                variant="primary"
                                className="btn-table"
                                onClick={() => {
                                  let currentScrollY = window.scrollY;
                                      setKey(
                                        listAdvertisement.scrollPosition,
                                        currentScrollY
                                      );
                                      setKey(listAdvertisement.search, props.search, false);
                                  navigate(
                                    "/advertisement/view-advertisement/advertising-details",
                                    {
                                      state: {
                                        status: AdvertisementCompleted,
                                        id: advertiseVal._id,
                                      },
                                    }
                                  );
                                }}
                              >
                                View details
                              </Button>
                            ) : (
                              <></>
                            )}
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </>
                ) : (
                  <tr>
                    <td colSpan={7}>
                      <div className="d-flex justify-content-center">
                        <span className="fs-15 fw-500">No Data found</span>
                      </div>
                    </td>{" "}
                  </tr>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
      {props.fetchLoading ? (
        <></>
      ) : (
        <>
          {advertisment.length ? (
            <>
              <Pagination
                totalRecords={props.total}
                currentPage={props.currentPage}
                handleCurrentPage={(event: any) => {
                  props.handleCurrentPage(event, AdvertisementCompleted);
                }}
                handleNextPage={(event: any) => {
                  props.handleNextPage(event, AdvertisementCompleted);
                }}
                handlePreviousPage={(event: any) => {
                  props.handlePreviousPage(event, AdvertisementCompleted);
                }}
                pageLimit={parseInt(props.pageLimit)}
                handlePageLimit={(event: any) => {
                  props.handlePageLimit(event, AdvertisementCompleted);
                }}
              />
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};
export default Completed;
