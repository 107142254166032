import { Button, Col, FormLabel, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { IMAGES } from "../../../utils/dummyJSON";
import ActiveSellersCustomers from "./chart/active-sellers-customers";
import OrdersReport from "./chart/orders-report";
import SalesReport from "./chart/admin-sale-report";
import SellersReport from "./chart/sellers-report";
import { useAuth } from "../auth";
import {
  Admin,
  AllModules,
  AssistAdmin,
  Dashboard,
  FinanceManager,
  OrdersDelivery,
  View,
} from "../../../utils/constants";
import APICallService from "../../../api/apiCallService";
import { useEffect, useState } from "react";
import { DASHBOARD, multipleWarehouse } from "../../../api/apiEndPoints";
import DashboardReport from "../../modals/report/dashboard-report";
import Method from "../../../utils/methods";
import { warehouse as warehouseStoreString } from "../../../utils/storeString";
import { getKey, removeKey, setKey } from "../../../Global/history";
import { CustomSelect } from "../../custom/Select/CustomSelect";
import { warehouse as warehouseStrings } from "../../../utils/storeString";
import Loader from "../../../Global/loader";
const AdminDashboard = () => {
  let tempWarehouse: any = getKey(warehouseStoreString.warehouseRef);
  tempWarehouse = JSON.parse(tempWarehouse);
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [count, setCount] = useState({
    pendingKYC: 0,
    totalSellers: 0,
    pendingProducts: 0,
    pendingShipmentsRequests: 0,
    totalListedProducts: 0,
    totalOrders: 0,
    totalBuyers: 0,
    totalOrderAmount: 0,
  });
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [warehouse, setWarehouse] = useState<any>([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState<any>(null);
  const [warehouseData, setWarehouseData] = useState<any>({
    value: currentUser?.warehouse.reference || "",
    title: currentUser?.warehouse.name || "",
    label: currentUser?.warehouse.name || "",
  });
  useEffect(() => {
    (async () => {
      let tempWarehouse: any = getKey(warehouseStrings.warehouseRef);
      tempWarehouse = JSON.parse(tempWarehouse);
      await fetchData(tempWarehouse?._id);
      await fetchWarehouse(tempWarehouse?._id);
    })();
  }, []);
  const fetchData = async (warehouse: any) => {
    let params: any = {
      needStats: true,
      needAllStats: true,
      fromDate: Method.convertDateToFormat(new Date(), "YYYY-MM-DD"),
    };
    if (warehouse) {
      params.warehouse = warehouse;
    }
    setLoading(true);
    let apiService = new APICallService(
      DASHBOARD,
      params,
      "",
      "",
      "",
      "",
      Dashboard,
      warehouse
    );
    let response = await apiService.callAPI();
    if (response) {
      setCount(response.stats);
    }
    setLoading(false);
  };
  const handleWarehouseChange = async (event: any) => {
    setLoading(true);
    if (event) {
      setKey(warehouseStrings.warehouseRef, event);
      setSelectedWarehouse(event);
      setWarehouseData(event);
      await fetchData(event.value);
    } else {
      setSelectedWarehouse(null);
      await fetchData("");
      removeKey(warehouseStrings.warehouseRef);
    }
    setLoading(false);
  };
  const fetchWarehouse = async (warehouseId: any) => {
    let params: any = {
      skip: 0,
      sortKey: "name",
      sortOrder: 1,
      needCount: true,
    };
    if (warehouseId) {
      params.warehouse = warehouseId;
    }
    setLoading(true);
    const apiService = new APICallService(
      multipleWarehouse.listWarehouse,
      params,
      "",
      "",
      "",
      "",
      AllModules,
      warehouseId
    );
    const response = await apiService.callAPI();
    let data: any = [...warehouse];
    if (response.records.length) {
      response.records.map((val: any) => {
        data.push({
          value: val._id,
          label: val.name,
          title: val.name,
          _id: val._id,
        });
      });
    }
    let tempWarehouse: any = getKey(warehouseStrings.warehouseRef);
    tempWarehouse = JSON.parse(tempWarehouse);
    data.unshift({
      value :'',
      label : 'All',
      title : 'All',
      _id : ''
    })
    if (tempWarehouse) {
      const temp = data.find((item: any) => item._id === tempWarehouse._id);
      setSelectedWarehouse(temp);
      setWarehouseData(temp);
    }
    console.log("ddddddd", data);
    setWarehouse(data);
    setLoading(false);
  };

  return (
    <>
      <div className="p-9">
        {showDownloadModal ? (
          <DashboardReport
            show={showDownloadModal}
            onHide={() => setShowDownloadModal(false)}
            moduleName={Dashboard}
          />
        ) : (
          <></>
        )}
        <Row className="mb-7 align-items-center">
          <Col xs className="fs-22 fw-bolder">
            Dashboard
          </Col>
          <Col xs={12} md={4}>
            {currentUser?.userType === Admin ? (
              <div className="d-flex align-items-center gap-4">
                <FormLabel className="fs-16 fw-500 text-dark">
                  Filter by warehouse
                </FormLabel>
                <CustomSelect
                  backgroundColor="white"
                  value={selectedWarehouse}
                  onChange={(event: any) => {
                    handleWarehouseChange(event);
                  }}
                  hideSelectedOptions={true}
                  options={warehouse}
                  // isClearable={true}
                />
              </div>
            ) : (
              <></>
            )}
          </Col>
          {!loading ? (
            <>
              {" "}
              {currentUser?.userType === AssistAdmin ? (
                <></>
              ) : (
                <>
                  {" "}
                  <Col sm="auto">
                    {Method.hasPermission(OrdersDelivery, View, currentUser) ? (
                      <Button
                        className="min-h-50px"
                        onClick={() => {
                          navigate("/orders");
                        }}
                      >
                        View orders & delivery plans
                      </Button>
                    ) : (
                      <></>
                    )}
                  </Col>
                  {/* <Col sm="auto">
                  <Button
                    className="min-h-50px"
                    onClick={() => {
                      navigate('/orders');
                    }}
                    variant="light-primary"
                  >
                    Download Report
                  </Button>
                </Col> */}
                </>
              )}
              {currentUser?.role.length === 0 ||
              currentUser?.role.some(
                (obj: any) => obj.type === FinanceManager
              ) ? (
                <Col sm="auto">
                  <Button
                    variant=""
                    size="lg"
                    className="text-primary bg-light-primary min-h-50px"
                    onClick={() => setShowDownloadModal(true)}
                  >
                    Download Report
                  </Button>
                </Col>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
        </Row>
        {loading ? (
          <div className="w-100 d-flex justify-content-center">
            <Loader loading={loading} />
          </div>
        ) : (
          <>
            {" "}
            <Row className="align-items-center mb-7 g-6">
              <Col xl={3} md={6} sm={4}>
                <div className="border-r8px bg-fbe5e5 rounded py-4 px-5">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex flex-column">
                      <span className="fs-22 fw-bolder">
                        TSh{" "}
                        {count.totalOrderAmount > 0
                          ? Method.getGeneralizedAmount(count.totalOrderAmount)
                          : 0}
                      </span>
                      <span className="fw-medium fs-16">
                        {`Today's total order value`}{" "}
                      </span>
                    </div>
                    <img src={IMAGES.PaymentSVG} alt="" />
                  </div>
                </div>
              </Col>
              <Col xl={3} md={6} sm={4}>
                <div className="border-r8px bg-c9f3d7 rounded py-4 px-5">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex flex-column">
                      <span className="fs-22 fw-bolder">
                        {count.totalSellers > 0 ? count.totalSellers : 0}
                      </span>
                      <span className="fw-medium fs-16">Total sellers</span>
                    </div>
                    <img src={IMAGES.SellerSVG} alt="" />
                  </div>
                </div>
              </Col>
              <Col xl={3} md={6} sm={4}>
                <div className="border-r8px bg-fbeace rounded py-4 px-5">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex flex-column">
                      <span className="fs-22 fw-bolder">
                        {count.totalBuyers > 0 ? count.totalBuyers : 0}
                      </span>
                      <span className="fw-medium fs-16">Total customers</span>
                    </div>
                    <img src={IMAGES.CustomerSVG} alt="" />
                  </div>
                </div>
              </Col>
              <Col xl={3} md={6} sm={4}>
                <div className="border-r8px bg-c6e4fb rounded py-4 px-5">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex flex-column">
                      <span className="fs-22 fw-bolder">
                        {count.totalListedProducts > 0
                          ? count.totalListedProducts
                          : 0}
                      </span>
                      <span className="fw-medium fs-16">Total products</span>
                    </div>
                    <img src={IMAGES.ProductSVG} alt="" />
                  </div>
                </div>
              </Col>
              <Col xl={3} md={6} sm={4}>
                <div className="border-r8px bg-d5d5f2  rounded py-4 px-5">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex flex-column">
                      <span className="fs-22 fw-bolder">
                        {count.totalOrders > 0 ? count.totalOrders : 0}
                      </span>
                      <span className="fw-medium fs-16">Total orders</span>
                    </div>
                    <img src={IMAGES.OrderSVG} alt="" />
                  </div>
                </div>
              </Col>
            </Row>
            {/* Chart 1 */} <SalesReport />
            {/* Chart 2 */} <ActiveSellersCustomers />
            {/* Chart 3 */} <OrdersReport />
            {/* Chart 4 */}
            {currentUser?.warehouse?.defaultWarehouse ? (
              <SellersReport />
            ) : (
              <></>
            )}
          </>
        )}
      </div>
    </>
  );
};
export default AdminDashboard;
