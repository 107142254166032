import React from 'react'
import { CustomSelectTable } from '../../custom/Select/CustomSelectTable';
import threeDot from '../../../_admin/assets/media/svg_uTrade/three-dot-round.svg';
import { Card, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const WarehouseDetail = () => {
    const navigate = useNavigate()
  return (
    <div className="p-9">
    <Row className="align-items-center">
      <Col
        xs={12}
        className="mb-5"
      >
        <div className="d-flex flex-wrap justify-content-between align-items-center">
          <div>
            <h1 className="fs-22 fw-bolder">Kigamboni Warehouse</h1>
            <h5>Contact number:+255 716 786 089</h5>
          </div>
          <CustomSelectTable
            marginLeft={'0px'}
            menuMargin={'-60px'}
            width={'140px'}
            placeholder={
              <img
                className="img-fluid"
                width={47}
                height={47}
                src={threeDot}
                alt=""
              />
            }
            options={[
              {
                label: (
                  <button
                    className="btn btn-link fs-14 fw-500 text-black  ms-3 p-4"
                    onClick={() =>
                      navigate('/warehouse/edit-warehouse-details')
                    }
                  >
                    Edit warehouse
                  </button>
                ),
                value: 1,
              },
              {
                label: (
                  <button
                    // onClick={() => setModalShow(true)}
                    className="btn btn-link fs-14 fw-500 text-danger  ms-3 p-4"
                  >
                    Delete warehouse
                  </button>
                ),
                value: 2,
              },
            ]}
            backgroundColor="white"
            // show={show}
            // onMenuClose={() => {
            //   onMenuClose();
            // }}
            // openMenuOnClick={() => {
            //   openMenuOnClick();
            // }}
            // onMenuOpen={() => {
            //   onMenuOpen();
            // }}
            isOptionDisabled={(option: any) => option.value === 3}
          />
        </div>
      </Col>
      <Col xs={12}>
        <Row>
          <Col>
            <Card className="border mb-7">
              <div
                className="bg-light d-flex align-items-center px-7"
                style={{ height: '75px', borderRadius: '10px' }}
              >
                <span className="fs-22 fw-bolder">Basic Details</span>
              </div>
              <Card.Body className="px-7">
                <Row>
                  <Col lg={6}>
                    <Row className="mb-5">
                      <Col
                        md={5}
                        xs={4}
                      >
                        <label className="fs-16 fw-500 text-dark">
                          Assigned districts:
                        </label>
                      </Col>
                      <Col
                        md={7}
                        xs={8}
                      >
                        <span className="fw-bold fs-16 fw-600 text-dark bg-light p-3 rounded-pill">
                          Kigamboni
                        </span>
                      </Col>
                    </Row>
                    <Row className="mb-5">
                      <Col
                        md={5}
                        xs={4}
                      >
                        <label className="fs-16 fw-500 text-dark">
                          Assigned managers:
                        </label>
                      </Col>
                      <Col
                        md={7}
                        xs={8}
                      >
                        <div className="d-flex flex-wrap gap-3">
                          <span className="fw-bold fs-16 fw-600 text-dark bg-light p-3 rounded-pill">
                            Matthew Powell
                          </span>
                          <span className="fw-bold fs-16 fw-600 text-dark bg-light p-3 rounded-pill">
                            Abdulrazak Niam
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mb-5">
                      <Col
                        md={5}
                        xs={4}
                      >
                        <label className="fs-16 fw-500 text-dark">
                          Loading areas:
                        </label>
                      </Col>
                      <Col
                        md={7}
                        xs={8}
                      >
                        <div className="d-flex flex-wrap gap-3">
                          <span className="fw-bold fs-16 fw-600 text-dark bg-light p-3 rounded-pill">
                            Area 1
                          </span>
                          <span className="fw-bold fs-16 fw-600 text-dark bg-light p-3 rounded-pill">
                            Area 2
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mb-5">
                      <Col
                        md={5}
                        xs={4}
                      >
                        <label className="fs-16 fw-500 text-dark">
                          Address:
                        </label>
                      </Col>
                      <Col
                        md={7}
                        xs={8}
                      >
                        <span className="fw-bold fs-16 fw-600 text-dark">
                          Plot no. 85A, Kingsway Estate, 4th Floor, Kinondoni,
                          Dar es salaam
                        </span>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Col>
      {/* <Col xs={12}>
        <Row>
          <Col>
            <Card className="border mb-7">
              <div
                className="d-flex justify-content-between align-items-center bg-light p-5"
                style={{ height: '75px', borderRadius: '10px' }}
              >
                <div className="fs-22 fw-bolder">Finance members</div>
                <Button
                  variant="link"
                  className="fs-16 fw-bolder text-primary btn-flush align-self-center text-nowrap"
                >
                  Add member
                </Button>
              </div>
              <Card.Body className="px-7">
                <div className="table-responsive">
                  <table className="table table-rounded table-row-bordered align-middle">
                    <tbody>
                      {financeMembers.map((member, index) => (
                        <tr key={index}>
                          <td className="fs-15 fw-600">
                            <img
                              src={member.image}
                              alt={member.name}
                              className="me-2"
                              style={{
                                width: '50px',
                                height: '50px',
                                borderRadius: '10%',
                              }}
                            />
                            {member.name}
                          </td>
                          <td>
                            <div className="d-flex flex-column text-end">
                              <span className="fs-15 fw-600">
                                {member.email}
                              </span>
                              <span className="fs-15 fw-300">
                                {member.phone}
                              </span>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="border mb-7">
              <div
                className="d-flex justify-content-between align-items-center bg-light p-5"
                style={{ height: '75px', borderRadius: '10px' }}
              >
                <div className="fs-22 fw-bolder">Loading bay members</div>
                <Button
                  className="fs-16 fw-bolder text-primary btn-flush align-self-center text-nowrap"
                  variant="link"
                >
                  Add members
                </Button>
              </div>
              <Card.Body className="px-7">
                <div className="table-responsive">
                  <table className="table table-rounded table-row-bordered align-middle">
                    <tbody>
                      {financeMembers.map((member, index) => (
                        <tr key={index}>
                          <td className="fs-15 fw-600">
                            <img
                              src={member.image}
                              alt={member.name}
                              className="me-2"
                              style={{
                                width: '50px',
                                height: '50px',
                                borderRadius: '10%',
                              }}
                            />
                            {member.name}
                          </td>
                          <td>
                            <div className="d-flex flex-column text-end">
                              <span className="fs-15 fw-600">
                                {member.email}
                              </span>
                              <span className="fs-15 fw-300">
                                {member.phone}
                              </span>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Col>
      <Col xs={12}>
        <Row>
          <Col>
            <Card className="border mb-7">
              <div
                className="d-flex justify-content-between align-items-center bg-light p-5"
                style={{ height: '75px', borderRadius: '10px' }}
              >
                <div className="fs-22 fw-bolder">Vehicles</div>
                <Button
                  variant="link"
                  className="fs-16 fw-bolder text-primary btn-flush align-self-center text-nowrap"
                >
                  Add member
                </Button>
              </div>
              <Card.Body className="px-7">
                <div className="table-responsive">
                  <table className="table table-rounded table-row-bordered align-middle">
                    <tbody>
                      {Vehicle.map((vehicle, index) => (
                        <tr key={index}>
                          <td>
                            <div className="d-flex">
                              <img
                                src={vehicle.image}
                                alt={vehicle.name}
                                className="me-2"
                                style={{
                                  width: '50px',
                                  height: '50px',
                                  borderRadius: '10%',
                                }}
                              />
                              <div className="d-flex flex-column">
                                <span className="fs-15 fw-600">
                                  {vehicle.name}
                                </span>
                                <span>{vehicle.number}</span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex flex-column text-end">
                              <span className="fs-15 fw-600">
                                {vehicle.driver}
                              </span>
                              <span className="fs-15 fw-300">
                                {vehicle.position}
                              </span>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="border mb-7">
              <div
                className="d-flex justify-content-between align-items-center bg-light p-5"
                style={{ height: '75px', borderRadius: '10px' }}
              >
                <div className="fs-22 fw-bolder">Drivers</div>
                <Button
                  className="fs-16 fw-bolder text-primary btn-flush align-self-center text-nowrap"
                  variant="link"
                >
                  Add members
                </Button>
              </div>
              <Card.Body className="px-7">
                <div className="table-responsive">
                  <table className="table table-rounded table-row-bordered align-middle">
                    <tbody>
                      {financeMembers.map((member, index) => (
                        <tr key={index}>
                          <td className="fs-15 fw-600">
                            <img
                              src={member.image}
                              alt={member.name}
                              className="me-2"
                              style={{
                                width: '50px',
                                height: '50px',
                                borderRadius: '10%',
                              }}
                            />
                            {member.name}
                          </td>
                          <td>
                            <div className="d-flex flex-column text-end">
                              <span className="fs-15 fw-600">
                                {member.email}
                              </span>
                              <span className="fs-15 fw-300">
                                {member.phone}
                              </span>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Col>
      <Col xs={12}>
        <Row>
          <Col>
            <Card className="border mb-7">
              <div
                className="d-flex justify-content-between align-items-center bg-light p-5"
                style={{ height: '75px', borderRadius: '10px' }}
              >
                <div className="fs-22 fw-bolder">Fulfillment members</div>
                <Button
                  variant="link"
                  className="fs-16 fw-bolder text-primary btn-flush align-self-center text-nowrap"
                >
                  Add member
                </Button>
              </div>
              <Card.Body className="px-7">
                <div className="table-responsive">
                  <table className="table table-rounded table-row-bordered align-middle">
                    <tbody>
                      {financeMembers.map((member, index) => (
                        <tr key={index}>
                          <td className="fs-15 fw-600">
                            <div className="d-flex align-items-center">
                              <img
                                src={member.image}
                                alt={member.name}
                                className="me-2"
                                style={{
                                  width: '50px',
                                  height: '50px',
                                  borderRadius: '10%',
                                }}
                              />
                              {member.name}
                            </div>
                          </td>
                          <td className="fs-15 fw-600 text-center">
                            {member.phone}
                          </td>
                          <td className="fs-15 fw-600 text-end">
                            <div className="d-flex justify-content-end">
                              {member.email}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Col> */}
      {/* <DeleteWarehouse
        show={modalShow}
        onHide={() => setModalShow(false)}
      /> */}
    </Row>
  </div>
  )
}

export default WarehouseDetail