import { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  OverlayTrigger,
  Popover,
  Row,
} from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { KTSVG } from '../../../_admin/helpers';
import { CustomSelect } from '../../custom/Select/CustomSelect';
import { IGetCustomers } from '../../../types/responseIndex';
import { useAuth } from '../auth';
import APICallService from '../../../api/apiCallService';
import { buyer, customerService } from '../../../api/apiEndPoints';
import { customerJSON } from '../../../api/apiJSON/customer';
import { customerServiceJSON } from '../../../api/apiJSON/customerService';
import Loader from '../../../Global/loader';
import Pagination from '../../../Global/pagination';
import { Add, AllCustomers, PAGE_LIMIT, View } from '../../../utils/constants';
import b1 from '../../../_admin/assets/media/product/default.svg';
import Method from '../../../utils/methods';
import { warehouse as warehouseStoreString } from '../../../utils/storeString';
import { getKey } from '../../../Global/history';

const ViewCustomersManager = (props: any) => {
  let tempWarehouse: any = getKey(warehouseStoreString.warehouseRef);
  tempWarehouse = JSON.parse(tempWarehouse);
  const navigate = useNavigate();
  const { currentUser, saveCurrentUser } = useAuth();
  const { state }: any = useLocation();
  const [fetchLoading, setFetchLoading] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(PAGE_LIMIT);
  const [customers, setCustomers] = useState<IGetCustomers | any>([]);
  const [team, setTeam] = useState<any>([]);
  const [teamAssigned, setTeamAssigned] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState<string>('');
  const [district, setDistrict] = useState<string>(
    (props && props.districtId) ||
      (state && state.districtId) ||
      (currentUser &&
        currentUser.districts &&
        currentUser.districts[0] &&
        currentUser.districts.find((val: any) => val.flag === true).reference)
  );
  const [teamMember, setTeamMember] = useState<any>([]);
  useEffect(() => {
    (async () => {
      setFetchLoading(true);
      if (!Method.hasModulePermission(AllCustomers, currentUser)) {
        return window.history.back();
      }
      let district =
        (props && props.districtId) ||
        (state && state.districtId) ||
        (currentUser &&
          currentUser.districts &&
          currentUser.districts[0] &&
          currentUser.districts[0].reference);
      let data = await fetchMyTeam(page, searchTerm, district);
      if(data){
      let temp: any = [
        { label: 'All team members', value: '0', title: 'All team members' },
      ];
      let tempTeam: any = [];
      data.map((val: any, index: number) => {
        temp.push({ label: val.name, value: val._id, title: val.name });
        tempTeam.push({ label: val.name, value: val._id, title: val.name });
      });
      setTeam(temp);
      setTeamMember(temp);
      setTeamAssigned(tempTeam);
      await fetchCustomerData(page, pageLimit, searchTerm, tempTeam);
      }
      setFetchLoading(false);
    })();
  }, []);
  const fetchMyTeam = async (
    pageNo: number,
    search: string,
    districtId: string
  ) => {
    let params:any = {
      pageNo: pageNo,
      limit: 0,
      sortKey: 'name',
      sortOrder: 1,
      searchTerm: search,
      district: districtId,
    };
if (tempWarehouse?._id) {
  params = {
    ...params,
    warehouse: tempWarehouse?._id,
  };
}
    if (currentUser) {
      let apiService = new APICallService(
        customerService.myTeam,
        customerServiceJSON.listTeam(params),
        {
          districtId: districtId,
          id:
            props && props.userId
              ? props.userId
              : state && state.userId
              ? state.userId
              : currentUser && currentUser._id,
        },
        '','','',AllCustomers,tempWarehouse?._id || null
      );
      let response = await apiService.callAPI();
      if (response) {
        if (pageNo === 1) {
          setTotalRecords(response.total);
        } else {
          let prevTotal = totalRecords;
          setTotalRecords(prevTotal);
        }
        return response.records;
      }
    }
  };
  const fetchCustomerData = async (
    pageNo: number,
    limit: number,
    search: string,
    teamIds: any
  ) => {
    setFetchLoading(true);
    let params: any = {
      pageNo: pageNo,
      limit: limit,
      sortKey: 'name',
      sortOrder: 1,
      searchTerm: search,
      needCount: pageNo === 1,
    };
    params = {
      ...params,
      district: district,
      ['addedBy[' + 0 + ']']:
        props && props.userId
          ? props.userId
          : state && state.userId
          ? state.userId
          : currentUser && currentUser._id,
    };
    if (teamIds.length > 0) {
      let temp: any = [...teamIds];
      temp = temp.filter((item: any) => item.value !== '0');
      temp.map((val: any, index: number) => {
        params = { ...params, ['addedBy[' + (index + 1) + ']']: val.value };
      });
    }
    if (tempWarehouse?._id) {
      params = {
        ...params,
        warehouse: tempWarehouse?._id,
      };
    }
    let apiService = new APICallService(buyer.listOnBoardedBuyers, params,'','','','',AllCustomers,tempWarehouse?._id || null);
    let response = await apiService.callAPI();
    if (response) {
      if (pageNo === 1) {
        setTotalRecords(response.total);
      } else {
        let prevTotal = totalRecords;
        setTotalRecords(prevTotal);
      }
      setCustomers(response.records);
    }
    setFetchLoading(false);
  };
  const handleCurrentPage = async (val: number) => {
    setLoading(true);
    setPage(val);
    await fetchCustomerData(val, pageLimit, searchTerm, teamMember);
    setLoading(false);
  };
  const handleNextPage = async (val: number) => {
    setLoading(true);
    setPage(val + 1);
    await fetchCustomerData(val + 1, pageLimit, searchTerm, teamMember);
    setLoading(false);
  };
  const handlePreviousPage = async (val: number) => {
    setLoading(true);
    setPage(val - 1);
    await fetchCustomerData(val - 1, pageLimit, searchTerm, teamMember);
    setLoading(false);
  };
  const handlePageLimit = async (event: any) => {
    setLoading(true);
    setPage(1);
    await setPageLimit(parseInt(event.target.value));
    await fetchCustomerData(1, event.target.value, searchTerm, teamMember);
    setLoading(false);
  };
  const handleSearch = async (value: string) => {
    setSearch(value);
    setLoading(true);
    await fetchCustomerData(1, pageLimit, value, teamMember);
    setLoading(false);
  };
  const handleCustomerProfile = (id: string) => {
    navigate('/customers/customer-profile', { state: id });
  };
  const handleTeamMember = async (event: any) => {
    if (event.value === '0') {
      setTeamMember(team);
      await fetchCustomerData(1, pageLimit, searchTerm, team);
    } else {
      setTeamMember([event]);
      await fetchCustomerData(1, pageLimit, searchTerm, [event]);
    }
  };
  const handleDistricts = async (event: any) => {
    setDistrict(event.value);
    let user: any = { ...currentUser };
    for (var i = 0; i < user.districts.length; i++) {
      if (user.districts[i].reference === event.value) {
        user.districts[i].flag = true;
      } else {
        user.districts[i].flag = false;
      }
    }
    saveCurrentUser(user);
    setFetchLoading(true);
    let data = await fetchMyTeam(page, searchTerm, event.value);
    setCustomers([]);
    if (data.length) {
      let temp: any = [{ label: 'All team members', value: '0' }];
      let tempTeam: any = [];
      data.map((val: any, index: number) => {
        temp.push({ label: val.name, value: val._id });
        tempTeam.push({ label: val.name, value: val._id });
      });
      setTeam(temp);
      setTeamMember(temp);
      setTeamAssigned(tempTeam);
      await fetchCustomerData(page, pageLimit, searchTerm, tempTeam);
    } else {
      setTeam([]);
      setTeamMember([]);
    }
    setFetchLoading(false);
  };
  const handleAssign = async (event: any, userId: string, index: number) => {
    let temp = [...customers];
    let apiService = new APICallService(
      buyer.assignTeamMember,
      customerJSON.assignMember({ user: userId, assignedTo: event.value }),
      '','','','',AllCustomers,tempWarehouse?._id || null
    );
    let response = await apiService.callAPI();
    if (response) {
      temp[index].assignedTo = {
        _id: event.value,
        name: event.title,
      };
    }
    setCustomers(temp);
  };
  const popover = (
    <Popover id="popover-basic">
      <Popover.Body className="p-2 bg-black border-r10px text-white">
        <span>No More details available</span>
      </Popover.Body>
    </Popover>
  );
  return (
    <>
      <div className={props.isAdmin ? '' : 'p-9'}>
        {(props && props.isAdmin) || (state && state.isAdmin) ? (
          <></>
        ) : (
          <Row className="align-items-center g-5 mb-7">
            <Col
              xs={12}
              sm
            >
              <h1 className="fs-22 fw-bolder mb-0">Customers</h1>
            </Col>
            <Col xs="auto">
              <CustomSelect
                value={currentUser?.districts.map((val: any) => {
                  if (val.reference === district) {
                    return {
                      label: val.name,
                      value: val.reference,
                      title: val.name,
                    };
                  }
                })}
                backgroundColor="#f9f9f9"
                minHeight="50px"
                minWidth="224px"
                controlFontSize="1.231rem"
                onChange={(event: any) => {
                  handleDistricts(event);
                }}
                options={currentUser?.districts.map((val: any) => {
                  return {
                    label: val.name,
                    value: val.reference,
                    title: val.name,
                  };
                })}
              />
            </Col>
            {Method.hasPermission(AllCustomers, Add, currentUser) ? (
            <Col xs="auto">
              <Button
                size="lg"
                onClick={() => navigate('/customers/add-new-customers')}
              >
                Add new customer
              </Button>
            </Col>
            ) : (
              <></>
            )}
          </Row>
        )}
        <Card className="bg-light border">
          <Card.Body className="px-7">
            <Row className="align-items-center">
              {search === '' && !customers.length ? (
                <Col
                  md
                  className="mb-md-0 mb-5"
                ></Col>
              ) : (
                <>
                  <Col
                    md
                    className="mb-md-0 mb-5"
                  >
                    <div className="d-flex align-items-center min-w-md-317px w-fit-content position-relative me-lg-4">
                      <KTSVG
                        path="/media/icons/duotune/general/gen021.svg"
                        className="svg-icon-3 position-absolute ps-5"
                      />
                      <input
                        type="text"
                        id="kt_filter_search"
                        className="form-control form-control-custom bg-white min-h-60px ps-13 text-dark"
                        placeholder="Search by customer name…"
                        value={search}
                        onChange={(event: any) => {
                          handleSearch(event.target.value.trimStart());
                        }}
                      />
                    </div>
                  </Col>
                </>
              )}
              {/* {teamMember.length ? (
                  <Col md="auto">
                    <div className="d-flex align-items-center">
                      <label
                        htmlFor=""
                        className="fs-16 fw-500"
                      >
                        Filter by member
                      </label>
                      <div className="min-w-lg-300px ms-6">
                        <CustomSelect
                          backgroundColor="#ffff"
                          value={teamMember}
                          options={team}
                          onChange={(event: any) => {
                            handleTeamMember(event);
                          }}
                          isSearchable={false}
                          isMulti={false}
                        />
                      </div>
                    </div>
                  </Col>
                ) : (
                  <></>
                )} */}
              <Col md="auto">
                <div className="d-flex align-items-center">
                  <label
                    htmlFor=""
                    className="fs-16 fw-500"
                  >
                    Filter by member
                  </label>
                  <div className="min-w-lg-300px ms-6">
                    <CustomSelect
                      backgroundColor="#ffff"
                      value={teamMember}
                      options={team}
                      onChange={(event: any) => {
                        handleTeamMember(event);
                      }}
                      isMulti={false}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Card className="border border-r10px mt-6">
          <Card.Body className="p-0">
            <div className="table-responsive">
              <table className="table table-rounded table-row-bordered align-middle gs-7 gy-4 mb-0">
                <thead>
                  <tr className="fs-16 fw-bold text-dark border-bottom h-70px align-middle">
                    <th className="min-w-250px">Customer name</th>
                    <th className="min-w-250px"> Assigned team member</th>
                    <th className="min-w-130px">Orders placed</th>
                    <th className="min-w-125px">Total sales</th>
                    <th className="min-w-150px text-end"></th>
                  </tr>
                </thead>
                <tbody>
                  {fetchLoading ? (
                    <>
                      <td colSpan={4}>
                        <div className="w-100 d-flex justify-content-center">
                          <Loader loading={fetchLoading || loading} />
                        </div>
                      </td>
                    </>
                  ) : (
                    <>
                      {customers.length ? (
                        <>
                          {customers.map(
                            (customVal: any, customIndex: number) => {
                              return (
                                <tr>
                                  <td>
                                    <div className="d-flex align-items-center">
                                      <div className="symbol symbol-50px border me-5">
                                        <img
                                          src={
                                            customVal.business.image
                                              ? customVal.business.image
                                              : b1
                                          }
                                          className="object-fit-contain"
                                          alt=""
                                        />
                                      </div>
                                      <div className="fs-15 fw-600">
                                        {customVal.name} <br />
                                        <span className="fw-500 text-gray">
                                          {customVal.business.name}
                                        </span>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="w-200px">
                                      <CustomSelect
                                        backgroundColor="#f9f9f9"
                                        minHeight="45px"
                                        radiusTable="5px"
                                        value={
                                          customVal.assignedTo &&
                                          teamAssigned.filter(
                                            (val: any) =>
                                              val.value ===
                                              customVal.assignedTo._id
                                          )
                                        }
                                        options={teamAssigned}
                                        isMulti={false}
                                        onChange={(event: any) => {
                                          handleAssign(
                                            event,
                                            customVal._id,
                                            customIndex
                                          );
                                        }}
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <span className="fs-15 fw-500">0</span>
                                  </td>
                                  <td>
                                    <span className="fs-15 fw-600">TSh 0</span>
                                  </td>
                                  <td className="text-end">
                                    {/* {!customVal.regCompleted &&
                                    customVal.addedByAdmin === false ? (
                                      <OverlayTrigger
                                        trigger="hover"
                                        placement="bottom"
                                        overlay={popover}
                                      >
                                        <Button
                                          className="fs-14"
                                          variant="light"
                                        >
                                          View details
                                        </Button>
                                      </OverlayTrigger>
                                    ) : (
                                      <Button
                                        className="fs-14"
                                        disabled={
                                          !customVal.regCompleted &&
                                          customVal.addedByAdmin === false
                                        }
                                        onClick={() => {
                                          handleCustomerProfile(customVal._id);
                                        }}
                                      >
                                        View details
                                      </Button>
                                    )}{' '} */}
                                    {Method.hasPermission(AllCustomers, View, currentUser) ? (
                                    <Button
                                      className="fs-14"
                                      onClick={() => {
                                        handleCustomerProfile(customVal._id);
                                      }}
                                    >
                                      View details
                                    </Button>
                                    ) : (
                                      <></>
                                    )}
                                  </td>
                                </tr>
                              );
                            }
                          )}
                        </>
                      ) : (
                        <tr>
                          <td colSpan={4}>
                            <div className="w-100 fs-15 fw-bold d-flex justify-content-center">
                              No Data found
                            </div>
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </Card.Body>
        </Card>
        {!fetchLoading ? (
          <>
            {customers.length ? (
              <Pagination
                totalRecords={totalRecords}
                currentPage={page}
                handleCurrentPage={(event: any) => {
                  handleCurrentPage(event);
                }}
                handleNextPage={(event: any) => {
                  handleNextPage(event);
                }}
                handlePreviousPage={(event: any) => {
                  handlePreviousPage(event);
                }}
                pageLimit={pageLimit}
                handlePageLimit={(event: any) => {
                  handlePageLimit(event);
                }}
              />
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
export default ViewCustomersManager;
