import { Button, Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  CartonWithDozens,
  CartonWithPieces,
  Dozen,
  Piece,
  OrdersDelivery,
  ReceivedDirectGood,
} from "../../../utils/constants";
import Validations from "../../../utils/validations";
import { orderReturn } from "../../../api/apiEndPoints";
import { orderDeliveryJSON } from "../../../api/apiJSON/order";
import APICallService from "../../../api/apiCallService";
import clsx from "clsx";
import { getKey } from "../../../Global/history";
import { warehouse as warehouseStoreString } from "../../../utils/storeString";
const ReturnOrder = () => {
  let tempWarehouse: any = getKey(warehouseStoreString.warehouseRef);
  tempWarehouse = JSON.parse(tempWarehouse);
  const navigate = useNavigate();
  const { state }: any = useLocation();
  const [vehicleDetails, setVehicleDetails] = useState<any>();
  const [orderId, setOrderId] = useState<any>();
  const [selectedProduct, setSelectedProduct] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    (async () => {
      let temp: any = [];
      console.log("state.vehicleDetails", state.vehicleDetails);
      state.vehicleDetails.variants.map((val: any) => {
        temp.push({
          variantID: val.variant._id,
          ...val,
          initialStock: val.stockCount,
          stockCount: val.returnedStockCount ? val.returnedStockCount : 0,
        });
      });
      await setSelectedProduct(temp);
      await setVehicleDetails(state.vehicleDetails);
      await setOrderId(state.orderId);
    })();
  }, []);
  const handleProductSelect = (event: any) => {
    const filteredArray: any = vehicleDetails.variants.find(
      (obj: any) => obj.variant._id === event.value
    );
    if (filteredArray) {
      let temp: any = [...selectedProduct];
      temp.push({ variant: event.value });
      setSelectedProduct(temp);
    }
  };
  const handleAddMore = () => {
    let temp: any = [...selectedProduct];
    temp.push({ variant: "" });
    setSelectedProduct(temp);
  };
  const handleRemove = async (index: any) => {
    let temp = [...selectedProduct];
    temp.splice(index, 1);
    setSelectedProduct(temp);
  };
  const handleQuantityChange = (event: any, index: number) => {
    let temp = [...selectedProduct];
    if (event.target.value <= temp[index].initialStock) {
      temp[index].stockCount = event.target.value;
    }
    setSelectedProduct(temp);
  };
  const handleOnKeyPress = async (event: any) => {
    // Allow only numbers
    const key = event.key;
    let validations = await Validations.allowOnlyNumbers(key);
    if (!validations) {
      event.preventDefault();
      return false;
    }
    return true;
  };
  const handleSubmit = async () => {
    let temp: any = [];
    selectedProduct.map((val: any) => {
      temp.push({
        variant: val.variantID,
        quantityType: val.quantityType,
        returnedStockCount: val.stockCount,
      });
    });
    // const filteredData = temp.filter(
    //   (item: any) => parseInt(item.returnedStockCount) > 0
    // );
    if (temp.length) {
      setLoading(true);
      let apiService = new APICallService(
        orderReturn.inform,
        orderDeliveryJSON.returnInform({
          route: vehicleDetails.route,
          vehicle: vehicleDetails.vehicle,
          variants: temp,
        }),
        {
          _id: orderId,
        },
        "",
        "",
        "",
        ReceivedDirectGood,
        tempWarehouse?._id || null
      );
      let response = await apiService.callAPI();
      if (response) {
        window.history.back();
      }
      setLoading(true);
    }
  };
  return (
    <>
      <div className="p-9">
        <div className="mb-7">
          <h1 className="fs-22 fw-bolder">Return Order</h1>
        </div>
        <Card className="border bg-f9f9f9 mb-7">
          <Card.Body>
            <Row>
              <Col md={6} lg={4}>
                <Form.Label className="fs-16 fw-600 me-2">
                  Vehicle details:-{" "}
                </Form.Label>
                {vehicleDetails ? (
                  <>
                    {vehicleDetails.routeVehicle.driver ? (
                      <span className={clsx("fs-16 fw-500 text-dark")}>
                        {vehicleDetails.routeVehicle.driver.name}/{" "}
                        {vehicleDetails.routeVehicle.vehicle.number}
                      </span>
                    ) : (
                      <span className={clsx("fs-16 fw-500 text-danger")}>
                        Driver not allocated /{" "}
                        {vehicleDetails.routeVehicle.vehicle.number}
                      </span>
                    )}{" "}
                  </>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Card className="border bg-f9f9f9 py-3">
          <Card.Header className="border-bottom-0 mb-3">
            <Card.Title className="fs-22 fw-bolder">
              Products & return quantities
            </Card.Title>
          </Card.Header>
          <Card.Body className="py-0">
            {selectedProduct.length ? (
              selectedProduct.map((productVal: any, productIndex: number) => {
                return (
                  <>
                    <Row className="align-items-center mb-6 g-3">
                      <Col md={5} lg={4}>
                        <Form.Label className="fs-16 fw-500">
                          Product
                        </Form.Label>
                        <Form.Label className="border form-control h-60px w-auto text-dark">
                          <div className="symbol symbol-30px border me-3">
                            <img
                              src={productVal.variant.media[0].url}
                              className="object-fit-contain"
                              alt=""
                            />
                          </div>
                          <span className="fs-14 fw-600 text-black mb-0">
                            {productVal.variant.title.replace(/\s*\)\s*/g, ")")}
                          </span>
                        </Form.Label>
                      </Col>
                      <Col xs={11} md={6} lg={7}>
                        <Row className="align-items-center fs-16 fw-500 mb-1">
                          <Col xs>
                            <span>Quantity to return back</span>
                          </Col>
                          <Col xs="auto">
                            <span className="text-gray">
                              Processed Quantity:{" "}
                              {`${productVal.initialStock} ${
                                productVal.quantityType === CartonWithDozens ||
                                productVal.quantityType === CartonWithPieces
                                  ? "C"
                                  : ""
                              }
                         ${productVal.quantityType === Piece ? "P" : ""}
                          ${productVal.quantityType === Dozen ? "D" : ""}`}
                            </span>
                          </Col>
                        </Row>
                        <Row className="align-items-center g-3">
                          <Col sm={4} md={6} lg={4}>
                            <InputGroup className="border border-r8px">
                              <Form.Control
                                className="form-control-custom fs-16 fw-600 border-0 text-start bg-white pe-1"
                                disabled={
                                  !(
                                    productVal.quantityType ===
                                      CartonWithDozens ||
                                    productVal.quantityType === CartonWithPieces
                                  )
                                }
                                value={
                                  productVal.quantityType ===
                                    CartonWithDozens ||
                                  productVal.quantityType === CartonWithPieces
                                    ? productVal.stockCount
                                    : "0"
                                }
                                onChange={(event: any) => {
                                  handleQuantityChange(event, productIndex);
                                }}
                                onKeyPress={(event: any) => {
                                  handleOnKeyPress(event);
                                }}
                              />
                              <InputGroup.Text className="fs-16 fw-600 text-gray bg-white border-0 ps-3">
                                cartons
                              </InputGroup.Text>
                            </InputGroup>
                          </Col>
                          <Col sm={4} md={6} lg={4}>
                            <InputGroup className="border border-r8px">
                              <Form.Control
                                className="form-control-custom fs-16 fw-600 border-0 text-start bg-white pe-1"
                                disabled={productVal.quantityType !== Dozen}
                                value={
                                  productVal.quantityType === Dozen
                                    ? productVal.stockCount
                                    : "0"
                                }
                                onChange={(event: any) => {
                                  handleQuantityChange(event, productIndex);
                                }}
                                onKeyPress={(event: any) => {
                                  handleOnKeyPress(event);
                                }}
                              />
                              <InputGroup.Text className="fs-16 fw-600 text-gray bg-white border-0 ps-3">
                                dozens
                              </InputGroup.Text>
                            </InputGroup>
                          </Col>
                          <Col sm={4} md={6} lg={4}>
                            <InputGroup className="border border-r8px">
                              <Form.Control
                                className="form-control-custom fs-16 fw-600 border-0 text-start bg-white pe-1"
                                disabled={productVal.quantityType !== Piece}
                                value={
                                  productVal.quantityType === Piece
                                    ? productVal.stockCount
                                    : "0"
                                }
                                onChange={(event: any) => {
                                  handleQuantityChange(event, productIndex);
                                }}
                                onKeyPress={(event: any) => {
                                  handleOnKeyPress(event);
                                }}
                              />
                              <InputGroup.Text className="fs-16 fw-600 text-gray bg-white border-0 ps-3">
                                pieces
                              </InputGroup.Text>
                            </InputGroup>
                          </Col>
                        </Row>
                      </Col>
                      {/* {selectedProduct.length === productIndex ? (
                    <></>
                  ) : (
                    <>
                      <Col
                        xs={1}
                        className="text-end mt-6"
                      >
                        <Button
                          variant="link"
                          className="btn-flush"
                          onClick={() => {
                            handleRemove(productIndex);
                          }}
                        >
                          <img
                            width={22}
                            height={22}
                            className="img-fluid"
                            src={GrayCross}
                            alt=""
                          />
                        </Button>
                      </Col>
                    </>
                  )} */}
                    </Row>
                    {selectedProduct.length - 1 === productIndex ? (
                      <></>
                    ) : (
                      <>
                        <div className="separator my-6"></div>
                      </>
                    )}
                  </>
                );
              })
            ) : (
              <></>
            )}
          </Card.Body>
        </Card>
        {/* BUTTON */}
        <div className="d-flex justify-content-end mt-4">
          <Button
            size="lg"
            className="min-h-lg-60px"
            onClick={() => {
              handleSubmit();
            }}
          >
            {!loading && (
              <span className="indicator-label">Confirm returns</span>
            )}
            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </Button>
        </div>
      </div>
    </>
  );
};
export default ReturnOrder;
