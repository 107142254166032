import clsx from "clsx";
import { useEffect, useState } from "react";
import { Button, Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Loader from "../../../Global/loader";
import { success } from "../../../Global/toast";
import APICallService from "../../../api/apiCallService";
import { platformFees } from "../../../api/apiEndPoints";
import { platformFeesToast } from "../../../utils/toast";
import ConfirmChanges from "../../modals/confirm-changes";
import { platformFeesJSON } from "../../../api/apiJSON/platformFees";
import Validations from "../../../utils/validations";
import { Add, Delete, Edit, PlatformFees } from "../../../utils/constants";
import Method from "../../../utils/methods";
import { useAuth } from "../auth";
import { getKey } from "../../../Global/history";
import { warehouse as warehouseStoreString } from "../../../utils/storeString";
const OtherFees = () => {
  let tempWarehouse: any = getKey(warehouseStoreString.warehouseRef);
  tempWarehouse = JSON.parse(tempWarehouse);
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [confirmModal, setConfirmModal] = useState(false);
  const [cost, setCost] = useState<any>({
    homePageSingleProduct: "",
    homePageMultipleProduct: "",
    categoryPageSingleProduct: "",
    categoryPageMultipleProduct: "",
    minimumOrder: "",
  });
  const [initialCost, setInitialCost] = useState<any>({
    homePageSingleProduct: "",
    homePageMultipleProduct: "",
    categoryPageSingleProduct: "",
    categoryPageMultipleProduct: "",
    minimumOrder: "",
  });
  const [costValidation, setCostValidation] = useState<any>({
    homePageSingleProduct: false,
    homePageMultipleProduct: false,
    categoryPageSingleProduct: false,
    categoryPageMultipleProduct: false,
    minimumOrder: false,
  });
  const [loading, setLoading] = useState(false);
  const [fetchLoader, setFetchLoader] = useState(false);
  const hasViewPermission = !Method.hasPermission(
    PlatformFees,
    Edit,
    currentUser
  );
  useEffect(() => {
    (async () => {
      await fetchOtherFees();
    })();
  }, []);
  const fetchOtherFees = async () => {
    setFetchLoader(true);
    let apiService = new APICallService(
      platformFees.listOtherFees,
      "",
      "",
      "",
      "",
      "",
      PlatformFees,
      tempWarehouse?._id
    );
    let response = await apiService.callAPI();
    if (response) {
      let data = {
        homePageSingleProduct: response.advertisement.homePageSingleProduct,
        homePageMultipleProduct: response.advertisement.homePageMultipleProduct,
        categoryPageSingleProduct:
          response.advertisement.categoryPageSingleProduct,
        categoryPageMultipleProduct:
          response.advertisement.categoryPageMultipleProduct,
        minimumOrder: response.minimumOrder,
      };
      setCost(data);
      setInitialCost(data);
    }
    setFetchLoader(false);
  };
  const handleChange = async (value: string, name: string) => {
    let temp = { ...cost };
    let tempValidation = { ...costValidation };
    let priceValidation = await Validations.validatePrice(value.trim());
    if (priceValidation) {
      temp[name] = value;
      if (value.length > 0) {
        tempValidation[name] = false;
      } else {
        tempValidation[name] = true;
      }
    }
    setCostValidation(tempValidation);
    setCost(temp);
  };
  const handleSave = async () => {
    let tempValidation = { ...costValidation };
    if (cost.homePageSingleProduct === "") {
      tempValidation.homePageSingleProduct = true;
    }
    if (cost.homePageMultipleProduct === "") {
      tempValidation.homePageMultipleProduct = true;
    }
    if (cost.categoryPageSingleProduct === "") {
      tempValidation.categoryPageSingleProduct = true;
    }
    if (cost.categoryPageMultipleProduct === "") {
      tempValidation.categoryPageMultipleProduct = true;
    }
    if (cost.minimumOrder === "") {
      tempValidation.minimumOrder = true;
    }
    const allFalse = Object.values(tempValidation).every((v) => v === false);
    if (allFalse) {
      if (JSON.stringify(initialCost) !== JSON.stringify(cost)) {
        setConfirmModal(true);
      }
    }
    setCostValidation(tempValidation);
  };
  const handleOnKeyPress = (event: any) => {
    var charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
      event.preventDefault();
      return false;
    }
    return true;
  };
  const onSave = async () => {
    setLoading(true);
    let apiService = new APICallService(
      platformFees.updateOtherFees,
      platformFeesJSON.addUpdateOtherFees(cost),
      "",
      "",
      "",
      "",
      PlatformFees,
      tempWarehouse?._id
    );
    let response = await apiService.callAPI();
    if (response) {
      success(platformFeesToast.feesUpdated);
      setConfirmModal(false);
    }
    setLoading(false);
  };
  return (
    <>
      {confirmModal ? (
        <ConfirmChanges
          show={confirmModal}
          title={"Are you sure you want to save changes?"}
          message={""}
          onHide={() => {
            setCost(initialCost);
            setConfirmModal(false);
          }}
          cost={cost}
          onSave={onSave}
          loading={loading}
        />
      ) : (
        <></>
      )}
      <Row>
        <Col xs={12}>
          <h2 className="fs-22 fw-bolder mb-6"> Set Advertisements fees</h2>
        </Col>
        {fetchLoader ? (
          <div className="d-flex justify-content-center mt-5">
            <Loader loading={fetchLoader} />
          </div>
        ) : (
          <>
            <Col>
              <Card className="biz-certificate">
                <Card.Body className="py-3">
                  <div className="table-responsive">
                    <table className="table align-middle gs-0 gy-4 mb-0">
                      <thead>
                        <tr className="fs-16 fw-600 align-middle h-55px">
                          <th className="min-w-150px">Ad placement</th>
                          <th className="min-w-225px">Single product cost</th>
                          <th className="min-w-225px">Multiple product cost</th>
                          <td className="min-w-200px"></td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <span className="fs-14 fw-600">Homepage</span>
                          </td>
                          <td>
                            <InputGroup
                              className={clsx(
                                "mw-225px min-h-45px border border-r6px",
                                costValidation.homePageSingleProduct
                                  ? "border-danger"
                                  : ""
                              )}
                            >
                              <InputGroup.Text className="fs-14 fw-600 text-dark border-0">
                                TSh
                              </InputGroup.Text>
                              <Form.Control
                                className="fs-14 fw-600 text-dark bg-light ps-0 border-0"
                                placeholder="example1"
                                aria-label="example1"
                                aria-describedby=""
                                value={cost.homePageSingleProduct}
                                onChange={(event: any) => {
                                  handleChange(
                                    event.target.value.trimStart(),
                                    "homePageSingleProduct"
                                  );
                                  // setConfirmModal(true);
                                }}
                                onKeyPress={(event: any) => {
                                  handleOnKeyPress(event);
                                }}
                                disabled={loading || hasViewPermission}
                              />
                              <InputGroup.Text className="fs-14 fw-500 text-gray border-0">
                                Per day
                              </InputGroup.Text>
                            </InputGroup>
                          </td>
                          <td>
                            <InputGroup
                              className={clsx(
                                "mw-225px min-h-45px border border-r6px",
                                costValidation.homePageMultipleProduct
                                  ? "border-danger"
                                  : ""
                              )}
                            >
                              <InputGroup.Text className="fs-14 fw-600 text-dark border-0">
                                TSh
                              </InputGroup.Text>
                              <Form.Control
                                className="fs-14 fw-600 text-dark bg-light ps-0 border-0"
                                aria-describedby=""
                                value={cost.homePageMultipleProduct}
                                onChange={(event: any) => {
                                  handleChange(
                                    event.target.value.trimStart(),
                                    "homePageMultipleProduct"
                                  );
                                  // setConfirmModal(true);
                                }}
                                // onKeyPress={(event: any) => {
                                //   handleOnKeyPress(event);
                                // }}
                                disabled={loading || hasViewPermission}
                              />
                              <InputGroup.Text className="fs-14 fw-500 text-gray border-0">
                                Per day
                              </InputGroup.Text>
                            </InputGroup>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span className="fs-14 fw-600">Category page</span>
                          </td>
                          <td>
                            <InputGroup
                              className={clsx(
                                "mw-225px min-h-45px border border-r6px",
                                costValidation.categoryPageSingleProduct
                                  ? "border-danger"
                                  : ""
                              )}
                            >
                              <InputGroup.Text className="fs-14 fw-600 text-dark border-0">
                                TSh
                              </InputGroup.Text>
                              <Form.Control
                                className="fs-14 fw-600 text-dark bg-light ps-0 border-0"
                                placeholder="example1"
                                aria-label="example1"
                                aria-describedby=""
                                value={cost.categoryPageSingleProduct}
                                onChange={(event: any) => {
                                  handleChange(
                                    event.target.value.trimStart(),
                                    "categoryPageSingleProduct"
                                  );
                                  // setConfirmModal(true);
                                }}
                                onKeyPress={(event: any) => {
                                  handleOnKeyPress(event);
                                }}
                                disabled={loading || hasViewPermission}
                              />
                              <InputGroup.Text className="fs-14 fw-500 text-gray border-0">
                                Per day
                              </InputGroup.Text>
                            </InputGroup>
                          </td>
                          <td>
                            <InputGroup
                              className={clsx(
                                "mw-225px min-h-45px border border-r6px",
                                costValidation.categoryPageMultipleProduct
                                  ? "border-danger"
                                  : ""
                              )}
                            >
                              <InputGroup.Text className="fs-14 fw-600 text-dark border-0">
                                TSh
                              </InputGroup.Text>
                              <Form.Control
                                className="fs-14 fw-600 text-dark bg-light ps-0 border-0"
                                placeholder="example1"
                                aria-label="example1"
                                aria-describedby=""
                                value={cost.categoryPageMultipleProduct}
                                onChange={(event: any) => {
                                  handleChange(
                                    event.target.value.trimStart(),
                                    "categoryPageMultipleProduct"
                                  );
                                  // setConfirmModal(true);
                                }}
                                onKeyPress={(event: any) => {
                                  handleOnKeyPress(event);
                                }}
                                disabled={loading || hasViewPermission}
                              />
                              <InputGroup.Text className="fs-14 fw-500 text-gray border-0">
                                Per day
                              </InputGroup.Text>
                            </InputGroup>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} className="mt-8 mb-2">
              <h3 className="fs-22 fw-bolder">Set Minimum order value</h3>
              <p className="fs-16 fw-500">
                You can set a minimum order value for the buyers.
              </p>
            </Col>
            <Col>
              <Card className="border py-8">
                <Card.Body className="py-0">
                  <Form.Group
                    as={Row}
                    className="align-items-center"
                    controlId="formPlaintextEmail"
                  >
                    <Form.Label
                      className="fs-16 fw-500 text-dark"
                      column
                      sm="2"
                    >
                      Set minimum <br /> order value
                    </Form.Label>
                    <Col sm="auto">
                      <InputGroup
                        size="lg"
                        className={
                          costValidation.minimumOrder
                            ? "border border-r8px min-h-60px  border-danger"
                            : "border border-r8px min-h-60px"
                        }
                      >
                        <InputGroup.Text
                          id=""
                          className="fs-16 fw-600 text-dark border-0"
                        >
                          TSh
                        </InputGroup.Text>
                        <Form.Control
                          className="fs-16 fw-600 text-dark bg-light border-0 ps-0"
                          aria-label=""
                          aria-describedby=""
                          value={cost.minimumOrder}
                          onChange={(event: any) => {
                            handleChange(
                              event.target.value.trimStart(),
                              "minimumOrder"
                            );
                          }}
                          onKeyPress={(event: any) => {
                            handleOnKeyPress(event);
                          }}
                          disabled={loading || hasViewPermission}
                        />
                      </InputGroup>
                    </Col>
                  </Form.Group>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} className="my-9">
              <Button
                variant="primary"
                size="lg"
                className="w-fit-content"
                onClick={handleSave}
                disabled={loading || hasViewPermission}
              >
                <span className="indicator-label fs-16 fw-bold">
                  Save details
                </span>
              </Button>
            </Col>
          </>
        )}
      </Row>
    </>
  );
};
export default OtherFees;
